import React from 'react';
import PropTypes from 'prop-types';

import { isEmptyObject } from 'utils/functions';

import BaseLoading, { BaseLoadingError } from './style';

/**
 * Loading
 * @component
 *
 */
function Loading(props) {
  const { error, loading, refetch, children, data, onLoadingSuccess } = props;
  const canDislay = !error && !loading && !isEmptyObject(data);
  const firstData = data && Object.values(data)[0];
  React.useEffect(() => {
    if (canDislay) {
      onLoadingSuccess(data, refetch);
    }
  }, [error, loading, firstData]);
  console.log('--', error, loading, canDislay, data);
  return (
    <React.Fragment>
      {error && <BaseLoadingError>{error.message}</BaseLoadingError>}
      {loading && <BaseLoading />}
      {canDislay && children}
    </React.Fragment>
  );
}

Loading.defaultProps = {
  error: null,
  loading: false,
  data: null,
  onLoadingSuccess: () => null,
  children: undefined
};

Loading.propTypes = {
  error: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  refetch: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
  onLoadingSuccess: PropTypes.func
};

export default Loading;
