import React from 'react';

import OperatedCategoryInsight from 'Insight/components/OperatedCategoryInsight';
import OperatorLayoutInsight from 'Insight/components/OperatorLayoutInsight';
import OperatedCriteriaInsight from 'Insight/components/OperatedCriteriaInsight';
import OperatedGroupInsight from 'Insight/components/OperatedGroupInsight';
import OperatorInsight from 'Insight/components/OperatorInsight';
// import { useInsight } from 'Insight/contexts/InsightContext';
// import { useSegment } from 'Insight/contexts/SegmentContext';
import { useOperator } from 'Insight/contexts/OperatorContext';
import { useCategory } from 'Insight/contexts/CategoryContext';
import constants from 'utils/constants';

const { insightSegmentTypes } = constants;
const { OR, AND } = insightSegmentTypes;
const getLabel = type => {
  const typeName = type.NAME.toLowerCase();
  if (typeName === OR.NAME.toLowerCase()) return OR.LABEL;
  if (typeName === AND.NAME.toLowerCase()) return AND.LABEL;
  return '--';
};

const extractSubGroup = (groups, categoryID) => {
  const entry = Object.entries(groups).find(arr => arr[0] === categoryID);
  if (!entry) return [];
  const objSubGroups = entry[1];
  return Object.entries(objSubGroups) || [];
};

/**
 * OperatorContainer
 * @component
 * @see AppInsight
 *
 */
function OperatorContainer() {
  const {
    operatorsGroups,
    handleSelectedChange,
    handleSubGroupChange
  } = useOperator();
  const { allCategories } = useCategory();
  const handleChange = params => {
    handleSelectedChange(params);
    console.log('ooo');
  };
  React.useEffect(() => {}, [operatorsGroups]);
  return (
    <OperatorLayoutInsight length={allCategories.length}>
      {allCategories.map(({ id: categoryID }, catIndex) => (
        <OperatedCategoryInsight
          key={`operatorCategory-${catIndex}`}
          rounded={catIndex === 1}
        >
          {extractSubGroup(operatorsGroups, categoryID).map(
            ([subCategoryID, subGroup], indexSub) => (
              <React.Fragment key={`operatorGroup-${indexSub}`}>
                {/*  SubCategory beginning : */}
                <OperatorInsight
                  operator={getLabel(subGroup.type)}
                  rounded
                  hide={indexSub === 0}
                  handleChange={() =>
                    handleSubGroupChange({
                      subCategoryID,
                      categoryID
                    })
                  }
                />
                <OperatedGroupInsight>
                  {subGroup.values.map((value, valueIndex) => (
                    <React.Fragment key={`criteria-${valueIndex}`}>
                      {/* Criteria beginning : */}
                      <OperatorInsight
                        operator={getLabel(value.type)}
                        hide={valueIndex === 0}
                        handleChange={() =>
                          handleChange({
                            categoryID,
                            subCategoryID,
                            position: value.position,
                            value,
                            isTypeChange: true
                          })
                        }
                      />
                      <OperatedCriteriaInsight
                        title={value.name}
                        isExcluded={value.exclude}
                        isFirst={value.position === 0}
                        isLast={value.position === subGroup.values.length - 1}
                        toggleExclude={() =>
                          handleChange({
                            categoryID,
                            value,
                            subCategoryID,
                            position: value.position
                          })
                        }
                      />
                      {/* Criteria ending */}
                    </React.Fragment>
                  ))}
                </OperatedGroupInsight>
                {/* SubCategory Ending */}
              </React.Fragment>
            )
          )}
        </OperatedCategoryInsight>
      ))}
    </OperatorLayoutInsight>
  );
}

export default OperatorContainer;
