import React from 'react';
import moment from 'utils/moment';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';
import { useFunnelGraph } from 'Funnel/contexts/FunnelGraphContext';
import FunnelRow from 'Funnel/components/FunnelRow';
import constants from 'utils/constants';
// import PropTypes from 'prop-types'
import {
  TableSynthesis,
  TableBodySynthesis,
  TableCellSynthesis,
  TableHeadSynthesis,
  TableRowSynthesis
} from 'Funnel/components/FunnelRow/style';
// import TableRow  from '@material-ui/core/TableRow';

const { connectors } = constants;
/**
 * @component
 * @see AppFunnel
 *
 */
function SynthesisContainer() {
  const { funnel, setFunnel } = useFunnel();
  const { setHasConnector } = useFunnelStep();
  const { connected, objectifed, anom } = funnel;
  const { conversionRate, addConversionRate } = useFunnelGraph();
  const setObjectif = (funnelId, value) => {
    setFunnel({
      ...funnel,
      objectifed: [
        ...objectifed.filter(ob => ob.funnelId !== funnelId),
        { funnelId, value }
      ]
    });
  };
  const setConnector = (funnelId, connector, data, childId) => {
    setHasConnector();
    const fields = { funnelId, connector, data };
    if (childId) fields.childId = childId;
    setFunnel({
      ...funnel,
      connected: [
        ...connected.filter(co => co.funnelId !== funnelId),
        { ...fields }
      ]
    });
  };
  const setFetched = () => {
    const newConnected = connected.map(c => ({ ...c, fetched: true }));
    setFunnel({ ...funnel, connected: newConnected });
  };
  const setForecast = forecast => setFunnel({ ...funnel, forecast });
  const setAnom = (funnelId, date, motifanom) => {
    const fields = { funnelId, date, motifanom };
    setFunnel({
      ...funnel,
      anom: [...anom.filter(an => an.funnelId !== funnelId), { ...fields }]
    });
  };
  //
  const saveDataAnalyse = () => null; // item => setArray([...array, item]);
  const filter = data => {
    return (
      moment(data.date).isBetween(
        moment(funnel.start).startOf('day'),
        moment(funnel.end).startOf('day'),
        null,
        '[]'
      ) && parseInt(data.dayoff, 10) !== 1
    );
  };
  return (
    <React.Fragment>
      <TableSynthesis>
        <TableHeadSynthesis>
          <TableRowSynthesis>
            <TableCellSynthesis />
            <TableCellSynthesis />
            <TableCellSynthesis>Réel</TableCellSynthesis>
            <TableCellSynthesis>Top</TableCellSynthesis>
            <TableCellSynthesis>Flop</TableCellSynthesis>
            <TableCellSynthesis>Objectif Indiqué</TableCellSynthesis>
            <TableCellSynthesis />
          </TableRowSynthesis>
        </TableHeadSynthesis>
        <TableBodySynthesis>
          {funnel.measure
            ? funnel.measure.funnels.map(f => {
                const objectifFunnel = Object.values(objectifed).find(
                  c => c.funnelId === f.id
                );
                return (
                  <FunnelRow
                    key={f.id}
                    {...f}
                    sources={connectors}
                    funnel={funnel}
                    objectif={objectifFunnel ? objectifFunnel.value : null}
                    connected={connected}
                    setObjectif={setObjectif}
                    setConnector={setConnector}
                    setFetched={setFetched}
                    setForecast={setForecast}
                    setAnom={setAnom}
                    saveDataAnalyse={saveDataAnalyse}
                    filter={filter}
                    conversionRate={conversionRate}
                    addConversionRate={addConversionRate}
                  />
                );
              })
            : null}
        </TableBodySynthesis>
      </TableSynthesis>
    </React.Fragment>
  );
}

SynthesisContainer.defaultProps = {};

SynthesisContainer.propTypes = {};

export default SynthesisContainer;
