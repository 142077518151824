import React from 'react';
import styled from 'styled-components';
import constants from 'utils/constants';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const { colors } = constants;

/**
 * BaseCriteria
 * @component
 *
 */
const BaseCriteriaInsight = styled.div`
  display: grid;
  grid-template-columns: ${({ hasInsee }) =>
    hasInsee ? '0.7fr 0.3fr 2fr 2fr' : '0.7fr 0.3fr 2fr'};
    vertical-align: bottom;
    margin-right: 4px;
  }
  .label {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .description-icon {
    display: flex;
    align-items: center;
    padding: 0 10px; 
  }
  .graph {
    width: 100%;
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      font-weight: 500;
      color: #e0e0e0;
    }
  }
  .graph.left {
    justify-content: flex-end;
    flex-direction: ${({ hasInsee }) => (hasInsee ? 'row' : 'row-reverse')};
    span {
      color: ${colors.insight};
    }
  }
  .gauge {
    height: 24px;
  }
  .gauge.ermes {
    background-color: ${colors.insight};
    width: ${({ ermesPercent }) => ermesPercent}%;
    border-radius: ${({ hasInsee }) =>
      hasInsee ? '8px 0 0 8px' : '0 8px 8px 0'};
    margin-left: 8px;
    margin-right: 5px;
  }
  .gauge.insee {
    background-color: #e0e0e0;
    width: ${({ inseePercent }) => inseePercent}%;
    border-radius: 0 8px 8px 0;
    margin-right: 8px;
    margin-left: 5px;
  }
`;

export const CriteriaCheckbox = withStyles({
  root: {
    color: colors.light,
    '&$checked': {
      color: colors.light
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

export default BaseCriteriaInsight;
