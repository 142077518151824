import constants from 'utils/constants';
import DefaultSegment from './constructors';

const { insightSegmentTypes } = constants;
const { OR, AND, SIMPLE, EXCLUDE } = insightSegmentTypes;

export default () => null;

/**
 * completeFilter
 * @desc a filterSegment is a SegmentSimple or SegmentComposed
 * @see './constructors.js'
 * @param [object]{
 *  [SegmentSimple | SegmentComposed] filterSegment
 *  [SegmentSimple] oneSegment
 *  }
 * @return [SegmentSimple} or [SegmentComposed]
 */
export const completeFilter = ({ filterSegment, oneSegment }) => {
  if (!filterSegment) {
    // if there is no Segment in  filterSegment
    return oneSegment;
  }
  // else add the segment to the filterSegment 's ComposedSegment
  const hasSingleSegment = !!filterSegment.segmentID;
  if (hasSingleSegment) {
    // if had single segment return composed segment
    const { categoryID, ...restFilter } = filterSegment;
    const newComposedSegment = new DefaultSegment({
      type: OR.NAME,
      filters: [restFilter, oneSegment]
    });
    return newComposedSegment;
  }
  // if has many segment, add one and return it;
  const nextSegmentComposed = { ...filterSegment };
  nextSegmentComposed.filters.push(oneSegment);
  return nextSegmentComposed;
};

/**
 * decreaseFilter
 * @desc build a new filters without the segment (criteriaID)
 * @return [SegmentSimple} or [SegmentComposed] or undefined
 */
export const decreaseFilter = ({ filterSegment, criteriaID }) => {
  if (!filterSegment || filterSegment.segmentID) {
    // if filterSegment is composed by only one SimpleSegment;
    return undefined;
  }
  // else remove SimpleSegment from filterSegment 's ComposedSegment
  const nextFilterItem = { ...filterSegment };
  const index = nextFilterItem.filters.findIndex(
    ({ segmentID }) => segmentID === criteriaID
  );
  if (index === -1) return nextFilterItem;
  nextFilterItem.filters.splice(index, 1);
  if (nextFilterItem.filters.length === 1) {
    // replace ComposedSegment by SimpleSegment if has only one element
    return nextFilterItem.filters[0];
  }
  return nextFilterItem;
};

/**
 * formatFilter
 * @Desc format filter to be sent via graphQL Query variables
 *
 */
export const formatFilter = filter => {
  const checkExist = f => f && Object.values(f).length;
  const formatedFilter = [];
  console.log('filters', filter);
  Object.values(filter)
    .filter(checkExist)
    .forEach(({ categoryID, ...rest }) => {
      console.log('rest', rest);
      formatedFilter.push(rest);
    });
  console.log('formatedFilter filters', formatedFilter);
  return formatedFilter;
};

/**
 * reverseOperator
 * @param [string] operator
 * @return [string]
 */
export const reverseOperator = operator => {
  if (operator === OR.NAME) return AND.NAME;
  if (operator === AND.NAME) return OR.NAME;
  if (operator === SIMPLE.NAME) return EXCLUDE.NAME;
  return SIMPLE.NAME;
};
