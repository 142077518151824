import React from 'react';
import PropTypes from 'prop-types';
import LoggedHeader from './LoggedHeader';
import DefaultHeader from './DefaultHeader';

/**
 * @component
 * @desc there is two header LoggedHeader fro logged users and DefaultHeader for anonymous users.
 * @desc those two headers has two state of display :  onScroll and notScrolled state.
 */
function Header(props) {
  const { isConnected } = props;
  return (
    <React.Fragment>
      {(isConnected && (
        <LoggedHeader imageName="/assets/img/logo_ermes_homepage.svg" />
      )) || <DefaultHeader {...props} />}
    </React.Fragment>
  );
}

Header.propTypes = {
  isConnected: PropTypes.bool.isRequired
};

export default Header;
