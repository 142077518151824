import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

/**
 * BaseButtonInsight
 * @component
 *
 */
const BaseButtonInsight = styled.button`
  display: inline-flex;
  text-transform: uppercase;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  border: 2px solid ${colors.light};
  border-radius: 27px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 7px;
  margin: 0 10px;
  outline: none;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export const DefaultButtonInsight = ({ inversed, ...rest }) => (
  <BaseButtonInsight
    color={inversed ? colors.insight : colors.light}
    bgColor={inversed ? 'transparent' : colors.insight}
    {...rest}
  />
);
DefaultButtonInsight.defaultProps = { inversed: false };
DefaultButtonInsight.propTypes = { inversed: PropTypes.bool };

export const UnColoredButton = ({ inversed, ...rest }) => (
  <BaseButtonInsight
    color={inversed ? colors.light : colors.primary}
    bgColor={inversed ? 'transparent' : colors.light}
    {...rest}
  />
);
UnColoredButton.defaultProps = { inversed: false };
UnColoredButton.propTypes = { inversed: PropTypes.bool };

export default BaseButtonInsight;
