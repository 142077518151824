import React from 'react';
import { DefaultSubGroup, DefaultSelected } from './constructor';
import formatOperators, {
  checkHasSubGroup,
  reverseOperator
} from './functions';

const OperatorContext = React.createContext();

/**
 * OperatorProvider
 * @provider
 */
export default function OperatorProvider(props) {
  const [operatorsGroups, setOperatorsGroup] = React.useState({});
  const [selectedList, setSelectedList] = React.useState([]);
  const value = {
    operatorsGroups,
    formatedFilter: formatOperators(operatorsGroups),
    isValueSelected(criteriaID) {
      return selectedList.some(id => id === criteriaID);
    },
    addValue(params) {
      const { categoryID, subCategoryID, criteria } = params;
      const nextGroup = { ...operatorsGroups };
      // add to operatorsGroups
      if (!checkHasSubGroup(params, operatorsGroups)) {
        nextGroup[categoryID] = nextGroup[categoryID] || {};
        nextGroup[categoryID][subCategoryID] = new DefaultSubGroup();
      }
      const subGroup = nextGroup[categoryID][subCategoryID];
      nextGroup[categoryID][subCategoryID].values.push(
        new DefaultSelected({
          id: criteria.id,
          name: criteria.name,
          position: subGroup.values.length
        })
      );
      setOperatorsGroup(nextGroup);
      // add to selectedList
      setSelectedList([...selectedList, criteria.id]);
    },
    removeValue(params) {
      const { categoryID, subCategoryID, criteria } = params;
      if (!checkHasSubGroup(params, operatorsGroups)) return;
      // update operatorsGroups
      const nextGroup = { ...operatorsGroups };
      const subGroup = nextGroup[categoryID][subCategoryID];
      if (!subGroup.values.length) return;
      if (subGroup.values.length === 1) {
        nextGroup[categoryID][subCategoryID] = new DefaultSubGroup();
      } else {
        const index = subGroup.values.findIndex(({ id }) => id === criteria.id);
        nextGroup[categoryID][subCategoryID].values.splice(index, 1);
        nextGroup[categoryID][subCategoryID].values = nextGroup[categoryID][
          subCategoryID
        ].values.map((values, pos) => ({ ...values, position: pos }));
      }
      setOperatorsGroup(nextGroup);
      // update selectedList
      const nextSelectedList = [...selectedList];
      const index = selectedList.indexOf(criteria.id);
      if (index === -1) return;
      nextSelectedList.splice(index, 1);
      setSelectedList(nextSelectedList);
    },
    handleSubGroupChange(params) {
      const { categoryID, subCategoryID } = params;
      if (!checkHasSubGroup) return;
      const nextGroup = { ...operatorsGroups };
      const subGroup = nextGroup[categoryID][subCategoryID];
      nextGroup[categoryID][subCategoryID].type = subGroup.type;
      setOperatorsGroup(nextGroup);
    },
    handleSelectedChange(params) {
      console.log('[[[[[[[[[[[[[[[[[[', params.value);
      const { categoryID, subCategoryID, position, isTypeChange } = params;
      console.log('1');
      if (!checkHasSubGroup) return;
      console.log('2');
      const nextGroup = { ...operatorsGroups };
      const selected = nextGroup[categoryID][subCategoryID].values[position];
      console.log('selected', position, nextGroup[categoryID][subCategoryID]);
      if (!selected) return;
      console.log('3', selected);
      if (isTypeChange) {
        nextGroup[categoryID][subCategoryID].values[
          position
        ].type = reverseOperator(selected.type);
      } else {
        nextGroup[categoryID][subCategoryID].values[
          position
        ].exclude = !selected.exclude;
      }
      console.log('4', nextGroup);
      setOperatorsGroup(nextGroup);
    }
  };

  return <OperatorContext.Provider {...props} value={value} />;
}

/**
 * useOperator
 * @function
 * @return value defined above (OperatorProvider)
 *
 */
export const useOperator = () => {
  const context = React.useContext(OperatorContext);
  if (!context)
    throw new Error('useOperator must be called inside OperatorProvider');
  return context;
};
