import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TitleH1 = styled.h1`
  display: block;
  text-align: center;
  font-size: 32px;
  width: ${({ width }) => width || 'auto'};
  padding: ${({ padding }) => padding || '0'};
`;

const TitleH2 = styled.h2`
  font-size: 26px;
`;

const TitleH3 = styled.h3`
  font-size: ${({ fontSize }) => fontSize || '16px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

const TitleH4 = styled.h4`
  font-size: ${({ fontSize }) => fontSize || '16px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;
/**
 * @components
 */
function Title(props) {
  const { big, medium, small } = props;
  if (big) {
    return <TitleH1 {...props} />;
  }
  if (medium) {
    return <TitleH2 {...props} />;
  }
  if (small) {
    return <TitleH3 {...props} />;
  }
  return <TitleH4 {...props} />;
}
Title.defaultProps = {
  big: false,
  medium: false,
  small: false
};
Title.propTypes = {
  big: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool
};

export const TitleSubTab = styled.span`
  margin-right: 25px;
`;

export const Subtitle = styled.div`
  font-size: 20px;
  margin-top: 25px;
  white-space: pre;
  font-weight: lighter;
`;

export default Title;
