import React from 'react';
// import styled from 'styled-components';
// import constants from 'utils/constants';
import Loading from 'components/Loading';

// const { colors } = constants;

/**
 * BaseLoading
 * @component
 *
 */
const BaseLoading = props => <Loading {...props} />;
export default BaseLoading;
