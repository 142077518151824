import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { useUser } from 'contexts/UserContext';
import constants from 'utils/constants';
import { DropdownHeader, Logo, FunnelLink, FlexCenterProfile } from './styles';

const { routes } = constants;

/**
 * ProfilNav
 * @component
 *
 */
function ProfilNav({ history }) {
  const { logOut, user, isAuthenticated } = useUser();
  const handleLogout = async event => {
    event.preventDefault();
    await logOut();
    history.push(routes.DEFAULT);
  };
  // const handleRedirection = () => {
  //   props.history.push('/moncompte');
  // };

  return (
    <React.Fragment>
      <FlexCenterProfile>
        <DropdownHeader
          minWidth="120px"
          marginRight="15px"
          fix
          defaultValue="Historique"
          options={[
            <FunnelLink to={`${routes.HISTORY}/funnel`} title="">
              <Logo
                className="sub_nav_logo"
                src="/assets/img/icon_funnel.png"
                alt=""
              />
              <span>Funnel</span>
            </FunnelLink>
          ]}
        />
        {isAuthenticated && (
          <DropdownHeader
            fix
            options={[
              <a
                href="#"
                onClick={() => {}}
                onKeyUp={() => {}}
                tabIndex={0}
                title=""
                role="button"
              >
                <Logo
                  src="/assets/img/picto_profil.svg"
                  alt="Icone mon compte"
                />
                Mon compte
              </a>,
              <a
                onClick={handleLogout}
                onKeyUp={handleLogout}
                role="button"
                tabIndex={0}
                title=""
              >
                <Logo
                  src="/assets/img/icons/logout.svg"
                  alt="Icone se deconnecter"
                />
                Se déconnecter
              </a>
            ]}
            defaultValue={
              user.attributes ? user.attributes['custom:nickname'] : ''
            }
          />
        )}
      </FlexCenterProfile>
    </React.Fragment>
  );
}

ProfilNav.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(ProfilNav);
