import React from 'react';
// import PropTypes from 'prop-types'

import BaseLoading from './style';

/**
 * LoadingInsight
 * @component
 *
 */
function LoadingInsight(props) {
  return <BaseLoading {...props} />;
}
LoadingInsight.defaultProps = {};
LoadingInsight.propTypes = {};

export default LoadingInsight;
