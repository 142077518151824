export default [
  {
    title: 'Socio-démographie',
    name: 'sociodemography',
    subCategories: [
      {
        title: 'Age',
        name: 'age',
        criterias: [
          {
            title: '<18',
            ermes: 51,
            insee: 52,
            checked: false,
            exclude: false,
            description: 'personnes agées de moins de 18 ans'
          },
          {
            title: '18-24',
            ermes: 46,
            insee: 49,
            checked: false,
            exclude: false,
            description: 'personne agées de 18 à 15 ans'
          },
          {
            title: '25-29',
            ermes: 42,
            insee: 44,
            checked: false,
            exclude: false,
            description: 'personnes aggées de 25 à 29 ans'
          },
          {
            title: '30-34',
            ermes: 38,
            insee: 41,
            checked: false,
            exclude: false,
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
          },
          {
            title: '35-39',
            ermes: 51,
            insee: 52,
            checked: false,
            exclude: false,
            description: 'personnes aggées de 35 à 39 ans'
          },
          {
            title: '40-44',
            ermes: 51,
            insee: 52,
            checked: false,
            exclude: false,
            description: 'personnes aggées de 40 à 44 ans'
          }
        ]
      },
      {
        title: 'Sexe',
        name: 'sex',
        criterias: [
          {
            title: 'Homme',
            ermes: 51,
            insee: 52,
            checked: false,
            exclude: false
          },
          {
            title: 'Femme',
            ermes: 46,
            insee: 49,
            checked: false,
            exclude: false
          }
        ]
      },
      {
        title: 'Enfants',
        name: 'children',
        criterias: [
          {
            title: 'Avec',
            ermes: 51,
            insee: 52,
            checked: false,
            exclude: false
          },
          {
            title: 'Sans',
            ermes: 51,
            insee: 52,
            checked: false,
            exclude: false
          }
        ]
      },
      {
        title: 'Bébé (-2ans)',
        name: 'baby',
        criterias: [
          {
            title: 'Non',
            ermes: 51,
            insee: 52,
            checked: false,
            exclude: false
          },
          {
            title: '1',
            ermes: 51,
            insee: 52,
            checked: false,
            exclude: false
          },
          {
            title: '2+',
            ermes: 51,
            insee: 52,
            checked: false,
            exclude: false
          }
        ]
      }
    ]
  },
  {
    title: 'Géographie',
    name: 'geography',
    subCategories: [
      {
        title: 'Cartographies',
        name: 'cartographies',
        criterias: [
          {
            title: 'Paris',
            ermes: 70,
            checked: false,
            exclude: false
          },
          {
            title: 'Hors Paris',
            ermes: 30,
            checked: false,
            exclude: false
          }
        ]
      },
      {
        title: 'Code postaux',
        name: 'postalCode',
        criterias: [
          {
            title: '75017',
            ermes: 30,
            checked: false,
            exclude: false
          },
          {
            title: '92000',
            ermes: 30,
            checked: false,
            exclude: false
          },
          {
            title: '77140',
            ermes: 30,
            checked: false,
            exclude: false
          }
        ]
      }
    ]
  },
  {
    title: 'Engagement / Médias',
    name: 'engagements',
    subCategories: [
      { title: 'TV', name: 'tv', criterias: [] },
      { title: 'Radio', name: 'radio', criterias: [] },
      { title: 'Presse', name: 'press', criterias: [] }
    ]
  },
  {
    title: "Typologies d'acheteur",
    name: 'typology',
    subCategories: [
      { title: 'Promo addict', name: 'promoAdict', criterias: [] },
      { title: 'e-commerce', name: 'ecommerce', criterias: [] },
      { title: 'Achat à crédit', name: 'creditBying', criterias: [] }
    ]
  },
  {
    title: "Centres d'intérêts",
    name: 'interest',
    subCategories: [
      { title: 'Actualité', name: 'actu', criterias: [] },
      { title: 'Voyages', name: 'traveling', criterias: [] },
      { title: 'Mode', name: 'mode', criterias: [] },
      { title: 'Jeux vidéo', name: 'videoGames', criterias: [] }
    ]
  },
  {
    title: 'Courses au quotidien',
    name: 'courses',
    subCategories: [
      { title: 'Bio', name: 'bio', criterias: [] },
      { title: 'Bébé', name: 'baby', criterias: [] },
      { title: 'Épicerie', name: 'grocery', criterias: [] },
      { title: 'Beauté', name: 'beauty', criterias: [] }
    ]
  },
  {
    title: "Intentions d'achat",
    name: 'achat',
    subCategories: [
      { title: 'Téléphone', name: 'phone', criterias: [] },
      { title: 'Sport', name: 'sport', criterias: [] },
      { title: 'Mode', name: 'mode', criterias: [] },
      { title: 'Déco', name: 'deco', criterias: [] },
      { title: 'Auto', name: 'auto', criterias: [] }
    ]
  }
];
