import styled from 'styled-components';

export const ImgContainer = styled.div`
  display: inline-block;

  .connectorContainer {
    padding: 30px 20px;
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 200px;
    background-color: white;
  }
`;

export const Image = styled.div``;
