import React from 'react';
import PropTypes from 'prop-types';

import { Button, InputContainer, Label } from './style';

function ConnectorForm(props) {
  const {
    updateConnected,
    updateConnector,
    connected,
    connector,
    source,
    cancel
  } = props;
  const [email, updateEmail] = React.useState('');
  const [password, updatePassword] = React.useState('');

  const markAsConnected = () => {
    updateConnector(null);
    updateEmail('');
    updatePassword('');
    updateConnected(
      connected.map((c, i) => c || i === source.find(co => co === connector.id))
    );
  };

  return (
    <div
      style={{
        display: connector && connector.sso ? 'block' : 'none'
      }}
    >
      <form>
        <InputContainer className="mail">
          <Label htmlFor="email" className="is-flex align-center">
            <span>Email</span>
            <input
              type="text"
              className="input"
              name="uname"
              id="email"
              value={email}
              onChange={e => updateEmail(e.target.value)}
              required
            />
          </Label>
        </InputContainer>

        <InputContainer className="password">
          <Label htmlFor="psw" className="is-flex align-center">
            <span>Mot de passe</span>
            <input
              type="password"
              className="input"
              name="psw"
              id="psw"
              value={password}
              onChange={e => updatePassword(e.target.value)}
              required
            />
          </Label>
        </InputContainer>

        <div className="opt-in is-flex">
          <label htmlFor="data" className="opt-in-container">
            J’autorise Ermes à accéder à mon compte pour en extraire les données
            nécessaires à ErmesFunnel
            <input type="checkbox" id="data" name="data" />
            <span className="checkmark" />
          </label>
        </div>
        <div className="input-container is-flex justify-content-between">
          <Button
            className="link"
            onClick={cancel}
            onKeyPress={cancel}
            role="button"
            tabIndex="0"
          >
            Annuler
          </Button>
          <Button
            className="btn btn--connector"
            onClick={markAsConnected}
            onKeyPress={markAsConnected}
            role="button"
            tabIndex="0"
          >
            Valider
          </Button>
        </div>
      </form>
    </div>
  );
}

ConnectorForm.propTypes = {
  updateConnector: PropTypes.func.isRequired,
  updateConnected: PropTypes.func.isRequired,
  connected: PropTypes.arrayOf(PropTypes.any).isRequired,
  connector: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired
};
export default ConnectorForm;
