import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

export const Button = styled.div`
  border: 1px solid ${colors.funnel};
`;

export const InputContainer = styled.div`
  margin: auto;
`;

export const Label = styled.label`
  display: block;
  margin 0 8px;

  .input {
    padding: 0 10px;
    border: 1px solid #4e85ec;
    border-radius: 10px;
    height: 35px;
    margin: auto;
    font-size: 16px;
  }
`;
