import React from 'react';
import PropTypes from 'prop-types';

import BaseCriteriasGroup from './style';

/**
 * CriteriasGroupInsight
 * @component
 * @see CriteriaContainer
 *
 */
function CriteriasGroupInsight(props) {
  const { title, children, hideTitle } = props;
  return (
    <BaseCriteriasGroup>
      {hideTitle ? <div /> : <div className="title">{title}</div>}
      <div className="criteria">{children}</div>
    </BaseCriteriasGroup>
  );
}
CriteriasGroupInsight.defaultProps = {
  hideTitle: false
};
CriteriasGroupInsight.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideTitle: PropTypes.bool
};

export default CriteriasGroupInsight;
