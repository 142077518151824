import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import constants from 'utils/constants';
import Dropdown from 'components/Dropdown';

const { colors } = constants;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;
export const HeaderErmes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 40px 0 40px;
  position: relative;
  border-bottom: 8px solid;
  border-color: ${({ color }) => colors[color] || colors.funnel};
`;
export const NavTab = styled.ul`
display: flex;
align-items: center;
list-style-type: none;
padding: 0;
margin: 3px 0 0 40px;

  a {
    display: flex;
    align-items: center;
    color: ${colors.light};
    flex-direction: column;
  }
  
  a.selected {
    background-color: ${colors.funnel};
    color:${colors.light};
    font-weight: 700;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    &:after {
      content:"";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 8px solid #009fe3;
      z-index: 10;
    }
  }
}
`;

export const NavTabItem = styled.li`
  min-height: 50px;
  display: flex;
  text-transform: uppercase;
  align-items: flex-end;
  .nav-tab-link {
    padding: 12px 20px;
    div {
      margin-top: 4px;
    }
  }
  background-color: ${props => (props.isFocused ? colors.funnel : 'inherit')};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

export const NavImg = styled.img`
  margin-top: 5px;
  width: 130px;
`;

export const BaseDefaultHeader = styled.div`
  position: fixed;
  box-sizing: border-box;
  background-color: ${({ isOnScroll }) =>
    isOnScroll ? colors.light : 'transparent'};
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  .test-link {
    color: ${({ isOnScroll }) => (isOnScroll ? colors.dark : colors.light)};
    margin-right: 20px;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    p a {
      display: block;
      text-align: center;
      margin-right: auto;
      margin: 0 auto 15px auto;
    }

    button {
      display: block;
      margin: 0 auto;
    }
  }
`;

/**
 * @params isActive
 * @params nav
 *
 */
export const BaseHeaderLink = styled(Link)`
  background-color: ${({ isActive, color, nav }) =>
    isActive ? color : colors[nav]};
  color: ${colors.secondary};
  text-decoration: none;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : '')};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
`;

const StyledDropdown = styled(Dropdown)`
  width: auto;
  display: flex;
  color: ${colors.light};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  min-height: 42px;

  .dropdown-content {
    border: 1px solid ${colors.funnel};
  }

  .dropdown-content,
  .dropdown-content a {
    top: 42px;
    width: auto;
    min-width: ${({ minWidth }) => minWidth || '200px'};
    right: 0;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    color: ${colors.light};
    background-color: ${colors.primary};
  }
  .dropdown-content a:hover {
    width: auto;
    font-size: 16px;
    background-color: ${colors.tertiary};
    font-weight: bold;
    a {
      background-color: ${colors.tertiary};
    }
  }
`;

// ProfileNav style

export const DropdownHeader = props => (
  <StyledDropdown
    color={colors.light}
    bgColor={colors.primary}
    borderColor={colors.funnel}
    maxWidth="300px"
    {...props}
  />
);

export const Logo = styled.img`
  width: 20px;
  margin: 0 7px 0 0;
  vertical-align: middle;
  border-style: none;
`;

export const FunnelLink = styled(Link)`
  text-decoration: none;
  display: block;
  font-size: 16px;
`;

export const FlexCenterProfile = styled.ul`
  list-style-type: none;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  list-style: none;
`;

export default {};
