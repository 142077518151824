import React from 'react';
import PropTypes from 'prop-types';

import BaseOperatedCriteria from './style';

/**
 * OperatedCriteria
 * @component
 * @see SelectedListContainer
 *
 */
function OperatedCriteriaInsight(props) {
  const { title, isExcluded, toggleExclude, isFirst, isLast } = props;
  const handleClick = e => {
    e.preventDefault();
    toggleExclude();
  };
  return (
    <BaseOperatedCriteria
      href="#"
      inversed={isExcluded}
      isFirst={isFirst}
      isLast={isLast}
      onClick={handleClick}
    >
      {isExcluded ? `Hors ${title}` : title}
    </BaseOperatedCriteria>
  );
}
OperatedCriteriaInsight.propTypes = {
  isExcluded: PropTypes.bool.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggleExclude: PropTypes.func.isRequired
};

export default OperatedCriteriaInsight;
