import React from 'react';
import PropTypes from 'prop-types';
import { HistoryWrapper, HistoryTitle } from './style';

/**
 * @component
 *
 */
function History(props) {
  const { children } = props;
  return (
    <HistoryWrapper>
      <HistoryTitle>Historique</HistoryTitle>
      {children}
    </HistoryWrapper>
  );
}

History.defaultProps = {
  data: null,
  children: undefined
};
History.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node
};

export default History;
