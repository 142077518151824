import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

/**
 * @component
 * @see OperatorContainer
 */
const BaseOperatedCategory = styled.div`
  background-color: colors.secondary;
  padding: 4px;
  border-top: 1px solid ${colors.secondary};
  overflow-y: auto;
`;

export default BaseOperatedCategory;
