import Cookie from 'js-cookie';
import parser from 'cookie';

export async function fetchFromCookie(req, options = {}) {
  return key => {
    if (req) {
      // server
      const cookies = req.headers.cookie;
      if (!cookies) return null;
      const cookieMap = parser.parse(cookies, options);
      return cookieMap[key];
    }
    // browser
    return Cookie.get(key);
  };
}

/**
 * isEmptyObject
 * @param {Object} obj
 * @desc check if an object is really empty eq = {}
 * @return Boolean
 *
 */
export function isEmptyObject(obj) {
  return obj && !Object.values(obj).length;
}

export default fetchFromCookie;
