import React from 'react';
import { Query } from 'react-apollo';

import CategoriesInsight, {
  CategoriesItem
} from 'Insight/components/CategoriesInsight';
import LoadingInsight from 'Insight/components/LoadingInsight';
import { useCategory } from 'Insight/contexts/CategoryContext';
import { INSIGHT } from 'utils/graphQL';

/**
 * CategoriesContainer
 * @component
 *
 */
function CategoriesContainer() {
  const { setCurrentCategory, setAllCategories } = useCategory();
  const [selected, setSelected] = React.useState(0);
  const [categories, setCategories] = React.useState([]);
  const handleSelect = (categoryId, categoryIndex) => {
    setCurrentCategory(categoryId);
    setSelected(categoryIndex);
  };
  const onLoadingSuccess = data => {
    const { viewer } = data;
    if (!viewer) return;
    const nextCategories = viewer.filteredData.categories;
    setCategories(nextCategories);
    setAllCategories(nextCategories);
  };
  React.useEffect(() => {
    if (categories[0]) {
      const firstCategoryId = categories[0].id;
      setCurrentCategory(firstCategoryId);
    }
  }, [categories]);
  return (
    <Query query={INSIGHT.GET_ALL_CATEGORIES}>
      {response => (
        <LoadingInsight {...response} onLoadingSuccess={onLoadingSuccess}>
          <CategoriesInsight length={categories.length || 0}>
            {categories.map((category, index) => (
              <CategoriesItem
                key={category.name}
                name={category.name}
                index={index}
                isActive={selected === index}
                title={category.name}
                description={category.description}
                handleSelect={() => handleSelect(category.id, index)}
              />
            ))}
          </CategoriesInsight>
        </LoadingInsight>
      )}
    </Query>
  );
}
//
// ",
// "variables":{
//    "categoryId":"Q2F0ZWdvcnk6QzFBMDY5NjAtMzUyQS05Q0FBLTkxNTYtNEYzMkVGMzU3NkQz",
//    "filters":[
//    ],
//    "compareWithAnalysisId":null,
//    "compareWithAudienceId":null,
//    "panelMode":false,
//    "targetedAudience":true
// }
export default CategoriesContainer;
