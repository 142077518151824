import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useUser } from 'contexts/UserContext';
import Loading from 'components/Loading';
import constants from 'utils/constants';

const { routes } = constants;

/**
 * LoadingAuth
 * wait for reconnect
 *
 */
const LoadingAuth = props => {
  const { children, isLoading, isAuthenticated } = props;
  const [ComponentChild, RedirectAuth] = React.Children.toArray(children);
  React.useEffect(() => {}, [isLoading]);
  return (
    <Loading loading={isLoading} data={{ component: true }}>
      {isAuthenticated && ComponentChild}
      {!isAuthenticated && RedirectAuth}
    </Loading>
  );
};
LoadingAuth.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

/**
 * AuthenticatedRoute
 * @component Route
 *
 */
function AuthenticatedRoute(props) {
  const { isAuthenticated, isLoadingAuth } = useUser();
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={renderProps => (
        <LoadingAuth
          isLoading={isLoadingAuth}
          isAuthenticated={isAuthenticated}
        >
          <Component {...renderProps} />
          <Redirect to={routes.DEFAULT} />
        </LoadingAuth>
      )}
    />
  );
}
AuthenticatedRoute.propTypes = {
  component: PropTypes.node.isRequired
};

export default AuthenticatedRoute;
