import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'utils/moment';
import 'react-dates/initialize';

import 'react-dates/lib/css/_datepicker.css';
import BaseDateRange, { BaseDateWrapper } from './style';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null
    };
  }

  isOutsideRange(date) {
    return moment(date).isAfter();
  }

  render() {
    console.log(`startdate = ${this.props.startDate.isValid()}`);
    return (
      <div className="datepicker">
        <BaseDateWrapper {...this.props}>
          <BaseDateRange
            startDateId="startDate"
            endDateId="endDate"
            startDatePlaceholderText="Date de début"
            endDatePlaceholderText="Date de fin"
            startDate={
              this.props.startDate.isValid() ? this.props.startDate : null
            }
            endDate={this.props.endDate.isValid() ? this.props.endDate : null}
            onDatesChange={({ startDate, endDate }) => {
              this.props.onDatesChange({ startDate, endDate });
            }}
            isOutsideRange={this.isOutsideRange}
            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => {
              this.setState({ focusedInput });
            }}
          />
        </BaseDateWrapper>
      </div>
    );
  }
}

DatePicker.propTypes = {
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  onDatesChange: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(moment().format()),
  end: PropTypes.instanceOf(moment().format())
};

DatePicker.defaultProps = {
  startDate: null,
  endDate: null,
  start: null,
  end: null
};

export default DatePicker;
