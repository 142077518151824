import { gql } from 'apollo-boost';

export default gql`
  query common_SegmentListQuery(
    $categoryId: ID!
    $filters: JSON
    $compareWithAnalysisId: ID
    $compareWithAudienceId: ID
    $panelMode: Boolean
    $targetedAudience: Boolean = true
  ) {
    viewer {
      filteredData(
        filters: $filters
        compareWithAnalysisId: $compareWithAnalysisId
        compareWithAudienceId: $compareWithAudienceId
        panelMode: $panelMode
        targetedAudience: $targetedAudience
      ) {
        segments(categoryId: $categoryId) {
          edges {
            node {
              ...SegmentList_segments
              ...SegmentListGeolocation_segments
              id
            }
          }
        }
        ...SegmentList_filteredData
        subCategories: categories(parentId: $categoryId) {
          __typename
          ...SegmentList_subCategories
          segments {
            edges {
              node {
                ...SegmentList_segments
                ...SegmentListGeolocation_segments
                id
              }
            }
          }
          id
        }
        dataCost {
          cpmMed
        }
        id
      }
      id
    }
  }
  fragment SegmentList_segments on Segment {
    __typename
    id
    description
    ... on Node {
      id
    }
    ... on SegmentInterface {
      segmentId
      features
      name
      category {
        id
      }
    }
    filteredPercent: audiences(type: FILTERED) {
      position
    }
    subSegments {
      edges {
        node {
          id
          name
          description
          segmentId
          filteredPercent: audiences(type: FILTERED) {
            position
          }
          category {
            id
          }
        }
      }
    }
    ...SegmentSegment_segment
  }
  fragment SegmentListGeolocation_segments on Segment {
    ...GeographicAreaMap_segments
  }
  fragment SegmentList_filteredData on FilteredData {
    nbProfiles
    nbProfilesTotalOpenWeb: nbProfiles(type: NB_COOKIES)
    nbProfilesCookies: nbProfiles(type: COOKIE)
    nbProfilesEmails: nbProfiles(type: EMAIL)
    nbProfilesForPanelMode: nbProfiles(type: PANEL)
    nbProfilesMobiles: nbProfiles(type: MOBILE)
    nbProfilesTotalPanel: nbProfiles(type: TOTAL_PANEL)
    nbProfilesOnlyLookalike: nbProfiles(type: TOTAL_ONLY_LOOKALIKE)
    nbProfilesNoLookalike: nbProfiles(type: TOTAL_NO_LOOKALIKE)
  }
  fragment SegmentList_subCategories on SubCategory {
    id
    name
    ...SubCategory_category
    segments {
      edges {
        node {
          ...SegmentList_segments
          ...SegmentListGeolocation_segments
          id
        }
      }
    }
  }
  fragment SubCategory_category on SubCategory {
    id
    name
    segments {
      edges {
        node {
          ...Segments_segments
          id
        }
      }
    }
  }
  fragment Segments_segments on SegmentInterface {
    ... on Node {
      id
    }
    segmentId
    features
    name
    category {
      id
    }
    filteredPercent: audiences(type: FILTERED) {
      position
    }
    ...SegmentSegment_segment_3I3uio
  }
  fragment SegmentSegment_segment_3I3uio on SegmentInterface {
    ...SegmentContent_segment_3I3uio
    ... on Segment {
      subSegments {
        edges {
          node {
            ...SegmentsWithSubSegments_segments_3I3uio
            id
          }
        }
      }
    }
  }
  fragment SegmentContent_segment_3I3uio on SegmentInterface {
    selectable
    name
    active
    features
    segmentId
    description
    audiences {
      lookAlike
      wantBuy
      buyers
      all
      total
      occasional
      regular
      veryRegular
      reference
      referencePanel {
        base
        occasional
        regular
        veryRegular
      }
    }
    comparedAudiences: audiences(type: COMPARED) {
      lookAlike
      wantBuy
      buyers
      all
      total
      occasional
      regular
      veryRegular
      reference
      referencePanel {
        base
        occasional
        regular
        veryRegular
      }
    }
  }
  fragment SegmentsWithSubSegments_segments_3I3uio on SegmentInterface {
    ... on Node {
      id
    }
    segmentId
    features
    name
    category {
      id
    }
    filteredPercent: audiences(type: FILTERED) {
      position
    }
    ...SegmentSubSegment_segment_3I3uio
  }
  fragment SegmentSubSegment_segment_3I3uio on SegmentInterface {
    ...SegmentContent_segment_3I3uio
    ... on SubSegment {
      subSegments {
        edges {
          node {
            ...SegmentsWithSubSubSegments_segments_3I3uio
            id
          }
        }
      }
    }
  }
  fragment SegmentsWithSubSubSegments_segments_3I3uio on SegmentInterface {
    ... on Node {
      id
    }
    segmentId
    features
    name
    category {
      id
    }
    filteredPercent: audiences(type: FILTERED) {
      position
    }
    ...SegmentSubSubSegment_segment_3I3uio
  }
  fragment SegmentSubSubSegment_segment_3I3uio on SubSubSegment {
    ...SegmentContent_segment_3I3uio
  }
  fragment GeographicAreaMap_segments on Segment {
    id
    name
    features
    audiences {
      all
    }
    filteredPercent: audiences(type: FILTERED) {
      all
    }
  }
  fragment SegmentSegment_segment on SegmentInterface {
    ...SegmentContent_segment_3I3uio
    ... on Segment {
      subSegments {
        edges {
          node {
            ...SegmentsWithSubSegments_segments_3I3uio
            id
          }
        }
      }
    }
  }
`;
