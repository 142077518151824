import styled from 'styled-components';
import Hider from 'components/Hider';
// import constants from 'utils/constants';
//
// const { colors } = constants;

/**
 * @component
 *
 */
const BaseHiderFunnel = styled(Hider)``;

export default BaseHiderFunnel;
