import colors from './colors';
import landingRoles from './landingRoles';
import panels from './panels';
import measures from './measures';
import connectors from './connectors';
import featureContent from './featureContent';
import policies from './policies';
import routes from './routes';
import exampleCategories from './exampleCategories';
import insightSegmentTypes from './insightSegmentTypes';

export default {
  colors,
  connectors,
  exampleCategories,
  featureContent,
  landingRoles,
  measures,
  insightSegmentTypes,
  panels,
  policies,
  routes
};
