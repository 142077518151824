import styled from 'styled-components';

export const PreviewItem = styled.div`
  background-color: #3e6de7;
  border-radius: 15px;
  width: ${props => props.width};
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 5px 20px;
  color: white;
  font-weight: bold;
`;

const BasePreviewFunnel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export default BasePreviewFunnel;
