import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;
const getBorderRadius = ({ isFirst, isLast }) => {
  if (isFirst && isLast) return '15px';
  if (isFirst) return '15px 0 0 15px';
  if (isLast) return '0 15px 15px 0';
  return 0;
};
/**
 * ConnectorCriteria
 * @component
 *
 */
const OperatedCriteria = styled.a`
  background-color: ${({ inversed }) =>
    inversed ? 'transparent' : colors.insight};
  color: ${({ inversed }) => (inversed ? colors.insight : colors.light)};
  border: 1px solid ${colors.insight};
  border-radius: ${({ isFirst, isLast }) =>
    getBorderRadius({ isFirst, isLast })};
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  padding: 1px 5px;
  text-transform: uppercase;
  transition: all 0.3s ease-out;
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 16px;
  font-weight: 600;
`;
export default OperatedCriteria;
