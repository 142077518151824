import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import constants from 'utils/constants';
import { Table, Column, Row, Title, Data } from './style';

const { measures } = constants;

export default class BaselineRow extends Component {
  render() {
    const { connected, id, filter, kpi, funnel } = this.props;

    const connectorBaseline = _.find(connected, c => c.funnelId === id);
    const hasDataBaseline = connectorBaseline && connectorBaseline.data.length;
    const connectorDataBaseline = hasDataBaseline
      ? _.filter(connectorBaseline.data, filter)
      : [];
    const totalB = hasDataBaseline
      ? _.sumBy(connectorDataBaseline, c => c.display)
      : 0;
    const totalBudget = hasDataBaseline
      ? _.sumBy(connectorDataBaseline, c => c.budget)
      : 0;
    const connector = _.find(connected, c => c.funnelId === kpi);
    const hasData = connector && connector.data.length;
    const connectorData = hasData ? _.filter(connector.data, filter) : [];
    const totalI = hasData ? _.sumBy(connectorData, c => c.value) : 0;
    const total = totalI - totalB;
    const kpiHB = totalBudget / totalB;
    const baselineMeasures = measures.find(a => a.name === funnel.measure.name);
    return (
      <Table>
        <Row>
          <Column textAlign="center">
            <Title>
              <Data>{totalI.toLocaleString('en-gb').replace(',', ' ')}</Data>
              {`\n${baselineMeasures.baseline.wording.total}`}
            </Title>
          </Column>
        </Row>
        <Row>
          <Column color="light" bgColor="funnel" textAlign="center">
            <div>
              <Data>{total.toLocaleString('en-gb').replace(',', ' ')}</Data>
              {`\nadvertising`}
            </div>
          </Column>
          <Column paddingLeft="15">
            <div>
              <Data light>
                {baselineMeasures.baseline.wording.advertising_title}
              </Data>
              {`\n${baselineMeasures.baseline.wording.advertising_description}`}
            </div>
          </Column>
        </Row>
        <Row>
          <Column color="funnel" bgColor="light" textAlign="center">
            <div>
              <Data>{totalB.toLocaleString('en-gb').replace(',', ' ')}</Data>
              {'\nbaseline'}
            </div>
          </Column>
          <Column paddingLeft="15">
            <div>
              <Data light>
                {baselineMeasures.baseline.wording.baseline_title}
              </Data>
              {`\n${baselineMeasures.baseline.wording.baseline_description}`}
            </div>
          </Column>
        </Row>
        <Row height="30px" />
        <Row>
          <Column
            color="ligth"
            bgColor="funnel"
            textAlign="center"
            padding="15px"
          >
            <Data>{kpiHB.toFixed(2)}€</Data>
          </Column>
          <Column padding="10px" paddingLeft="15">
            <div>
              <Data light>{baselineMeasures.baseline.wording.kpi_title}</Data>
              {`\n${baselineMeasures.baseline.wording.pki_description}`}
            </div>
          </Column>
        </Row>
      </Table>
    );
  }
}

BaselineRow.propTypes = {
  id: PropTypes.string.isRequired,
  connected: PropTypes.array.isRequired,
  filter: PropTypes.func.isRequired,
  kpi: PropTypes.string.isRequired,
  funnel: PropTypes.object.isRequired
};
