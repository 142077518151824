const config = {
  identityPoolId: 'eu-west-1:b5c9e922-6929-440a-824c-675fecfc628c',
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_DV5ShpPPs',
  userPoolWebClientId: '37lsqpl8f13kriko5lojbe05r8',
  /*
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: 'localhost',
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: false
    },
    */
  graphqlEndpoint:
    'https://74kmiarokbbjlpmzv3owwlinom.appsync-api.eu-west-1.amazonaws.com/graphql'
  // apiKey: 'w43vfhpaofdr7ivsdmdtytqwtu'
};

export const COGNITO_ID_TOKEN_COOKIE_NAME = 'cognito-id-token';

export default config;
