import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

/* eslint no-nested-ternary: 0 */

export default class ObjectifRow extends Component {
  render() {
    const {
      funnel,
      id,
      objectifed,
      connected,
      filter,
      per,
      // array,
      savet3,
      worstlevierCPI
    } = this.props;

    const funnelIndex = _.findIndex(funnel.measure.funnels, f => f.id === id);
    const objectif = _.find(objectifed, c => c.funnelId === id);
    const value = parseFloat(objectif.value);
    const connector = _.find(connected, c => c.funnelId === id);
    const connectorData = _.filter(connector.data, filter);
    const reeltotal = [];
    connectorData.map(d => {
      reeltotal.push(d.cost);
      return d.cost;
    });
    let reel = reeltotal.reduce((p, c, l, a) => p + c / a.length, 0);
    if (this.props.per !== 0) {
      reel *= per;
    }
    reel = parseFloat(reel.toFixed(2));
    let reelParent = 0;
    let objectifRate = 0;
    if (funnelIndex > 0) {
      const parentFunnel = funnel.measure.funnels[funnelIndex - 1];
      const parent = _.find(connected, c => c.funnelId === parentFunnel.id);
      const parentObjectif = _.find(
        objectifed,
        c => c.funnelId === parentFunnel.id
      );
      let parentValue = parseFloat(parentObjectif.value);
      const parentData = _.filter(parent.data, filter);
      const totalParent = connector ? _.sumBy(parentData, c => c.value) : 0;
      const budgetParent = connector ? _.sumBy(parentData, c => c.budget) : 0;
      reelParent = parseFloat(budgetParent / totalParent);
      if (parentFunnel.per !== 1) {
        parentValue /= parentFunnel.per;
      }
      objectifRate = ((parentValue / value) * 100).toFixed(2);
    }
    // let newObjectif = 0;

    // newObjectif = reel * (1 - 0.1 * Math.exp(-(value - reel) / reel));
    /* if (reel - value > reel / 3) {
      newObjectif = reel - (reel * 10) / 100;
    } else if (reel <= value) {
      newObjectif = reel - (reel * 5) / 100;
    } else {
      newObjectif = _.mean([reel, value]);
    } */

    // let t = 1;
    // for (let i = 0; i < 3; i += 1) {
    //   if (i === 0) t *= parseFloat(array[i]);
    // }
    const t1 =
      funnelIndex !== 0
        ? ((reelParent / reel) * 100).toFixed(2)
        : reel.toFixed(2);
    const t2 = funnelIndex > 0 ? objectifRate : value.toFixed(2);
    const t3 = ((t1 - t2) / t2) * 100;
    const { title } = funnel.measure.funnels[funnelIndex];
    const affichet3 = title === 'CPM' ? t3.toFixed(2) * -1 : t3.toFixed(2);
    const className = affichet3 > 0 ? 'green' : 'red';
    if (funnel.measure.funnels[funnelIndex].title === worstlevierCPI) {
      if (savet3 > t3)
        this.props.newt3(t3, funnel.measure.funnels[funnelIndex].title);
    }
    if (savet3 < t3) {
      this.props.newt3(t3, funnel.measure.funnels[funnelIndex].title);
    }

    return (
      <tr>
        <td>{title}</td>
        <td>
          {funnelIndex > 0 ? objectifRate : value.toFixed(2)}
          {funnelIndex > 0 ? '%' : '€'}
        </td>
        <td>
          {funnelIndex !== 0
            ? ((reelParent / reel) * 100).toFixed(2)
            : reel.toFixed(2)}
          {funnelIndex !== 0 ? '%' : '€'}
        </td>
        {/* <td>
          {funnelIndex !== 0
            ? ((reelParent / newObjectif) * 100).toFixed(2) >= 100
              ? 100
              : ((reelParent / newObjectif) * 100).toFixed(2)
            : newObjectif.toFixed(2)}
          {funnelIndex !== 0 ? '%' : '€'}
        </td> */}
        <td>
          <div
            style={{
              width: '88px',
              textAlign: 'center',
              backgroundColor: className,
              borderRadius: '7px',
              fontWeight: 'bold',
              padding: '3px 7px'
            }}
          >
            {affichet3}%
          </div>
        </td>
      </tr>
    );
  }
}

ObjectifRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  funnel: PropTypes.object.isRequired,
  connected: PropTypes.array.isRequired,
  objectifed: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  average: PropTypes.number.isRequired,
  filter: PropTypes.func.isRequired,
  per: PropTypes.number,
  array: PropTypes.array,
  worstlevierCPI: PropTypes.string.isRequired,
  savet3: PropTypes.number.isRequired,
  newt3: PropTypes.func.isRequired
};

ObjectifRow.defaultProps = {
  per: 0,
  array: []
};
