import React from 'react';
import constants from 'utils/constants';
import Button from './style';

const { colors } = constants;

export const ButtonPrimary = props => {
  return <Button bgcolor={colors.primary} {...props} />;
};

export const ButtonConnect = props => {
  return (
    <Button disableHover bgcolor={colors.funnel} color="#FFFFFF" {...props} />
  );
};

export default Button;
