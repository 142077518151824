import React from 'react';
import PropTypes from 'prop-types';

import BaseHider from './style';

/**
 * HiderFunnel
 * @component
 *
 */
function Hider({ children, hide }) {
  return (
    <BaseHider hide={hide}>
      {children}
      <div className="hide-block" />
    </BaseHider>
  );
}
Hider.defaultProps = {};
Hider.propTypes = {
  children: PropTypes.node.isRequired,
  hide: PropTypes.func.isRequired
};

export default Hider;
