export default () => null;

/**
 * extractResponseData
 * @param Object api response
 * @return Object
 */
export const extractResponseData = response => {
  const { viewer } = response;
  if (!viewer) return {};
  const { filteredData } = viewer;
  const {
    segments,
    subCategories: nextSubCategories,
    nbProfiles,
    nbProfilesCookies,
    nbProfilesEmails
  } = filteredData;
  const hasInseeData = nextSubCategories.some(subCat =>
    subCat.segments.edges.some(
      ({ node: criteria }) => criteria.audience && criteria.audiences.reference
    )
  );
  const insightData = { nbProfiles, nbProfilesCookies, nbProfilesEmails };
  return {
    nextSubCategories,
    segments: segments.edges,
    hasInseeData,
    insightData
  };
};

/**
 * getPercent
 * @param [number] value from back |  (ex: 0.344)
 * @param [string] field from back | (ex: reference)
 * @return Number
 */
const getPercent = (value, field) => {
  if (!value || !value[field]) return 0;
  return Math.round(value[field] * 100);
};

/**
 * sortCriteria
 * @param criteriaList
 * @desc for use in sort functions
 * @warning sort modify current Array reference, copy it before use
 *
 */
export const sortCriteria = (first, next) => {
  let { filteredPercent: A } = first.node;
  let { filteredPercent: B } = next.node;
  A = A || 0;
  B = B || 0;
  return B - A;
};

export const getSegmentProps = criteria => {
  return {
    ermes: getPercent(criteria.audiences, 'all'),
    insee: getPercent(criteria.audiences, 'reference'),
    title: criteria.name,
    selectable: criteria.selectable,
    description: criteria.description
  };
};
