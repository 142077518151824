import { gql } from 'apollo-boost';

export default gql`
  query audience_AudienceMainLayoutQuery {
    viewer {
      ...AudienceMainLayout_viewer
      filteredData {
        categories {
          __typename
          id
        }
        id
      }
      id
    }
  }

  fragment AudienceMainLayout_viewer on Viewer {
    firstName
    lastName
    ...AudienceAside_viewer
  }

  fragment AudienceAside_viewer on Viewer {
    ...CategoriesAside_viewer
  }

  fragment CategoriesAside_viewer on Viewer {
    filteredData {
      categories {
        __typename
        id
        name
        description
        ... on Category {
          nbSegments
        }
      }
      id
    }
    ...AsideMain_viewer
  }

  fragment AsideMain_viewer on Viewer {
    firstName
    lastName
    company
    permissions {
      admin {
        access
      }
      discover {
        shopperProfiling {
          access
          canExport
          expiredAt
          brands
          categories {
            id
            name
          }
        }
        shopperTrends {
          access
          canExport
          expiredAt
          brands
          categories {
            id
            name
          }
        }
        mixity {
          access
          canExport
          expiredAt
          brands
          categories {
            id
            name
          }
        }
        geography {
          access
          canExport
          expiredAt
          brands
          categories {
            id
            name
          }
        }
        panel {
          access
          canExport
          expiredAt
        }
        insightsRetailMedia {
          access
          canExport
          expiredAt
          admin
        }
      }
      targeting {
        digitalCampaign {
          access
        }
        marketingDirect {
          access
        }
      }
      salesPromotion {
        coupon {
          access
          segmentation
          admin
        }
        challenge {
          access
        }
      }
      measure {
        digital {
          access
          canExport
        }
        t2s {
          access
          canExport
        }
      }
    }
  }
`;
