import React from 'react';
import PropTypes from 'prop-types';

import BaseResultInsight from './style';

/**
 * ResultInsight
 * @component
 */
const ResultInsight = ({ name, value }) => {
  return (
    <BaseResultInsight>
      <h3 className="title">{name} :</h3>
      <div className="value-result-insight">{value}</div>
    </BaseResultInsight>
  );
};
ResultInsight.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

export default ResultInsight;
