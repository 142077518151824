import React from 'react';
import constants from 'utils/constants';
import BaseLinkFunnel from './style';

const { colors } = constants;

const getAdaptedStyle = ({ profile, redirect }) => {
  if (redirect) {
    return {
      bgColor: colors.funnel,
      padding: '3px'
    };
  }
  if (profile) {
    return {
      bgColor: 'orange',
      padding: '3px'
    };
  }
  return {};
};

/**
 * @component
 *
 */
function LinkFunnel(props) {
  const style = getAdaptedStyle(props);
  return <BaseLinkFunnel {...style} {...props} />;
}

export default LinkFunnel;
