import { gql } from 'apollo-boost';

export default gql`
  mutation CreateAudienceMutation($input: CreateAudienceInput!) {
    createAudience(input: $input) {
      audienceEdge {
        __typename
        cursor
        node {
          ...commonFragments_node
          id
          name
        }
      }
    }
  }
  fragment commonFragments_segment on Segment {
    features
    id
    name
    category {
      id
    }
  }

  fragment commonFragments_simpleFilter on SimpleFilter {
    __typename
    flags
    segment {
      ...commonFragments_segment
    }
  }

  fragment commonFragments_notFilter on NotFilter {
    __typename
    flags
    segment {
      ...commonFragments_segment
    }
  }
  fragment commonFragments_node on DocumentWithFilters {
    filter {
      ... on OrFilter {
        __typename
        filters {
          ...commonFragments_simpleFilter
          ... on AndFilter {
            __typename
            filters {
              ...commonFragments_simpleFilter
              ...commonFragments_notFilter
            }
          }
          ...commonFragments_notFilter
        }
      }
      ...commonFragments_simpleFilter
      ...commonFragments_notFilter
    }
    id
  }
`;
