import React from 'react';
import PropTypes from 'prop-types';

import { BigTitleFunnel } from 'Funnel/components/TitleFunnel';
import { useFunnel } from 'Funnel/contexts/FunnelContext';

/**
 * TitleContainer
 * @component
 *
 */
function TitleContainer(props) {
  const { funnel } = useFunnel();
  const { isRecoveryMode } = props;
  return isRecoveryMode ? <BigTitleFunnel>{funnel.name}</BigTitleFunnel> : null;
}
TitleContainer.defaultProps = {};
TitleContainer.propTypes = {
  isRecoveryMode: PropTypes.bool.isRequired
};

export default TitleContainer;
