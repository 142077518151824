import React from 'react';
import DefaultSegment from './constructors';
import {
  completeFilter,
  decreaseFilter,
  formatFilter,
  reverseOperator
} from './functions';

const SegmentContext = React.createContext();

/**
 * @deprecated
 * SegmentProvider
 * @provider
 * @desc manage all the segment process
 * @see Default Segment for konw structure data
 * @state selected: unordered list of selected (eq one criteria) for CriteriaContainer
 * @state filter: filtered selected for query variables
 * @nb segment ~= criteria but adapted for query format
 */
export default function SegmentProvider(props) {
  const [selected, setSelected] = React.useState([]);
  const [filter, setFilter] = React.useState({});

  const value = {
    formatedFilter: formatFilter(filter),
    filter,
    checkHasSegment: id => selected.indexOf(id) !== -1,
    handleSegmentChange(params) {
      const { criteria, subCategoryID, categoryID } = params;
      const criteriaID = criteria.id;
      const filterSegment = filter[subCategoryID];
      const nextSelectedArray = [...selected];
      const segmentIndex = selected.indexOf(criteria.id);
      let newFilterSegment = {};
      if (segmentIndex !== -1) {
        // remove segment
        nextSelectedArray.splice(segmentIndex, 1);
        newFilterSegment = decreaseFilter({ filterSegment, criteriaID });
      } else {
        // add segment
        nextSelectedArray.push(criteriaID);
        const oneSegment = new DefaultSegment({ id: criteriaID });
        oneSegment.name = criteria.name;
        newFilterSegment = completeFilter({ filterSegment, oneSegment });
      }
      setSelected(nextSelectedArray);
      newFilterSegment.categoryID = categoryID;
      setFilter({ ...filter, [subCategoryID]: newFilterSegment });
    },
    updateSegment({ criteriaID, subCategoryID, operator }) {
      const nextSegment = { ...filter[subCategoryID] };
      if (nextSegment.filters) {
        const filterSegmentIndex = nextSegment.filters.findIndex(
          ({ segmentID }) => segmentID === criteriaID
        );
        if (filterSegmentIndex === -1) return;
        nextSegment.filters[filterSegmentIndex].operator = reverseOperator(
          operator
        );
      } else {
        nextSegment.operator = reverseOperator(operator);
      }
      setFilter({
        ...filter,
        [subCategoryID]: nextSegment
      });
    }
  };
  return <SegmentContext.Provider {...props} value={value} />;
}

/**
 * useSegment
 * @function
 * @return value defined above (SegmentProvider)
 *
 */
export const useSegment = () => {
  const context = React.useContext(SegmentContext);
  if (!context)
    throw new Error('useSegment must be called inside SegmentProvider');
  return context;
};
