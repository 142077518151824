import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

export const HistoryWrapper = styled.div`
  height: 500px;
  width: 100%;
`;

export const HistoryTitle = styled.h1`
  max-width: 730px;
  margin: 0px auto;
  text-align: center;
  font-weight: 500;
  color: ${({ color }) => color || colors.light};
  font-weight: 300;
  font-size: 40px;
  font-weight: bold;
  margin: 50px auto 0px auto;
`;

export default {};
