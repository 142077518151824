import styled from 'styled-components';
import Section from 'components/Section';
import constants from 'utils/constants';

const { colors } = constants;

const BaseSectionFunnel = styled(Section)`
  background-color: ${colors.secondary};
  border-radius: 4px;
  padding: 8px 16px 16px;
  color: ${colors.light};

  .content {
    font-size: 14px;
    padding: 8px 0;
  }
`;

export default BaseSectionFunnel;
