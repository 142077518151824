import React from 'react';

export default {
  default() {
    return (
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      />
    );
  },
  // -----------
  data(options) {
    return (
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13 0H0V2.4375H13V0Z" fill="#FFF" {...options} />
        <path d="M13 4.875H0V8.125H13V4.875Z" fill="#FFF" {...options} />
        <path d="M13 10.5625H0V13H13V10.5625Z" fill="#FFF" {...options} />
      </svg>
    );
  },
  // -----------
  insight(options) {
    return (
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9484 8.76676L9.81724 7.91385L9.46069 7.7188C11.0585 6.19147 11.3146 3.64262 9.97929 1.80454C9.28246 0.845831 8.26149 0.224324 7.10767 0.0491112C5.9506 -0.126101 4.79677 0.171433 3.8601 0.878894C2.92019 1.58966 2.31093 2.63102 2.13915 3.80791C1.96738 4.98812 2.25907 6.16502 2.95267 7.12043C3.10824 7.33531 3.28321 7.53366 3.47119 7.71549L3.15681 7.88409L2.04839 8.76345C0.742239 10.0131 -0.00323055 11.7751 1.05246e-05 13.6V14H1.94466V13.5735C1.84418 12.3338 2.22665 11.1272 3.01747 10.1784C3.80829 9.22959 4.91345 8.65106 6.12886 8.54858C7.34426 8.4494 8.52726 8.83619 9.45745 9.64283C10.3876 10.4495 10.9549 11.5768 11.0554 12.8165C11.0748 13.0677 11.0748 13.3223 11.0554 13.6V14H13V13.6C13 11.7784 12.2545 10.0164 10.9484 8.76676ZM6.5146 7.28903L6.48216 6.95844C5.15656 6.95844 4.06756 5.85757 4.05135 4.50876C4.04487 3.84758 4.28792 3.21948 4.74167 2.74673C5.19542 2.27399 5.80151 2.00621 6.45296 1.9996C6.46269 1.9996 6.47244 1.9996 6.48216 1.9996C7.80776 1.9996 8.89676 3.09384 8.91297 4.44926C8.91945 5.11044 8.67641 5.73856 8.22266 6.21131C7.76891 6.68405 7.16282 6.95183 6.51136 6.95844L6.5146 7.28903Z"
          fill="#FFF"
          {...options}
        />
      </svg>
    );
  },
  // -----------
  activation(options) {
    return (
      <svg
        width="22"
        height="14"
        viewBox="0 0 22 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8257 0C14.4778 0 13.2328 0.433821 12.2158 1.17031L13.9653 2.91973C14.5141 2.60495 15.1477 2.42134 15.8257 2.42134C17.8959 2.42134 19.5787 4.10618 19.5787 6.17441C19.5787 8.24466 17.8939 9.9275 15.8257 9.9275C15.4382 9.9275 15.065 9.86897 14.7118 9.758L12.8736 11.5962C13.7514 12.0764 14.7562 12.3488 15.8257 12.3488C19.2317 12.3488 22 9.57841 22 6.17441C22 2.77041 19.2317 0 15.8257 0Z"
          fill="#FFF"
          {...options}
        />
        <path
          d="M8.96101 0.231934L7.13692 2.07014L10.4219 5.37527H0V7.96812H10.4219L7.13692 11.2753L8.96101 13.1114L15.3574 6.67271L8.96101 0.231934Z"
          fill="#FFF"
          {...options}
        />
      </svg>
    );
  },
  // -----------
  funnel(options) {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 14L9 14L9 12L5 12L5 14Z" fill="#FFF" {...options} />
        <path d="M2 8L12 8L12 6L2 6L2 8Z" fill="#FFF" {...options} />
        <path d="M0 2L14 2L14 0L0 -6.75251e-10L0 2Z" fill="#FFF" {...options} />
      </svg>
    );
  },
  trash(options) {
    return (
      <svg
        width="11"
        height="14"
        viewBox="0 0 11 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.785714 12.4444C0.785714 13.3 1.49286 14 2.35714 14H8.64286C9.50714 14 10.2143 13.3 10.2143 12.4444V3.11111H0.785714V12.4444ZM11 0.777778H8.25L7.46429 0H3.53571L2.75 0.777778H0V2.33333H11V0.777778Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  }
};
