import list from './list';

/**
 * Icon
 *
 */
function Icon({ icon, attrs }) {
  const Ico = list[icon](attrs);
  console.log(Ico, 'ico');
  return Ico || list.default;
}

export default Icon;
