import styled from 'styled-components';
// import constants from 'utils/constants';
//
// const { colors } = constants;

/**
 * @component
 *
 */
const BaseResultLayout = styled.div`
  display: grid;
  padding 9px 0;
  grid-template-columns: 1.5fr 2fr;
  background-color: #383b50;
  height: fit-content;
  border-bottom: 2px solid white;
`;

export const BaseResultColumn = styled.div`
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ align }) => align || 'center'};
  align-items: center;
`;

export default BaseResultLayout;
