import React from 'react';
import Grid from 'components/Grid';
import BaseDatePicker from 'components/DateRangePicker';
import constants from 'utils/constants';
import styled from 'styled-components';

const { colors } = constants;

const PeriodWrapper = props => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    item
    xs={5}
    {...props}
  />
);

export const DatePicker = props => (
  <BaseDatePicker
    bgColor={colors.primary}
    color={colors.light}
    borderColor={colors.funnel}
    {...props}
  />
);

export const Label = styled.span`
  margin-right: 8px;
`;

export default PeriodWrapper;
