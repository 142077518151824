import React from 'react';
import constants from 'utils/constants';

import Section from './index';

const { colors } = constants;

/**
 *
 *
 * SectionLanding
 * @param {Object} props
 *
 *
 *
 */
function SectionDashboard(props) {
  return (
    <Section
      {...props}
      width="100%"
      color={colors.light}
      justifyContent="center"
      alignItems="center"
      minHeight="auto"
      containerProps="lg"
    />
  );
}

export default SectionDashboard;
