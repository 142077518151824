import React from 'react';
// import PropTypes from 'prop-types';

import BaseOperatedCategory from './style';

/**
 * OperatedCategoryInsight
 * @component
 * @see OperatorContainer
 * @desc display a pannel containing operators by category
 *
 */
function OperatedCategoryInsight(props) {
  return <BaseOperatedCategory {...props} />;
}
OperatedCategoryInsight.efaultProps = {};
OperatedCategoryInsight.propTypes = {};

export default OperatedCategoryInsight;
