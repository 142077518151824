import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BaseVisibilities = styled.div`
  position: relative;
  width: 100%;
  .visible_0 {
    display: ${({ isSecond }) => (isSecond && 'none') || 'block'};
  }
  .visible_1 {
    display: ${({ isSecond }) => (isSecond && 'block') || 'none'};
  }
`;

/**
 * SaleVisibilities
 * @component
 *
 */
function SaleVisibilities({ children, isSecond }) {
  return (
    <BaseVisibilities isSecond={isSecond}>
      {React.Children.map(children, (child, i) => (
        <div className={`visible_${i}`}>{child}</div>
      ))}
    </BaseVisibilities>
  );
}
SaleVisibilities.defaultProps = {};
SaleVisibilities.propTypes = {
  children: PropTypes.node.isRequired,
  isSecond: PropTypes.bool.isRequired
};

export default SaleVisibilities;
