import React from 'react';
import constants from 'utils/constants';

import Home from 'components/Home';
import Title from 'components/Title';
import { Subtitle } from 'components/Title/style';
import { HrefLink } from 'components/Link/style';
import { IconLandingDetails } from 'components/Icon';
import Section, {
  SectionLanding,
  SectionTableWrapper,
  SectionTable
} from 'components/Section';

const { panels } = constants;

const { landingRoles } = constants;
/**
 * @component
 * @param {children} param0
 */
function HomeContainer() {
  return (
    <Home>
      <SectionLanding
        role={landingRoles.welcome}
        className="section-one"
        centerContent
      >
        <div>
          <img
            src="/assets/img/homepage_visual.jpg"
            className=" img-home"
            alt="graphique"
          />
        </div>
        <Title big padding="20px" width="50%">
          Bienvenue sur la Plateforme ERMES, tous les outils pour simplifier et
          optimiser tout votre marketing.
          <Subtitle>
            Nos algorithmes travaillent, préparent, opèrent, alertent.{`\n\n`}
            Vous analysez, imaginez et décidez.{`\n`}Vos connaissances et
            performances décollent.
          </Subtitle>
        </Title>
      </SectionLanding>
      <HrefLink className="arrow-home" href="#welcome-section">
        <img src="assets/img/icons/arrow-next-section-black.svg" alt="fleche" />
      </HrefLink>
      <SectionLanding
        role={landingRoles.description}
        bgColor="red"
        id="welcome-section"
      >
        <Title big>
          Bienvenue chez ERMES, la plateforme pensée et construite pour les
          stratèges et opérationnels des marques
        </Title>
        <Section centerContent>
          <div className="is-flex flex-direction-column flex-1">
            <p>
              Nous pensons que la data et l’intelligence artificielle doivent
              permettre aux marques une nouvelle façon de travailler, de penser
              et d’agir en intégrant l’ensemble des moyens de communication.
            </p>
            <p>
              C’est pour permettre aux marques de bénéficier pleinement des
              progrès de la data et de l’IA que nous avons construit ERMES sur
              quatre piliers :
            </p>
            <ul>
              <li>
                La simplicité et la clarté pragmatique au service de l’usage
                réel
              </li>
              <li>
                Une Data massive (40+ millions) d’identifiants consommateurs
                avec plus de 1000 points de données issus de comportements en
                ligne ou en magasin
              </li>
              <li>
                Une Intelligence Artificielle permettant très simplement
                d’agréger, comprendre, analyser, simuler et suggérer, vous
                permettant de passer plus de temps à imaginer et agir,
              </li>
              <li>
                Des connecteurs media permettant de déclencher et piloter vos
                campagnes en mode agile sur tous les leviers, digitaux ou non.
              </li>
            </ul>
          </div>
          <img
            className="is-flex flex-1"
            src="assets/img/chart-line.svg"
            alt="Graphique ligne"
          />
        </Section>
      </SectionLanding>
      <HrefLink className="arrow-home arrow-home--white" href="#tools">
        <img src="assets/img/icons/arrow-next-section-blue.svg" alt="fleche" />
      </HrefLink>
      <SectionLanding role={landingRoles.details} id="tools" fullWidth>
        <SectionTableWrapper>
          <SectionTable
            position="relative"
            role={panels.data}
            url="/features/data"
          >
            <div>
              <IconLandingDetails
                src="assets/img/icons/icon-data.svg"
                alt="Icone data"
              />
              <Title small fontSize="2rem" textAlign="center">
                Data
              </Title>
              <Title>
                Enrichissez vos données de DMP et CRM à partir de notre base de
                données
              </Title>
              <div>
                <p>
                  Un set-up simplifié pour accéder à des millions de données de
                  qualité et mises à jour continuellement
                </p>
              </div>
              <div>
                <p>
                  Elargissez vos moyens d’action en établissant une
                  correspondance entre vos différents identifiants digitaux
                  (Cookie, email haché ou ID Mobile).
                </p>
              </div>
              <div>
                <p>
                  Un flux automatisé et sécurisé pour transférer les données
                  simplement sur vos différents outils
                </p>
              </div>
            </div>
          </SectionTable>
          <SectionTable
            position="relative"
            role={panels.insight}
            url="/features/insight"
          >
            <div>
              <IconLandingDetails
                src="assets/img/icons/icon-insight.svg"
                alt="Icone insight"
              />
              <Title small fontSize="2rem" textAlign="center">
                Insight
              </Title>
              <Title>
                Définissez simplement vos cibles à partir de 1000 critères de
                segmentation ou à partir de vos données propres.
              </Title>
              <div>
                <p>
                  Une base de données qui analyse et catégorise en continue les
                  flux provenant de nos partenaires Data et Media
                </p>
              </div>
              <div>
                <p>
                  Plus de 1000 critères de segmentation pertinents vous
                  permettant de définir une cible précise.
                </p>
              </div>
              <div>
                <p>
                  Une activation immédiate des cibles définies et selon leurs
                  appétences médias.
                </p>
              </div>
            </div>
          </SectionTable>
          <SectionTable
            position="relative"
            role={panels.activation}
            url="/features/activation"
          >
            <div>
              <IconLandingDetails
                src="assets/img/icons/icon-activation.svg"
                alt="Icone activation"
              />
              <Title small fontSize="2rem" textAlign="center">
                Activation
              </Title>
              <Title>
                Une fois vos cibles définies, paramétrez ici vos campagnes sur
                tous les supports digitaux ou linéaires.
              </Title>
              <div>
                <p>
                  Des partenariats Médias vous permettant de multiplier les
                  points de contacts offline et online depuis une seule
                  plateforme
                </p>
              </div>
              <div>
                <p>
                  Une IA Media-planner qui vous accompagne et vous conseille
                  dans votre stratégie d’activation pour optimiser vos
                  performances
                </p>
              </div>
              <div>
                <p>
                  Un processus de configuration efficient depuis la définition
                  de votre cible jusqu’à l’upload de vos créations
                </p>
              </div>
            </div>
          </SectionTable>
          <SectionTable
            position="relative"
            role={panels.funnel}
            url="/features/funnel"
          >
            <div>
              <IconLandingDetails
                src="assets/img/icons/icon-funnel.svg"
                alt="Icone funnel"
              />
              <Title small fontSize="2rem" textAlign="center">
                Funnel
              </Title>
              <Title fontSize="1.1rem">
                Définissez vos tableaux de bord en mode funnel en partant de vos
                objectifs opérationnels.
              </Title>
              <div>
                <p>
                  Des connecteurs développés pour agréger vos données rapidement
                  et les présenter clairement
                </p>
              </div>
              <div>
                <p>
                  Un moteur d’IA qui détermine votre traffic naturel, qui
                  détecte les anomalies de campagne et qui prévoie vos
                  performances futures
                </p>
              </div>
              <div>
                <p>
                  Des suggestions d’optimisation et des moyens d’actions
                  immédiat avec des effets de levier important
                </p>
              </div>
            </div>
          </SectionTable>
        </SectionTableWrapper>
      </SectionLanding>
    </Home>
  );
}

export default HomeContainer;
