import React from 'react';
import PropTypes from 'prop-types';

import BudgetWrapper, { Input, Label } from './style';

/**
 * BudgetFunnel
 * @component
 *
 */
function BudgetFunnel(props) {
  const { setBudget, funnel } = props;

  return (
    <BudgetWrapper>
      <Label>
        <span className="span">BUDGET MÉDIA PRÉVU </span>
        <Input
          label=""
          placeholder="Mon budget"
          type="number"
          step="any"
          value={funnel.budget}
          onChange={({ target }) => setBudget(target.value)}
        />
      </Label>
    </BudgetWrapper>
  );
}

BudgetFunnel.propTypes = {
  funnel: PropTypes.objectOf(PropTypes.any).isRequired,
  setBudget: PropTypes.func.isRequired
};

export default BudgetFunnel;
