import React from 'react';
import BaseIcon from './style';

function Icon(props) {
  return <BaseIcon {...props} />;
}

export const IconLandingDetails = props => (
  <Icon height="32px" margin="0 auto" {...props} />
);
export default Icon;
