import React from 'react';
import PropTypes from 'prop-types';
import LayoutFunnel from 'Funnel/components/LayoutFunnel';
import ActionContainer from 'Funnel/containers/ActionContainer';
import ConnectorsContainer from 'Funnel/containers/ConnectorsContainer';
import { useFunnelGraph } from 'Funnel/contexts/FunnelGraphContext';
import { withRouter } from 'react-router-dom';
// import { BrowserRouter as Router } from 'react-router-dom';
// import NewConnector from 'Funnel/containers/NewConnector';
import TitleContainer from 'Funnel/containers/TitleContainer';
import IndicatorsContainer from 'Funnel/containers/IndicatorsContainer';
import InsightSummaryContainer from 'Funnel/containers/InsightSummaryContainer';
import OverviewContainer from 'Funnel/containers/OverviewContainer';
import PersistContainer from 'Funnel/containers/PersistContainer';
import OLDExample from 'Funnel/__sale__';
import SaleVisibilitites from 'Funnel/__sale__/SaleVisibilities';
import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { useUser } from 'contexts/UserContext';

/**
 * AppFunnel
 * @component
 * TODO ::Inverser les conditions isFinalstep pour la tab et le overview
 * @desc  step :: indique l etape de creation du funnel
 * Most of the Component below can be enabled only after set at
 * least one connector,
 * cad after step 4
 *
 */
function AppFunnelSale(props) {
  const { recoveredFunnel } = props;
  const { isFinalStep, setHasGetFunnel } = useFunnelStep();
  const { graphTab, setGraphTab, setSubTab, subGraphTab } = useFunnelGraph();
  const { isAuthenticated } = useUser();
  const { setFunnel, parseFunnel } = useFunnel();
  React.useEffect(() => {
    if (recoveredFunnel) {
      const newFunnel = parseFunnel(recoveredFunnel);
      setFunnel(newFunnel);
      setHasGetFunnel();
    }
  }, [isAuthenticated]);
  return (
    <LayoutFunnel.Wrapper>
      <IndicatorsContainer isRecoveryMode={!!recoveredFunnel} />
      <TitleContainer isRecoveryMode={!!recoveredFunnel} />
      <ConnectorsContainer />
      {/* <NewConnector /> */}
      <LayoutFunnel.Grid>
        <LayoutFunnel.Column verticalySpaced>
          <InsightSummaryContainer />
          <ActionContainer />
        </LayoutFunnel.Column>
        <LayoutFunnel.Column style={{ display: 'flex', width: '100%' }}>
          <SaleVisibilitites isSecond={isFinalStep}>
            <OverviewContainer />
            <OLDExample
              graphTab={graphTab}
              setGraphTab={setGraphTab}
              setSubTab={setSubTab}
              subGraphTab={subGraphTab}
            />
          </SaleVisibilitites>
        </LayoutFunnel.Column>
      </LayoutFunnel.Grid>
      <PersistContainer />
    </LayoutFunnel.Wrapper>
  );
}
AppFunnelSale.defaultProps = {
  recoveredFunnel: null
};
AppFunnelSale.propTypes = {
  recoveredFunnel: PropTypes.objectOf(PropTypes.any)
};

export default withRouter(AppFunnelSale);
