import React from 'react';
// import PropTypes from 'prop-types'

import BaseResultTitle from './style';

/**
 * ResultTitleInsight
 * @component
 *
 */
function ResultTitleInsight(props) {
  return <BaseResultTitle medium {...props} />;
}
ResultTitleInsight.defaultProps = {};
ResultTitleInsight.propTypes = {};

export default ResultTitleInsight;
