export default {
  OR: {
    NAME: 'Or',
    LABEL: 'OU'
  },
  AND: {
    NAME: 'And',
    LABEL: 'ET'
  },
  SIMPLE: {
    NAME: 'Simple',
    LABEL: ''
  },
  EXCLUDE: {
    NAME: 'Not',
    LABEL: ''
  }
};
