import { gql } from 'apollo-boost';

export default {
  LIST_FUNNEL: gql`
    query listFunnels(
      $userId: ID
      $saved: Boolean
      $limit: Int
      $nextToken: String
    ) {
      listFunnels(
        filter: { userId: { eq: $userId }, saved: { eq: $saved } }
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          name
          measure
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `,
  LIST_FUNNEL_ADD: gql`
    query listFunnels($nextToken: String, $limit: Int) {
      listFunnels(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          measure
          start
          end
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `,
  extractListFunnel({ data }) {
    const { listFunnels } = data;
    return (listFunnels && listFunnels.items) || [];
  },
  extractTokenFunnel({ data }) {
    const { listFunnels } = data;
    return (listFunnels && listFunnels.nextToken) || '';
  },
  DELETE_FUNNEL: gql`
    mutation deleteFunnel($id: ID!) {
      deleteFunnel(input: { id: $id }) {
        id
      }
    }
  `,

  CREATE_FUNNEL: gql`
    mutation createFunnel(
      $anom: AWSJSON
      $budget: Float
      $connected: AWSJSON
      $end: AWSDateTime
      $forecast: AWSJSON
      $measure: AWSJSON
      $name: String!
      $objectifed: AWSJSON
      $saved: Boolean
      $start: AWSDateTime
      $userId: ID!
    ) {
      createFunnel(
        input: {
          anom: $anom
          budget: $budget
          connected: $connected
          end: $end
          forecast: $forecast
          measure: $measure
          name: $name
          objectifed: $objectifed
          saved: $saved
          start: $start
          userId: $userId
        }
      ) {
        anom
        budget
        connected
        createdAt
        end
        forecast
        id
        measure
        name
        objectifed
        saved
        start
        updatedAt
        userId
      }
    }
  `,
  GET_FUNNEL: gql`
    query getFunnel($id: ID!) {
      getFunnel(id: $id) {
        anom
        budget
        connected
        createdAt
        end
        forecast
        id
        measure
        name
        objectifed
        saved
        start
        updatedAt
        userId
      }
    }
  `,
  UPDATE_FUNNEL: gql`
    mutation updateFunnel(
      $anom: AWSJSON
      $id: ID!
      $budget: Float
      $connected: AWSJSON
      $end: AWSDateTime
      $forecast: AWSJSON
      $measure: AWSJSON
      $name: String!
      $objectifed: AWSJSON
      $saved: Boolean
      $start: AWSDateTime
      $userId: ID!
    ) {
      updateFunnel(
        input: {
          anom: $anom
          budget: $budget
          connected: $connected
          end: $end
          forecast: $forecast
          id: $id
          measure: $measure
          name: $name
          objectifed: $objectifed
          saved: $saved
          start: $start
          userId: $userId
        }
      ) {
        anom
        budget
        connected
        createdAt
        end
        forecast
        id
        measure
        name
        objectifed
        saved
        start
        updatedAt
      }
    }
  `
};
