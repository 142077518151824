import styled from 'styled-components';
import Link from 'components/Link';
import constants from 'utils/constants';

const { colors } = constants;

const BaseLinkFunnel = styled(Link)`
  .text {
    color: ${colors.light};
  }
  margin: auto;
`;

export default BaseLinkFunnel;
