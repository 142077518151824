import React from 'react';
import PropTypes from 'prop-types';

import BaseResultLayout, { BaseResultColumn } from './style';

/**
 * ResultInsightLayout
 * @component
 * @see ResultContainer
 *
 */
function ResultInsightLayout(props) {
  const { children } = props;
  return <BaseResultLayout>{children}</BaseResultLayout>;
}
ResultInsightLayout.defaultProps = {};
ResultInsightLayout.propTypes = {
  children: PropTypes.node.isRequired
};

/**
 * ResultColumn
 * @component
 *
 */
export const ResultColumn = props => {
  const { design } = props;
  let align = '';
  if (design === 'results') align = 'space-around';
  if (design === 'actions') align = 'flex-end';
  return <BaseResultColumn {...props} align={align} />;
};
ResultColumn.propTypes = {
  design: PropTypes.oneOf(['results', 'actions']).isRequired
};

export default ResultInsightLayout;
