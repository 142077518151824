import React from 'react';
// import PropTypes from 'prop-types';

import BaseOperatedGroup from './style';

/**
 * OperatedGroupInsight
 * @component
 * @see OperatorContainer
 * @desc wrap criterias and operators (subCategories)
 */
function OperatedGroupInsight(props) {
  return <BaseOperatedGroup {...props} />;
}

export default OperatedGroupInsight;
