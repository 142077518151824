import React from 'react';
import PropTypes from 'prop-types';
import BaseResultSaving, { ModalInput, Title } from './style';

/**
 * ResultSavingInsight
 * @component
 *
 */

function ResultSavingInsight(props) {
  const [name, setName] = React.useState('');
  const { onNameChange } = props;
  const handleChange = ({ target }) => {
    setName(target.value);
    onNameChange(target.value);
  };
  return (
    <BaseResultSaving>
      <Title>Donner un nom à votre Cible</Title>
      <ModalInput
        id="outlined-bare"
        placeholder="Nom de la cible"
        margin="normal"
        variant="outlined"
        value={name}
        onChange={handleChange}
        /*
        inputProps={{
          classes: {
            focused: {},
            root: {
              '&$cssFocused $notchedOutline': {
                borderColor: 'white !important'
              }
            }
          }
        }}
        */
      />
    </BaseResultSaving>
  );
}
ResultSavingInsight.defaultProps = {};
ResultSavingInsight.propTypes = {
  onNameChange: PropTypes.func.isRequired
};

export default ResultSavingInsight;
