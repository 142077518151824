import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';

import { InMemoryCache } from 'apollo-boost';
import AWSAppSyncClient from 'aws-appsync';
import { AUTH_TYPE } from 'aws-appsync/lib/link/auth-link';
import Amplify, { Auth } from 'aws-amplify';
import { ApolloProvider } from 'react-apollo';
import awsConfig from 'utils/api/aws/aws-export';

import App from 'App';
import UserProvider from 'contexts/UserContext';

import 'index.css';

Amplify.configure(awsConfig);

/**
 * client AWSAppSyncClient
 * @WARNING there is another client in src/modules/Insight/
 */
const client = new AWSAppSyncClient(
  {
    url: awsConfig.graphqlEndpoint,
    region: awsConfig.region,
    auth: {
      // apiKey: awsConfig.apiKey,
      // Amazon Cognito Federated Identities using AWS Amplify
      // credentials: () => Auth.currentCredentials(),
      // Amazon Cognito user pools using AWS Amplify
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
      }
    },
    disableOffline: true
  },
  {
    cache: new InMemoryCache().restore({}),
    ssrMode: true
  }
);

ReactDOM.render(
  <ApolloProvider client={client}>
    <UserProvider>
      <App />
    </UserProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
