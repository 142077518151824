import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

/* The dropdown container */
const BaseDropdown = styled.div`
  float: left;
  background-color: transparent;
  position: relative;
  .dropbtn {
    cursor: pointer;
    font-size: 17px;
    border: 1px solid ${({ borderColor }) => borderColor || 'transparent'};
    outline: none;
    color: ${({ color }) => color || colors.dark};
    padding: 7px 0px 7px 20px;
    background-color: ${({ bgColor }) => bgColor || colors.light};
    font-family: inherit; /* Important for vertical align on mobile */
    border-radius: 4px;
    margin: 0; /* Important for vertical align on mobile phones */
    width: ${({ width }) => width || 'auto'};
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    ${({ maxWidth }) => `max-width: ${maxWidth}` || 'max-width: auto'};
    ${({ minWidth }) => `min-width: ${minWidth}` || 'min-width: auto'};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    float: none;
    color: ${colors.dark};
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropbtn {
    text-align: left;
    padding: 10px 40px 10px 10px;
  }

  /* Add a grey background color to dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #ddd;
  }

  /* Show the dropdown menu on hover */
  &:hover .dropdown-content {
    display: block;
  }
`;

export const ArrowDown = styled.img`
  right: 10px;
  top: 8px;
  position: absolute;
`;

export default BaseDropdown;
