import React from 'react';
import styled from 'styled-components';
// import Dropdown from 'react-dropdown';
import Dropdown from 'components/Dropdown';
import Grid from 'components/Grid';
import BaseLabel from 'components/Label';

import constants from 'utils/constants';

const { colors } = constants;

const PurposeWrapper = props => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    item
    xs={4}
    {...props}
  />
);

const StyledDropdown = styled(Dropdown)``;

export const DropdownPurpose = props => (
  <StyledDropdown
    color={colors.light}
    bgColor={colors.primary}
    borderColor={colors.funnel}
    width="300px"
    minWidth="300px"
    {...props}
  />
);

export const Label = styled(BaseLabel)``;

export default PurposeWrapper;
