import { DateRangePicker } from 'react-dates';
import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;
export const BaseDateWrapper = styled.div`
  .DateInput,
  .DateRangePickerInput {
    background-color: transparent;
    border: none;
  }
  .DateInput_input {
    font-size: 17px;
    background-color: ${({ bgColor }) => bgColor || colors.light};
    color: ${({ color }) => color || colors.dark};
    border: 1px solid ${({ borderColor }) => borderColor || colors.black};
    border-radius: 4px;
    padding: 7px 16px;
    cursor: pointer;
    line-height: 0;
  }

  .DateRangePickerInput_arrow_svg {
    fill: ${({ borderColor }) => borderColor || colors.black};
    margin: 0 10px;
  }
`;

const BaseDateRange = styled(DateRangePicker)``;

export default BaseDateRange;
