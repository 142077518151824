import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

const BaseLabel = styled.label`
  color: ${colors.light};
  display: ${({ display }) => display || 'flex'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  .span {
    margin-right: 8px;
  }
`;

export default BaseLabel;
