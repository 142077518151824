import React from 'react';
import SectionFunnel from 'Funnel/components/SectionFunnel';
import TitleFunnel from 'Funnel/components/TitleFunnel';
import ArrowListFunnel from 'Funnel/components/ArrowListFunnel';
import HiderFunnel from 'Funnel/components/HiderFunnel';
import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';
import { useFunnelGraph } from 'Funnel/contexts/FunnelGraphContext';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import moment from 'utils/moment';
import _ from 'lodash';

/**
 * @component
 *
 */
function InsightSummaryContainer() {
  const { setGraphTab, setSubTab } = useFunnelGraph();
  const { isFinalStep } = useFunnelStep();
  const { funnel } = useFunnel();

  const filter = data => {
    return moment(data.date).isBetween(
      moment(funnel.start)
        .startOf('day')
        .format(),
      moment(funnel.end)
        .startOf('day')
        .format(),
      null,
      '[]'
    );
  };

  function getBaseline() {
    const allkpi = funnel.measure.funnels.map(({ id }) => id);
    const id = allkpi[4];
    const newConnector = Object.values(funnel.connected).find(
      oneConnected => oneConnected.funnelId === id
    );
    if (!id || !newConnector || !funnel.connected) return 0;
    const hasData = newConnector && newConnector.data.length;
    const newConnectorData = hasData ? newConnector.data.filter(filter) : [];
    const totalB = hasData ? _.sumBy(newConnectorData, c => c.display) : 0;

    return totalB;
  }

  function getBudget() {
    const allkpi = funnel.measure.funnels.map(({ id }) => id);
    const id = allkpi[4];
    const newConnector = Object.values(funnel.connected).find(
      oneConnected => oneConnected.funnelId === id
    );
    if (!id || !newConnector || !funnel.connected) return 0;
    const hasData = newConnector && newConnector.data.length;
    const newConnectorData = hasData ? newConnector.data.filter(filter) : [];
    const totalB = hasData ? _.sumBy(newConnectorData, c => c.budget) : 0;

    let pourcentage = totalB - funnel.budget;
    let bool = true;
    if (pourcentage < 0) {
      bool = false;
      pourcentage *= -1;
    }
    pourcentage = (pourcentage * 100) / funnel.budget;
    return bool
      ? `${totalB
          .toLocaleString('en-gb', {
            minimumFractionDigits: 2
          })
          .replace(',', ' ')} € ( > ${pourcentage
          .toLocaleString('en-gb', {
            minimumFractionDigits: 2
          })
          .replace(',', ' ')}`
      : `${totalB
          .toLocaleString('en-gb', {
            minimumFractionDigits: 2
          })
          .replace(',', ' ')} € ( < ${pourcentage
          .toLocaleString('en-gb', {
            minimumFractionDigits: 2
          })
          .replace(',', ' ')}`;
  }

  function getAnomalie() {
    let anomData = 0;

    if (funnel.connected.length < 4) return anomData;
    funnel.connected[3].data.map(i => {
      const date = moment(i.date);
      if (
        date > moment(funnel.start).subtract(1, 'days') &&
        date < moment(funnel.end)
      ) {
        if (i.anom !== 0) {
          if (i.akpi1 === '1') anomData += 1;
          if (i.akpi2 === '1') anomData += 1;
          if (i.akpi3 === '1') anomData += 1;
          if (i.akpi4 === '1') anomData += 1;
        }
      }
      return i;
    });
    return anomData;
  }

  return (
    <HiderFunnel hide={!isFinalStep}>
      <SectionFunnel>
        <TitleFunnel>Insights du Funnel</TitleFunnel>
        <div className="content">
          <ArrowListFunnel
            onClick={() => {
              setGraphTab(2);
              setSubTab('tabDistribMedia');
            }}
          >
            Budget réel dépensé sur la période :
            <strong> {getBudget()}% au budget initial)</strong>
          </ArrowListFunnel>
          <ArrowListFunnel
            onClick={() => {
              setGraphTab(2);
              setSubTab('tabAnalyse');
            }}
          >
            Analyse du Funnel
          </ArrowListFunnel>
          <ArrowListFunnel
            onClick={() => {
              setGraphTab(2);
              setSubTab('tabBaseline');
            }}
            value={getBaseline()
              .toLocaleString('en-gb')
              .replace(',', ' ')}
          >
            Nombre d’inscriptions mensuelles hors campagne :
          </ArrowListFunnel>
          <ArrowListFunnel
            onClick={() => {
              setGraphTab(2);
              setSubTab('tabAnomalies');
            }}
            value={`${parseFloat(getAnomalie())} anomalie(s)`}
          >
            Nombre d’anomalies détéctées :
          </ArrowListFunnel>
          <ArrowListFunnel
            onClick={() => {
              setGraphTab(2);
              setSubTab('graphPrevision');
            }}
            value=""
          >
            Prévisions de vos performances futures
          </ArrowListFunnel>
        </div>
      </SectionFunnel>
    </HiderFunnel>
  );
}

export default InsightSummaryContainer;
