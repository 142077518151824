import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'utils/moment';
import {
  PDFDownloadLink,
  Page,
  Text,
  Font,
  View,
  Document,
  StyleSheet
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import CsvDownload from './CsvDownload';

// Create styles
Font.register({
  family: 'Roboto',
  src: 'https://fonts.googleapis.com/css?family=Roboto&display=swap'
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 24,
    textAlign: 'center'
  },
  flexProperty: {
    flexDirection: 'row'
  },
  box: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 15,
    marginBottom: 20,
    width: '22%',
    textAlign: 'center',
    fontSize: 14,
    backgroundColor: '#4E85EC',
    color: 'white',
    borderRadius: 16
  },
  text: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'justify',
    color: 'black'
  },
  table: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 8,
    borderTop: '1 solid black',
    borderLeft: '1 solid black',
    display: 'flex',
    flexDirection: 'column'
  },
  cell: {
    flex: 1,
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
    borderTop: 'none'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  }
});

export class Pdf extends Component {
  getValues = (kpi, time) => {
    const { connected } = this.props;
    let cibleValue = 0;
    connected.map(item => {
      item.data.map(i => {
        const date = moment(i.date);
        if (date.format('LL') === time.format('LL')) {
          if (item.funnelId === kpi) {
            cibleValue = i.graph;
          }
        }
        return i;
      });
      return item;
    });
    return cibleValue.toFixed(2);
  };

  getAnomData = (connected, allkpi, funnel) => {
    const anomData = [];
    connected[3].data.map(i => {
      const date = moment(i.date);
      if (date >= moment(funnel.start) && date <= moment(funnel.end)) {
        if (i.anom !== 0) {
          if (i.akpi1 === '1')
            anomData.push([
              date.format('DD/MM/YYYY'),
              date.format('DD/MM/YYYY'),
              allkpi[0],
              this.getValues(allkpi[0], date),
              parseFloat(i.tkpi1).toFixed(2),
              (parseFloat(i.ekpi1) * 100).toFixed(2)
            ]);
          if (i.akpi2 === '1')
            anomData.push([
              date.format('DD/MM/YYYY'),
              allkpi[1],
              this.getValues(allkpi[1], date),
              parseFloat(i.tkpi2).toFixed(2),
              (parseFloat(i.ekpi2) * 100).toFixed(2)
            ]);
          if (i.akpi3 === '1')
            anomData.push([
              date.format('DD/MM/YYYY'),
              allkpi[2],
              this.getValues(allkpi[2], date),
              parseFloat(i.tkpi3).toFixed(2),
              (parseFloat(i.ekpi3) * 100).toFixed(2)
            ]);
          if (i.akpi4 === '1')
            anomData.push([
              date.format('DD/MM/YYYY'),
              allkpi[3],
              this.getValues(allkpi[3], date),
              parseFloat(i.tkpi4).toFixed(2),
              (parseFloat(i.ekpi4) * 100).toFixed(2)
            ]);
        }
      }
      return i;
    });
    return anomData;
  };

  // Create Document Component
  render() {
    const {
      funnel,
      connected,
      id,
      filter,
      name,
      objectifed,
      array,
      budget,
      pourcentageBudget
    } = this.props;
    const synthese = CsvDownload.getsynthese(connected, id, filter);
    const analyse = CsvDownload.getanalyse(
      connected,
      id,
      filter,
      funnel,
      name,
      objectifed,
      array
    );
    const baseline = CsvDownload.getbaseline(connected, id, filter);
    const anomaly = this.getAnomData(connected, id, funnel);
    const dateStart = moment(funnel.start);
    const dateEnd = moment(funnel.end);
    return (
      <Document>
        <Page size="A4" style={styles.body}>
          <View style={styles.title}>
            <Text
              style={styles.title}
              render={() =>
                `Projet: ${funnel.name}.\n${funnel.measure.name}.\n\n`
              }
            />
          </View>
          <View style={styles.text}>
            <View style={styles.flexProperty}>
              <Text> Analyses des performances du </Text>
              <Text style={{ color: '#3E6DE7' }}>
                {dateStart.format('DD/MM/YYYY')}
              </Text>
              <Text> au </Text>
              <Text style={{ color: '#3E6DE7' }}>
                {dateEnd.format('DD/MM/YYYY')}
              </Text>
            </View>
            <View style={styles.flexProperty}>
              <Text>Pour un budget de </Text>
              <Text style={{ color: '#3E6DE7' }}>{`${funnel.budget}€ :`}</Text>
            </View>
            <View style={styles.flexProperty}>
              <Text>Le budget réel dépensé pour cette période est de </Text>
              <Text style={{ color: '#3E6DE7' }}>{`${budget.toFixed(
                2
              )}€ \n`}</Text>
              <Text> soit </Text>
            </View>
            <View style={styles.flexProperty}>
              <Text style={{ color: '#3E6DE7' }}>{`${pourcentageBudget.toFixed(
                2
              )}% `}</Text>
              <Text> inferieur à votre dubget initialement prévu.</Text>
            </View>
          </View>
          <View style={styles.subtitle}>
            <Text render={() => `Synthese\n\n`} />
          </View>
          <View style={styles.flexProperty}>
            <Text
              style={styles.box}
              render={() =>
                `${(synthese[3][1] / 1000).toFixed(0)}\n${
                  funnel.measure.funnels[0].name
                }`
              }
            />
            <Text
              style={styles.box}
              render={() =>
                `${(synthese[4][1] / 1000).toFixed(0)}\n${
                  funnel.measure.funnels[1].name
                }`
              }
            />
            <Text
              style={styles.box}
              render={() =>
                `${(synthese[5][1] / 1000).toFixed(0)}\n${
                  funnel.measure.funnels[2].name
                }`
              }
            />
            <Text
              style={styles.box}
              render={() =>
                `${(synthese[6][1] / 1000).toFixed(0)}\n${
                  funnel.measure.funnels[3].name
                }`
              }
            />
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={styles.cell} />
              <View style={styles.cell}>
                <Text>Réel</Text>
              </View>
              <View style={styles.cell}>
                <Text>Top</Text>
              </View>
              <View style={styles.cell}>
                <Text>Flop</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text style={styles.cell}>CPM</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[3][2]}€`} />
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[3][3]}€`} />
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[3][4]}€`} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text style={styles.cell}>CPV</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[4][2]}€`} />
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[4][3]}€`} />
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[4][4]}€`} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text style={styles.cell}>CPD</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[5][2]}€`} />
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[5][3]}€`} />
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[5][4]}€`} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text style={styles.cell}>CPI</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[6][2]}€`} />
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[6][3]}€`} />
              </View>
              <View style={styles.cell}>
                <Text style={styles.cell} render={() => `${synthese[6][4]}€`} />
              </View>
            </View>
          </View>

          <View style={styles.subtitle}>
            <Text render={() => `Analyses\n`} />
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text>KPI</Text>
              </View>
              <View style={styles.cell}>
                <Text>Objectif</Text>
              </View>
              <View style={styles.cell}>
                <Text>Réel</Text>
              </View>
              <View style={styles.cell}>
                <Text>Nouvel Objectif</Text>
              </View>
              <View style={styles.cell}>
                <Text>Impact CPI</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text render={() => `${analyse[3][0]}`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[3][1]}€`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[3][2]}€`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[3][3]}€`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `-${analyse[3][4]}%`} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text render={() => `${analyse[4][0]}`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[4][1]}%`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[4][2]}%`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[4][3]}%`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `-${analyse[4][4]}%`} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text render={() => `${analyse[5][0]}`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[5][1]}%`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[5][2]}%`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[5][3]}%`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `-${analyse[5][4]}%`} />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text render={() => `${analyse[6][0]}`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[6][1]}%`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[6][2]}%`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `${analyse[6][3]}%`} />
              </View>
              <View style={styles.cell}>
                <Text render={() => `-${analyse[6][4]}%`} />
              </View>
            </View>
          </View>
          <View style={styles.text}>
            <View>
              <Text>
                La Baseline correspond au traffic que vous auriez eu sans aucune
                campagne média. La Baseline est de :
              </Text>
              <Text style={{ color: '#3E6DE7' }}>{`${baseline[3][1]}`}</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.row}>
                <View style={styles.cell}>
                  <Text render={() => `CPI hors baseline\n\n`} />
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.cell}>
                  <Text>Réel</Text>
                </View>
                <View style={styles.cell}>
                  <Text>Top</Text>
                </View>
                <View style={styles.cell}>
                  <Text>Flop</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.cell}>
                  <Text render={() => `${baseline[3][2]}€`} />
                </View>
                <View style={styles.cell}>
                  <Text render={() => `${baseline[3][3]}€`} />
                </View>
                <View style={styles.cell}>
                  <Text render={() => `${baseline[3][4]}€`} />
                </View>
              </View>
            </View>
          </View>
          <Page size="A4" style={styles.body}>
            <Text render={() => `Anomalies\n\n`} />
            <Text>
              {' '}
              Date &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp; KPI
              &nbsp;&nbsp;&nbsp;| Valeur KPI | Moyenne/5j | Ecart
            </Text>
            <Text
              render={() =>
                anomaly.map(value => {
                  let res = '';
                  for (let i = 0; i !== 6; i += 1) {
                    if (res !== `${value[i]}      ` && value[i] !== undefined)
                      res += `${value[i]}      `;
                  }
                  return `\n${res}\n`;
                })
              }
            />
          </Page>
        </Page>
      </Document>
    );
  }
}

export const createAndDownloadPDF = (pdfContent, filename, divId, callback) => {
  setTimeout(() => {
    const link = (
      <div id={divId}>
        <PDFDownloadLink document={pdfContent} fileName={filename} />
      </div>
    );
    const elem = document.createElement('div');
    document.getElementById('root').appendChild(elem);
    ReactDOM.render(link, elem);
    setTimeout(() => {
      document.getElementById(divId).children[0].click();
      elem.remove();
      callback();
    }, 1);
  }, 1);
};

Pdf.propTypes = {
  funnel: PropTypes.object.isRequired,
  id: PropTypes.array.isRequired,
  connected: PropTypes.array.isRequired,
  filter: PropTypes.func.isRequired,
  name: PropTypes.array.isRequired,
  objectifed: PropTypes.array.isRequired,
  array: PropTypes.array.isRequired,
  budget: PropTypes.number.isRequired,
  pourcentageBudget: PropTypes.number.isRequired
};
