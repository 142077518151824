import React from 'react';
import PropTypes from 'prop-types';
import constants from 'utils/constants';

import Section from './index';

const { colors, landingRoles } = constants;
const { details, welcome, description } = landingRoles;
const getBGColor = role => {
  if (role === welcome) return 'black';
  if (role === description) return colors.funnel;
  if (role === details) return colors.primary;
  return 'transparent';
};

/**
 *
 *
 * SectionLanding
 * @param {Object} props
 *
 *
 *
 */
function SectionLanding(props) {
  const { role, fullWidth } = props;
  const bgColor = getBGColor(role);
  return (
    <Section
      {...props}
      type="landing"
      bgColor={bgColor}
      color={colors.light}
      justifyContent="center"
      padding={fullWidth ? '0' : '0 24px'}
      alignItems="center"
      minHeight={fullWidth ? '' : 'calc(100vh - 30px)'}
      containerProps={{ maxWidth: fullWidth ? false : 'lg' }}
    />
  );
}

SectionLanding.defaultProps = {
  role: '',
  fullWidth: false
};

SectionLanding.propTypes = {
  role: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default SectionLanding;
