import styled from 'styled-components';
// import constants from 'utils/constants';
//
// const { colors } = constants;

/**
 * @component
 * @see OperatorsInsight
 * @see CategoriesInsight/styles.js,
 * @desc heigth is adapted with CategoriesInsight 's height
 * because grid-template-rows is set at 1fr
 *
 */
const BaseOperators = styled.div`
  display: grid;
  min-width: 220px;
  grid-template-rows: repeat(
    ${({ length }) => `${length},${Math.round(100 / length)}%`}
  );
`;

export default BaseOperators;
