import React from 'react';
import styled from 'styled-components';
import Grid from 'components/Grid';

import constants from 'utils/constants';

const { colors } = constants;
const Wrapper = styled.div`
  background-color: ${colors.secondary};
  border-radius: 4px;
  padding: 8px 16px 16px;
`;

const IndicatorsWrapper = props => (
  <Wrapper>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className="navbar funnel"
      {...props}
    />
  </Wrapper>
);

export default IndicatorsWrapper;
