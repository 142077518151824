import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AuthenticatedRoute from 'Policy';

import Layout from 'components/Layout';
import DashboardContainer from 'containers/DashboardContainer';
import FeatureDetailsContainer from 'containers/FeatureDetailsContainer';
import FooterContainer from 'containers/FooterContainer';
import HeaderContainer from 'containers/HeaderContainer';
import HistoryContainer from 'containers/HistoryContainer';
import HomeContainer from 'containers/HomeContainer';
import LoginContainer from 'containers/LoginContainer';

import FunnelModule from 'modules/Funnel';
import InsightModule from 'modules/Insight';

import constants from 'utils/constants';

const { routes } = constants;

/**
 * App
 * @desc App Entry
 */
function App() {
  return (
    <Layout>
      <Router>
        <HeaderContainer>
          <LoginContainer />
        </HeaderContainer>
        <Route exact path={routes.DEFAULT} component={HomeContainer} />
        {/* Policy manage url access (it can auto redirect) */}
        <Route
          path={`${routes.FEATURE}/:feature`}
          component={FeatureDetailsContainer}
        />
        <AuthenticatedRoute
          path={routes.DASHBOARD}
          component={DashboardContainer}
        />
        <AuthenticatedRoute
          exact
          path={routes.FUNNEL}
          component={FunnelModule}
        />

        <AuthenticatedRoute
          exact
          path={routes.INSIGHT}
          component={InsightModule}
        />
        <AuthenticatedRoute
          path={`${routes.FUNNEL}/:id`}
          component={FunnelModule}
        />
        <AuthenticatedRoute
          path={`${routes.HISTORY}/:panel`}
          component={HistoryContainer}
        />
        <FooterContainer />
      </Router>
    </Layout>
  );
}

export default App;
