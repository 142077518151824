import React from 'react';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { useFunnelGraph } from 'Funnel/contexts/FunnelGraphContext';
import Chart from 'Funnel/__old__/components/Charts';
import moment from 'utils/moment';
import BaseOld from './style';
// import PropTypes from 'prop-types'

/**
 * @component
 *
 */
function GraphContainer() {
  const [selectedGraph, setSelectedGraph] = React.useState('graphKpi');
  const { allkpi, allname } = useFunnelGraph();
  const { funnel: funnelRef } = useFunnel();
  const funnel = {
    ...funnelRef,
    end: moment(funnelRef.end).format('DD-MM-YYYY'),
    start: moment(funnelRef.start).format('DD-MM-YYYY')
  };
  const { connected } = funnel;
  console.log('funnel in Graph', funnel);
  return (
    <BaseOld>
      {connected.length >= 1 ? (
        <div className="graph-section">
          {connected.length >= 4 ? (
            <div className="filter-headers" style={{ color: 'white' }}>
              <div
                className="btn"
                onClick={() => setSelectedGraph('graphKpi')}
                onKeyPress={() => setSelectedGraph('graphKpi')}
                tabIndex="0"
                role="button"
              >
                {' '}
                KPIS
              </div>
              <div
                className="btn"
                onClick={() => setSelectedGraph('graphVolume')}
                onKeyPress={() => setSelectedGraph('graphVolume')}
                tabIndex="-1"
                role="button"
              >
                {' '}
                Volumes
              </div>
              <div
                className="btn"
                onClick={() => setSelectedGraph('graphTconversion')}
                onKeyPress={() => setSelectedGraph('graphTconversion')}
                tabIndex="-2"
                role="button"
              >
                {' '}
                Taux de conversion
              </div>
            </div>
          ) : null}
          <div />
        </div>
      ) : null}

      {selectedGraph === 'graphKpi' && connected.length >= 1 ? (
        <div className="graph_container">
          <Chart
            connected={connected.filter(c => !c.funnelId.includes('HB'))}
            funnel={funnel}
            graphId={1}
            allkpi={allkpi}
            allname={allname}
          />
        </div>
      ) : null}
      {selectedGraph === 'graphVolume' && connected.length >= 4 ? (
        <div className="graph_container">
          <Chart
            connected={connected.filter(c => !c.funnelId.includes('HB'))}
            funnel={funnel}
            graphId={2}
            allkpi={allkpi}
            allname={allname}
          />
        </div>
      ) : null}

      {selectedGraph === 'graphTconversion' && connected.length >= 4 ? (
        <div className="graph_container">
          <Chart
            connected={connected.filter(c => !c.funnelId.includes('HB'))}
            funnel={funnel}
            graphId={3}
            allkpi={allkpi}
            allname={allname}
          />
        </div>
      ) : null}
    </BaseOld>
  );
}

GraphContainer.defaultProps = {};

GraphContainer.propTypes = {};

export default GraphContainer;
