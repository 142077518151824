import React from 'react';
import PropTypes from 'prop-types';
import BaseDescription, { Title } from './style';

/**
 * ResultSavingInsight
 * @component
 *
 */

function ResultSavingInsight(props) {
  const { title, description } = props;
  return (
    <BaseDescription>
      <Title>{title} :</Title>
      <div className="description">{description}</div>
    </BaseDescription>
  );
}
ResultSavingInsight.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default ResultSavingInsight;
