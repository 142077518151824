import React from 'react';
import BaseTitleFunnel from './style';

/**
 * @component
 *
 */
function TitleFunnel(props) {
  return <BaseTitleFunnel {...props} />;
}

/**
 * @component
 *
 */
export const BigTitleFunnel = props => (
  <BaseTitleFunnel big lightColor {...props} />
);

export default TitleFunnel;
