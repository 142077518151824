import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import BaseSection, { BaseSectionContainer } from './style';

function Section(props) {
  const { children, containerProps, boxProps, centerContent } = props;
  let boxStyle = { ...boxProps };
  if (centerContent)
    boxStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      xs: '32',
      lg: '12'
    };
  return (
    <BaseSection {...props}>
      <BaseSectionContainer {...containerProps}>
        <Box className="box" {...boxStyle}>
          {children}
        </Box>
      </BaseSectionContainer>
    </BaseSection>
  );
}
Section.defaultProps = {
  children: undefined,
  containerProps: {},
  boxProps: {},
  centerContent: false
};
Section.propTypes = {
  children: PropTypes.node,
  containerProps: PropTypes.objectOf(PropTypes.any),
  boxProps: PropTypes.objectOf(PropTypes.any),
  centerContent: PropTypes.bool
};

export { default as SectionLanding } from './SectionLanding';
export { default as SectionDashboard } from './SectionDashboard';
export { default as SectionTable, SectionTableWrapper } from './SectionTable';
export default Section;
