import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

const BaseOld = styled.div`
  .title_blue_line {
    margin-top: 0;
  }
  .table {
    background-color: ${colors.primary};
    color: ${colors.light};
    width: 100%;
    border: 1px solid ${colors.light};
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  tbody {
    background-color: ${colors.secondary};
  }
  td {
    border: 2px solid ${colors.primary};
    padding: 4px;
    margin: 4px;
    border-radius: 4px;
  }
  .btn_plug {
    background-color: transparent;
    margin: 4px;
    border-color: transparent;
  }
  .modal-content {
    width: 565px;
  }
  .btn-funnel-save {
    color: ${colors.light};
    background-color: ${colors.primary};
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    padding: 16px 24px;
    margin: 0 8px;
    border: 1px solid ${colors.light};
    text-decoration: none;
  }
  input[type='text'] {
    background-color: ${colors.primary};
    color: ${colors.light};
    padding: 8px;
  }
  .DateInput input[type='text'] {
    cursor: pointer;
  }
  .input-row {
    border-color: ${colors.funnel};
    margin: 0 4px 0 4px;
    width: 100px;
  }
  .action_td_container a {
    color: ${colors.light};
  }
  .kpi_title {
    font-weight: 600;
  }
  .buying_num_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .download_link {
    display: grid;
    grid-template-columns: 210px 100px 100px;
    margin-left: 15%;
    img {
      height: 60px;
    }
  }
  .filter-headers {
    grid-template-columns: 190px 190px 190px;
    margin: auto;
    grid-column-gap: 4px;
    display: inline-flex;
    .btn {
      width: 190px;
      margin: 5px;
      display: flex;
      justify-content: center;
      border: 1px solid ${colors.light};
      height: 30px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
    }
    .btn:hover {
      border-color: ${colors.funnel};
      color: ${colors.funnel};
    }
  }
  .filter-headers.bis {
    grid-template-columns: 170px 170px 170px 170px;
  }
  .graph_container {
  }
  .kpi_table_description,
  .pb-data {
    margin-bottom: 16px;
  }
`;

export default BaseOld;
