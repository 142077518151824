import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'utils/moment';
import _ from 'lodash';
import constants from 'utils/constants';
import GraphContainer from 'Funnel/containers/GraphContainer';
import InsightDetailsContainer from 'Funnel/containers/InsightDetailsContainer';
import SynthesisContainer from 'Funnel/containers/SynthesisContainer';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
// import FunnelRow from './components/FunnelRow';
// import ObjectifRow from './components/ObjectifRow';
import Chart from './components/Charts';
// import DatePicker from './components/DatePicker';
import BaselineRow from './components/BaselineRow';
import { FunnelContext, withFunnel } from './components/FunnelProvider';
import ForecastChart from './components/ForecastChart';
import CsvDownload from './components/CsvDownload';
import AnomalyRow from './components/AnomalyRow';
import { Pdf, createAndDownloadPDF } from './components/PdfDownload';

const { colors } = constants;

const FakeResultContainer = styled.div`
  width: 100%;
  .red_funnel_result {
    height: 500px;
    overflow-y: auto;
  }
`;

const FakeTab = styled.div`
  background-color: ${({ bgColor }) => bgColor || colors.light};
  height: auto;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 6px solid #eeeeee;
  a {
    position: relative;
    bottom: -6px;
    color: #666666;
    display: inline-block;
    height: 56px;
    padding: 16px 0;
    text-align: center;
    text-decoration: none;
    width: 170px;
  }
  .active {
    position: relative;
    border-bottom: 6px solid #009fe3;
    color: black;
    bottom: -6px;
  }
`;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      // saved: false,
      selectedGraph: 'graphKpi',
      array: [],
      savet3: 0,
      worstlevierCPI: '',
      allkpi: [],
      allname: [],
      loadingPDF: false
    };
    this.reportRef = React.createRef();
    this.filter = this.filter.bind(this);
    this.saveDataAnalyse = this.saveDataAnalyse.bind(this);
    this.newt3 = this.newt3.bind(this);
  }

  delFunnel = async event => {
    event.preventDefault();
    // this.setState({
    //   saved: false
    // });
    await this.context.delete(true);
    this.reportRef.current.scrollIntoView();
  };

  saveFunnel = async event => {
    event.preventDefault();
    // this.setState({
    //   saved: true
    // });
    await this.context.save(true);
    this.reportRef.current.scrollIntoView();
  };

  downloadCsv = () => {
    CsvDownload.dl(
      this.context.connected,
      this.state.allkpi,
      this.filter,
      this.context.funnel,
      this.state.allname,
      this.context.objectifed,
      this.state.array
    );
  };

  buildPDF = (budget, pourcentageBudget) => {
    if (!this.state.loadingPDF) {
      this.setState({ loadingPDF: true }, () => {
        createAndDownloadPDF(
          <Pdf
            funnel={this.context.funnel}
            id={this.state.allkpi}
            connected={this.context.connected}
            filter={this.filter}
            name={this.state.allname}
            objectifed={this.context.objectifed}
            array={this.state.array}
            budget={budget}
            pourcentageBudget={pourcentageBudget}
          />,
          `someName.pdf`,
          'pdf-creator-link',
          () => this.setState({ loadingPDF: false })
        );
      });
    }
  };

  newt3 = (item, name) => {
    this.setState({ savet3: item });
    this.setState({ worstlevierCPI: name });
  };

  updateTab = (e, tab) => {
    const { setGraphTab, setSubTab } = this.props;
    e.preventDefault();
    setSubTab('default');
    setGraphTab(tab);
  };

  filter(data) {
    return moment(data.date).isBetween(
      moment(this.context.funnel.start).startOf('day'),
      moment(this.context.funnel.end).startOf('day'),
      null,
      '[]'
    );
  }

  saveDataAnalyse(item) {
    this.state.array.push(item);
  }

  checkBaseline(id, connected, filter) {
    const connector = _.find(connected, c => c.funnelId === id);
    const hasData = connector && connector.data.length;
    const connectorData = hasData ? _.filter(connector.data, filter) : [];
    const totalB = hasData ? _.filter(connectorData, c => c.display !== 0) : [];
    if (totalB.length === 0) {
      return (
        <td>
          {' '}
          {
            "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
          }
        </td>
      );
    }

    return (
      <div className="pb-data">
        <div className="kpi_table_description align_center">
          La Baseline correspond au traffic que vous auriez eu sans aucune
          campagne média. Sur la période analysée, votre Baseline est de :
        </div>
        <div className="table_blue_container">
          {!this.state.allkpi[4] ? (
            <td> {'missing data'}</td>
          ) : (
            <BaselineRow
              id={this.state.allkpi[4]}
              connected={connected}
              filter={this.filter}
              kpi={this.state.allkpi[3]}
            />
          )}
        </div>
      </div>
    );
  }

  checkAnomalie(connected, allkpi, funnel) {
    let anomData = 0;
    connected[3].data.map(i => {
      const date = moment(i.date);
      if (date >= moment(funnel.start) && date <= moment(funnel.end)) {
        if (i.anom !== 0) {
          if (i.akpi1 === '1') anomData += 1;
          if (i.akpi2 === '1') anomData += 1;
          if (i.akpi3 === '1') anomData += 1;
          if (i.akpi4 === '1') anomData += 1;
        }
      }
      return i;
    });
    if (anomData === 0)
      return (
        <p>
          {' '}
          {
            "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
          }
        </p>
      );
    return (
      <div className="table_blue_container">
        <table className="table table_blue">
          <thead>
            <tr>
              <th className="uppercase">Date</th>
              <th>KPI</th>
              <th>Valeur KPI</th>
              <th> Moyenne 5 jours précedents</th>
              <th> Ecart KPI/5jours précedents</th>
              <th className="uppercase">Piste explication anomalies</th>
            </tr>
          </thead>
          <AnomalyRow
            connected={connected}
            allkpi={allkpi}
            funnel={funnel}
            setAnom={this.context.setAnom}
          />
        </table>
      </div>
    );
  }

  checkPrevision(connected, forecast, funnel) {
    const totalA = forecast[0] ? _.filter(forecast[0], c => c.value !== 0) : [];
    const totalB = forecast[1] ? _.filter(forecast[1], c => c.value !== 0) : [];
    if (totalA.length === 0 || totalB.length === 0) {
      return (
        <td>
          {' '}
          {
            <p>
              {' '}
              {
                "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
              }
            </p>
          }
        </td>
      );
    }
    return (
      <div className="graph_container">
        {!this.state.allkpi[3] || !this.state.allkpi[4] ? (
          <td> {'missing data'}</td>
        ) : (
          <ForecastChart
            funnel={funnel}
            forecast={forecast}
            kpi={this.state.allkpi[3]}
            kpiForecast={this.state.allkpi[4]}
            connected={connected}
          />
        )}
      </div>
    );
  }

  render() {
    const {
      // funnel,
      // connectors,
      connected,
      objectifed,
      forecast
      // setPeriod,
      // setObjectif,
      // setConnector,
      // setFetched,
      // setForecast,
      // setAnom
    } = this.context;
    const funnel = this.props.newFunnel;

    funnel.measure.funnels.map(item => {
      if (this.state.allkpi.length > 4) {
        this.state.allkpi = [];
        this.state.allname = [];
      }
      this.state.allkpi.push(item.id);
      this.state.allname.push(item.name);
      return 0;
    });

    const targetFunnel = _.find(funnel.measure.funnels, f => f.target);
    const connector = _.find(connected, c => c.funnelId === targetFunnel.id);
    const objectif = _.find(objectifed, c => c.funnelId === targetFunnel.id);
    let comp = 0;
    let budget = 0;
    let connectorData;
    let pourcentageBudget;
    let bool = true;
    let analysebool = true;

    if (connector !== undefined) {
      connectorData = _.filter(connector.data, this.filter);
      budget = _.sumBy(connectorData, c => c.budget);
      pourcentageBudget = budget - funnel.budget;
      if (pourcentageBudget < 0) {
        bool = false;
        pourcentageBudget *= -1;
      }
      pourcentageBudget = (pourcentageBudget * 100) / funnel.budget;
    }
    if (connector && objectif) {
      const total = _.sumBy(connectorData, c => c.value);
      const reel = parseFloat(budget / total);
      const value = parseFloat(objectif.value);
      comp = ((value - reel) / value) * 100;
      if (comp < 0) {
        analysebool = false;
        comp *= -1;
      }
      if (comp < 1) {
        comp = 0;
      }
    }
    const { graphTab } = this.props;
    console.log('funnel in Home', funnel);
    // console.log('funnel in Home', this.props.newFunnel);

    return (
      <FakeResultContainer>
        <FakeTab>
          <a
            href="#"
            className={graphTab === 0 && 'active'}
            onClick={e => this.updateTab(e, 0)}
          >
            Synthèse Funnel
          </a>
          <a
            href="#"
            className={graphTab === 1 && 'active'}
            onClick={e => this.updateTab(e, 1)}
          >
            Graphiques Funnel
          </a>
          <a
            href="#"
            className={graphTab === 2 && 'active'}
            onClick={e => this.updateTab(e, 2)}
          >
            Insights
          </a>
        </FakeTab>
        <section ref={this.reportRef} className="container red_funnel_result">
          {/* <h1 className="title_blue_line flex_container"> */}
          {/*   <span>{funnel.name}</span> */}
          {/*   <span className="title_date"> */}
          {/*     du {moment(funnel.start).format('L')} au{' '} */}
          {/*     {moment(funnel.end).format('L')} */}
          {/*   </span> */}
          {/* </h1> */}
          {/* <p */}
          {/*   className={`align_center saved_text ${ */}
          {/*     this.state.saved ? '' : 'hidden' */}
          {/*   }`} */}
          {/* /> */}

          <div style={{ display: graphTab !== 0 && 'none' }}>
            <h2 className="title_dropdown_calendar align_center">
              {/* <div> */}
              {/*   Analyse des performances du{' '} */}
              {/*   <div style={{ display: 'inline-block' }}> */}
              {/*     <DatePicker */}
              {/*       onDatesChange={setPeriod} */}
              {/*       startDate={moment(funnel.start)} */}
              {/*       endDate={moment(funnel.end)} */}
              {/*     /> */}
              {/*   </div> */}
              {/* </div> */}
              {/* pour un budget de{' '} */}
              {/* <span className="text_blue"> */}
              {/*   {funnel.budget */}
              {/*     .toLocaleString('en-gb', { */}
              {/*       minimumFractionDigits: 2 */}
              {/*     }) */}
              {/*     .replace(',', ' ')}{' '} */}
              {/*   € */}
              {/* </span> */}
              {/* <br /> */}
              {/* <br /> */}
              {connected.length >= 4 ? (
                <div>
                  Le budget réel dépensé pour cette période est de{' '}
                  <span className="text_blue">
                    {budget
                      .toLocaleString('en-gb', {
                        minimumFractionDigits: 2
                      })
                      .replace(',', ' ')}{' '}
                    €
                  </span>
                  {funnel.budget !== '' ? (
                    <span>
                      {' '}
                      soit{' '}
                      <span className="text_blue">
                        {pourcentageBudget.toFixed()}%
                      </span>{' '}
                      {bool === false ? (
                        <span>inferieur</span>
                      ) : (
                        <span>superieur</span>
                      )}{' '}
                      à votre budget initialement prévu{' '}
                    </span>
                  ) : null}
                </div>
              ) : null}
            </h2>

            <h3 className="subtitle_blue_line logo">
              <div>
                {connected.length >= 4 ? (
                  <div className="download_link" href="" title="">
                    <div className="download-text">
                      Télécharger au
                      <br /> format .csv ou .pdf
                    </div>
                    <div
                      className="picto-container"
                      onClick={() => this.downloadCsv()}
                      onKeyUp={() => this.downloadCsv()}
                      tabIndex="0"
                      role="button"
                    >
                      <img
                        className="picto_download"
                        src="/assets/img/connector/CSV_Icon.png"
                        alt="icone csv"
                      />
                    </div>
                    <div
                      id="root"
                      className="picto-container"
                      onClick={() => this.buildPDF(budget, pourcentageBudget)}
                      onKeyUp={() => this.buildPDF(budget, pourcentageBudget)}
                      tabIndex="0"
                      role="button"
                    >
                      <img
                        className="picto_download"
                        src="/assets/img/logopdf.png"
                        alt="icone pdf"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </h3>

            <SynthesisContainer />
            {/* <table className="table funnel_synthese_table">
              <thead>
                <tr>
                  <th />
                  <th className="number_col">Réel</th>
                  <th className="number_col">Top</th>
                  <th className="number_col">Flop</th>
                  <th className="number_col">Objectif</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {funnel.measure
                  ? funnel.measure.funnels.map(f => {
                      const objectifFunnel = _.find(
                        objectifed,
                        c => c.funnelId === f.id
                      );
                      return (
                        <FunnelRow
                          key={f.id}
                          {...f}
                          sources={connectors}
                          funnel={funnel}
                          objectif={
                            objectifFunnel ? objectifFunnel.value : null
                          }
                          connected={connected}
                          setObjectif={setObjectif}
                          setConnector={setConnector}
                          setFetched={setFetched}
                          setForecast={setForecast}
                          setAnom={setAnom}
                          saveDataAnalyse={this.saveDataAnalyse}
                          filter={this.filter}
                        />
                      );
                    })
                  : null}
              </tbody>
            </table> */}
          </div>
          {/* <div style={{ display: tab !== 1 && 'none' }}> */}
          {graphTab === 1 && (
            <React.Fragment>
              {connected.length >= 1 ? (
                <div className="graph-section">
                  <h3 className="subtitle_blue_line">
                    {connected.length >= 4 ? (
                      <div className="filter-headers">
                        <div
                          className="btn"
                          onClick={() =>
                            this.setState({ selectedGraph: 'graphKpi' })
                          }
                          onKeyPress={() =>
                            this.setState({ selectedGraph: 'graphKpi' })
                          }
                          tabIndex="0"
                          role="button"
                        >
                          {' '}
                          KPIS
                        </div>
                        <div
                          className="btn"
                          onClick={() =>
                            this.setState({ selectedGraph: 'graphVolume' })
                          }
                          onKeyPress={() =>
                            this.setState({ selectedGraph: 'graphVolume' })
                          }
                          tabIndex="-1"
                          role="button"
                        >
                          {' '}
                          Volumes
                        </div>
                        <div
                          className="btn"
                          onClick={() =>
                            this.setState({ selectedGraph: 'graphTconversion' })
                          }
                          onKeyPress={() =>
                            this.setState({ selectedGraph: 'graphTconversion' })
                          }
                          tabIndex="-2"
                          role="button"
                        >
                          {' '}
                          Taux de conversion
                        </div>
                      </div>
                    ) : null}
                  </h3>
                  <div />
                </div>
              ) : null}

              {this.state.selectedGraph === 'graphKpi' &&
              connected.length >= 1 ? (
                <div className="graph_container">
                  <Chart
                    connected={connected.filter(
                      c => !c.funnelId.includes('HB')
                    )}
                    funnel={funnel}
                    redraw={graphTab !== 1}
                    graphId={1}
                    allkpi={this.state.allkpi}
                    allname={this.state.allname}
                  />
                </div>
              ) : null}
              {this.state.selectedGraph === 'graphVolume' &&
              connected.length >= 4 ? (
                <div className="graph_container">
                  <Chart
                    connected={connected.filter(
                      c => !c.funnelId.includes('HB')
                    )}
                    funnel={funnel}
                    graphId={2}
                    redraw={graphTab !== 1}
                    allkpi={this.state.allkpi}
                    allname={this.state.allname}
                  />
                </div>
              ) : null}

              {this.state.selectedGraph === 'graphTconversion' &&
              connected.length >= 4 ? (
                <div className="graph_container">
                  <Chart
                    connected={connected.filter(
                      c => !c.funnelId.includes('HB')
                    )}
                    funnel={funnel}
                    graphId={3}
                    allkpi={this.state.allkpi}
                    allname={this.state.allname}
                  />
                </div>
              ) : null}
              <GraphContainer />
            </React.Fragment>
          )}
          {graphTab === 2 && (
            <React.Fragment>
              <InsightDetailsContainer
                selectedTab={this.props.subGraphTab}
                savet3={this.state.savet3}
                worstlevierCPI={this.state.worstlevierCPI}
                analysebool={analysebool}
                forecast={forecast}
              />
            </React.Fragment>
          )}
        </section>
      </FakeResultContainer>
    );
  }
}

Report.contextType = FunnelContext;
Report.propTypes = {
  newFunnel: PropTypes.object,
  setGraphTab: PropTypes.func.isRequired,
  graphTab: PropTypes.number.isRequired,
  setSubTab: PropTypes.func.isRequired,
  subGraphTab: PropTypes.number.isRequired
};
Report.defaultProps = {
  newFunnel: {}
};

const ReportOld = withFunnel(Report);
export default props => {
  const { funnel } = useFunnel();
  return <ReportOld newFunnel={funnel} {...props} />;
};

// export default withFunnel(Report);
