import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'utils/moment';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';
import constants from 'utils/constants';

const { colors } = constants;

const StyledDropdown = styled(Dropdown)``;

const StyleSelect = props => (
  <StyledDropdown
    color={colors.light}
    bgColor={colors.primary}
    borderColor={colors.funnel}
    width="250px"
    {...props}
  />
);

const AnomalyTableStyle = {
  case: {
    borderBottom: '1px solid #fff',
    margin: '0px auto',
    textAlign: 'left',
    padding: '15px',
    fontWeight: 'bold',
    color: '#fff',
    maxWidth: '300px'
  },
  kpi: {
    color: '#fff',
    borderRadius: '7px',
    height: '24px',
    display: 'flex',
    width: '60px',
    justifyContent: 'center'
  }
};

const motif = [
  'Meteo',
  'Problème technique',
  'Defaut de paiement',
  'Changement de publicité',
  'Changement de cible',
  'Autre'
];

export default class AnomalyRow extends Component {
  getValues = (kpi, time) => {
    const { connected } = this.props;
    let cibleValue = 0;
    connected.map(item => {
      item.data.map(i => {
        const date = moment(i.date);
        if (date.format('LL') === time.format('LL')) {
          if (item.funnelId === kpi) {
            cibleValue = i.graph;
          }
        }
        return i;
      });
      return item;
    });
    return cibleValue.toFixed(2);
  };

  getMotif = (kpi, time) => {
    const { funnel } = this.props;
    if (funnel.anom) {
      const kpis = funnel.anom.filter(e => e.funnelId === kpi);
      if (kpis.length > 0) {
        const f = kpis.filter(i => i.date === time.format('DD-MM-YYYY'));
        if (f.length > 0) {
          return f[f.length - 1].motifanom;
        }
      }
    }
    return 'Sélectioner une option';
  };

  handleChange = (e, funnelId, date) => {
    if (typeof e === 'string') {
      this.props.setAnom({ funnelId, date, motifanom: e });
    } else this.props.setAnom({ funnelId, date, motifanom: e.target.value });
  };

  render() {
    const { connected, allkpi, funnel } = this.props;
    const anomData = [];
    connected[3].data.map(i => {
      const date = moment(i.date);
      if (
        moment(date).isBetween(
          moment(funnel.start).subtract(1, 'days'),
          moment(funnel.end),
          null,
          []
        )
      ) {
        const color = i.ekpi1 < 0 ? 'anom_success' : 'anom_alert';
        const color1 = i.ekpi2 < 0 ? 'anom_success' : 'anom_alert';
        const color2 = i.ekpi3 < 0 ? 'anom_success' : 'anom_alert';
        const color3 = i.ekpi4 < 0 ? 'anom_success' : 'anom_alert';
        if (i.anom !== 0) {
          if (i.akpi1 === '1') {
            anomData.push([
              <tr>
                {' '}
                <td style={AnomalyTableStyle.case}>
                  {date.format('DD-MM-YYYY')}
                </td>
                <td style={AnomalyTableStyle.case}>{allkpi[0]}</td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {' '}
                  <span style={AnomalyTableStyle.kpi} className={color}>
                    {this.getValues(allkpi[0], date)}€
                  </span>
                </td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {parseFloat(i.tkpi1).toFixed(2)}€
                </td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {(parseFloat(i.ekpi1) * 100).toFixed(2)}%
                </td>
                <td style={AnomalyTableStyle.case}>
                  {
                    <React.Fragment>
                      <StyleSelect
                        defaultValue={this.getMotif(allkpi[0], date)}
                        value={this.getMotif(allkpi[0], date)}
                        options={motif}
                        onInput={this.getMotif(allkpi[0], date)}
                        onChange={e =>
                          this.handleChange(
                            e,
                            allkpi[0],
                            date.format('DD-MM-YYYY')
                          )
                        }
                      />
                      <input
                        type="text"
                        style={{
                          display:
                            this.getMotif(allkpi[0], date) === 'Autre'
                              ? 'block'
                              : 'none',
                          width: '250px',
                          cursor: 'pointer',
                          fontSize: '17px',
                          border: '1px solid #009fe3',
                          outline: 'none',
                          color: '#fff',
                          padding: '7px 0px 7px 20px',
                          backgroundColor: '#1e1e2f',
                          fontFamily: 'inherit',
                          borderRadius: '4px',
                          margin: '0'
                        }}
                        onBlur={e =>
                          this.handleChange(
                            e,
                            allkpi[0],
                            date.format('DD-MM-YYYY')
                          )
                        }
                      />
                    </React.Fragment>
                  }
                </td>
              </tr>
            ]);
          }
          if (i.akpi2 === '1')
            anomData.push([
              <tr>
                {' '}
                <td style={AnomalyTableStyle.case}>
                  {date.format('DD-MM-YYYY')}
                </td>
                <td style={AnomalyTableStyle.case}>{allkpi[1]}</td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {' '}
                  <span style={AnomalyTableStyle.kpi} className={color1}>
                    {this.getValues(allkpi[1], date)}€
                  </span>
                </td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {parseFloat(i.tkpi2).toFixed(2)}€
                </td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {(parseFloat(i.ekpi2) * 100).toFixed(2)}%
                </td>
                <td style={AnomalyTableStyle.case}>
                  {
                    <React.Fragment>
                      <StyleSelect
                        defaultValue={this.getMotif(allkpi[1], date)}
                        value={this.getMotif(allkpi[1], date)}
                        options={motif}
                        onChange={e =>
                          this.handleChange(
                            e,
                            allkpi[1],
                            date.format('DD-MM-YYYY')
                          )
                        }
                      />
                      <input
                        type="text"
                        style={{
                          display:
                            this.getMotif(allkpi[1], date) === 'Autre'
                              ? 'block'
                              : 'none',
                          width: '250px',
                          cursor: 'pointer',
                          fontSize: '17px',
                          border: '1px solid #009fe3',
                          outline: 'none',
                          color: '#fff',
                          padding: '7px 0px 7px 20px',
                          backgroundColor: '#1e1e2f',
                          fontFamily: 'inherit',
                          borderRadius: '4px',
                          margin: '0'
                        }}
                        onBlur={e =>
                          this.handleChange(
                            e,
                            allkpi[1],
                            date.format('DD-MM-YYYY')
                          )
                        }
                      />
                    </React.Fragment>
                  }
                </td>
              </tr>
            ]);
          if (i.akpi3 === '1')
            anomData.push([
              <tr>
                {' '}
                <td style={AnomalyTableStyle.case}>
                  {date.format('DD-MM-YYYY')}
                </td>
                <td style={AnomalyTableStyle.case}>{allkpi[2]}</td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {' '}
                  <span style={AnomalyTableStyle.kpi} className={color2}>
                    {this.getValues(allkpi[2], date)}€
                  </span>
                </td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {parseFloat(i.tkpi3).toFixed(2)}€
                </td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {(parseFloat(i.ekpi3) * 100).toFixed(2)}%
                </td>
                <td style={AnomalyTableStyle.case}>
                  {
                    <React.Fragment>
                      <StyleSelect
                        defaultValue={this.getMotif(allkpi[2], date)}
                        value={this.getMotif(allkpi[2], date)}
                        options={motif}
                        onChange={e =>
                          this.handleChange(
                            e,
                            allkpi[2],
                            date.format('DD-MM-YYYY')
                          )
                        }
                      />
                      <input
                        type="text"
                        style={{
                          display:
                            this.getMotif(allkpi[2], date) === 'Autre'
                              ? 'block'
                              : 'none',
                          width: '250px',
                          cursor: 'pointer',
                          fontSize: '17px',
                          border: '1px solid #009fe3',
                          outline: 'none',
                          color: '#fff',
                          padding: '7px 0px 7px 20px',
                          backgroundColor: '#1e1e2f',
                          fontFamily: 'inherit',
                          borderRadius: '4px',
                          margin: '0'
                        }}
                        onBlur={e =>
                          this.handleChange(
                            e,
                            allkpi[2],
                            date.format('DD-MM-YYYY')
                          )
                        }
                      />
                    </React.Fragment>
                  }
                </td>
              </tr>
            ]);
          if (i.akpi4 === '1')
            anomData.push([
              <tr>
                {' '}
                <td style={AnomalyTableStyle.case}>
                  {date.format('DD-MM-YYYY')}
                </td>{' '}
                <td style={AnomalyTableStyle.case}>{allkpi[3]}</td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {' '}
                  <span style={AnomalyTableStyle.kpi} className={color3}>
                    {this.getValues(allkpi[3], date)}€
                  </span>
                </td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {parseFloat(i.tkpi4).toFixed(2)}€
                </td>{' '}
                <td style={AnomalyTableStyle.case}>
                  {(parseFloat(i.ekpi4) * 100).toFixed(2)}%
                </td>
                <td style={AnomalyTableStyle.case}>
                  {
                    <React.Fragment>
                      <StyleSelect
                        defaultValue={this.getMotif(allkpi[3], date)}
                        value={this.getMotif(allkpi[3], date)}
                        options={motif}
                        onChange={e =>
                          this.handleChange(
                            e,
                            allkpi[3],
                            date.format('DD-MM-YYYY')
                          )
                        }
                      />
                      <input
                        type="text"
                        style={{
                          display:
                            this.getMotif(allkpi[3], date) === 'Autre'
                              ? 'block'
                              : 'none',
                          width: '250px',
                          cursor: 'pointer',
                          fontSize: '17px',
                          border: '1px solid #009fe3',
                          outline: 'none',
                          color: '#fff',
                          padding: '7px 0px 7px 20px',
                          backgroundColor: '#1e1e2f',
                          fontFamily: 'inherit',
                          borderRadius: '4px',
                          margin: '0'
                        }}
                        onBlur={e =>
                          this.handleChange(
                            e,
                            allkpi[3],
                            date.format('DD-MM-YYYY')
                          )
                        }
                      />
                    </React.Fragment>
                  }
                </td>
              </tr>
            ]);
        }
      }
      return i;
    });
    if (anomData.length === 0)
      return <tbody>pas d&apos;anomalie détectée</tbody>;
    return <tbody>{anomData}</tbody>;
  }
}

AnomalyRow.propTypes = {
  connected: PropTypes.array.isRequired,
  funnel: PropTypes.object.isRequired,
  setAnom: PropTypes.func.isRequired,
  allkpi: PropTypes.array.isRequired
};
