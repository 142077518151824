import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import constants from 'utils/constants';

const { colors } = constants;

export const TableDistrib = styled(Table)`
  && {
    width: 100%;
    color: white;
    p,
    div,
    span,
    th,
    td {
      color: white;
    }
  }
`;

export const TableBodyDistrib = styled(TableBody)``;

export const TableHeadDistrib = styled(TableHead)`
  text-align: center;
  background: #7e7ead;

  th {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

export const TableCellDistrib = styled(TableCell)`
  .label {
    text-align: right;
    padding-bottom: 27px;
  }
  img {
    width: 20px;
    padding-left: 5px;
  }
  font-weight: ${({ fontWeight }) =>
    fontWeight ? 'bold' : 'normal'} !important;
  && {
    height: 50px;
    border: none;
  }
  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &&.action_td {
    border: none;
  }
  &&.b-all {
    border: 1px solid #7e7ead;
  }
  &&.b-left {
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid #7e7ead;
  }
  &&.b-right {
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-right: 1px solid #7e7ead;
  }
  &&.b-top {
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid #7e7ead;
  }
  &&.b-bottom {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #7e7ead;
  }
`;

export const TableRowDistrib = styled(TableRow)``;

export const InputDistrib = styled.input`
   {
    width: 3.6rem;
    border: 1px solid ${colors.funnel};
    border-radius: 0.5rem;
    height: 1.8rem;
    text-align: center;
    overflow: hidden;
    margin: 0.4rem;
    background-color: ${colors.primary};
    color: white;
  }
`;

export const Image = styled.div``;

export const Container = styled.div`
  background-color: ${colors.light};
  border-radius: 5px;
  margin: auto 10px;
  padding: 5px 20px;
  text-align: center;
  display: flex;
  .question_mark {
    height: 50px;
    width: 40px;
    margin-right: 10px;
  }
  .align_center {
    color: ${colors.dark};
  }
`;

export const ErrorMessage = styled.p`
  color: ${colors.light};
  font-size: 14px;
  padding: 10px 40px;
`;

export const LabelStyle = {
  fontSize: '14px',
  fill: colors.light
};
