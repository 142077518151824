import React from 'react';
import { Query } from 'react-apollo';

import LoadingInsight from 'Insight/components/LoadingInsight';
import CriteriasFormInsight, {
  InsightTitle,
  InsightDesc,
  InsightLegend
} from 'Insight/components/CriteriasFormInsight';
import CriteriasGroupInsight from 'Insight/components/CriteriasGroupInsight';
import CriteriaInsight from 'Insight/components/CriteriaInsight';
import { useCategory } from 'Insight/contexts/CategoryContext';
// import { useSegment } from 'Insight/contexts/SegmentContext';
import { useOperator } from 'Insight/contexts/OperatorContext';
// import { useInsight } from 'Insight/contexts/InsightContext';

import { INSIGHT } from 'utils/graphQL';

import {
  extractResponseData,
  sortCriteria,
  getSegmentProps
} from './functions';

const criteriaDesc =
  'Sélectionner vos critères de ciblage, ajuster vos résultats avec les connecteurs ET/OU, et activer votre audience cible.';

/**
 * CriteriaContainer
 * @component
 * @desc :: manage criterias logic (right board on /insight)
 *
 */
function CriteriaContainer() {
  const [hasInsee, setHasInsee] = React.useState(false);
  const [subCategories, setSubCategories] = React.useState([]);
  const [orphansCriterias, setOrphans] = React.useState([]);
  const { currentCategory } = useCategory();
  const {
    isValueSelected,
    addValue,
    removeValue,
    formatedFilter
  } = useOperator();
  const onLoadingSuccess = req => {
    const extracted = extractResponseData(req);
    setHasInsee(extracted.hasInseeData);
    setSubCategories(extracted.nextSubCategories);
    setOrphans(extracted.segments);
  };
  const handleCheckboxClick = params => {
    if (isValueSelected(params.criteria.id)) {
      removeValue(params);
    } else {
      addValue(params);
    }
  };
  const queryOptions = {
    variables: { categoryId: currentCategory, filters: formatedFilter },
    fetchPolicy: 'network-only'
  };
  React.useEffect(() => {}, [currentCategory]);
  if (!currentCategory) return <LoadingInsight loading />;
  return (
    <CriteriasFormInsight hasInsee={hasInsee}>
      <InsightTitle>Comprendre sa cible</InsightTitle>
      <InsightDesc>{criteriaDesc}</InsightDesc>
      <InsightLegend hasInsee={hasInsee} hasData={!!subCategories.length} />
      {/* display child criterias */}
      {subCategories.map((subCategory, ind) => (
        <CriteriasGroupInsight key={`sub-${ind}`} title={subCategory.name}>
          {[...subCategory.segments.edges]
            .sort(sortCriteria)
            .map(({ node: criteria }, ind2) => (
              <CriteriaInsight
                key={`crit-${ind2}`}
                {...getSegmentProps(criteria)}
                checked={isValueSelected(criteria.id)}
                description={criteria.description}
                hasInsee={hasInsee}
                toggleCheck={() =>
                  handleCheckboxClick({
                    criteria,
                    subCategoryID: subCategory.id,
                    categoryID: currentCategory
                  })
                }
              />
            ))}
        </CriteriasGroupInsight>
      ))}
      {/* display oprhans criterias */}
      <CriteriasGroupInsight title="Autre" hideTitle={!subCategories.length}>
        {[...orphansCriterias]
          .sort(sortCriteria)
          .map(({ node: criteria }, ind3) => (
            <CriteriaInsight
              key={`crit-${ind3}`}
              {...getSegmentProps(criteria)}
              checked={isValueSelected(criteria.id)}
              description={criteria.description}
              hasInsee={hasInsee}
              toggleCheck={() =>
                handleCheckboxClick({
                  criteria,
                  subCategoryID: 'other',
                  categoryID: criteria.category.id
                })
              }
            />
          ))}
      </CriteriasGroupInsight>
      <Query query={INSIGHT.GET_CATEGORY_DETAILS} {...queryOptions}>
        {response => (
          <LoadingInsight {...response} onLoadingSuccess={onLoadingSuccess} />
        )}
      </Query>
    </CriteriasFormInsight>
  );
}

export default CriteriaContainer;
