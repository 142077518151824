import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  TableCellSynthesis,
  TableRowSynthesis,
  InputSynthesis,
  ConversionRateSpan,
  Reel
} from 'Funnel/components/FunnelRow/style';
import LinearProgressSynthesis from 'Funnel/components/FunnelRow/LinearProgressSynthesis';
import Connector from './Connector';

export default class FunnelRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      active: false
    };
    this.onClose = this.onClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const condition1 = nextProps.objectif !== this.props.objectif;
    const condition2 = nextProps.conversionRate === this.props.conversionRate;
    return condition1 || condition2;
  }

  onClose() {
    this.props.setFetched();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.objectif !== state.value) {
      return {
        value: props.objectif || '',
        active: props.objectif !== ''
      };
    }
    return null;
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
      active: event.target.value !== ''
    });
    this.props.setObjectif(this.props.id, event.target.value);
  }

  handleBlur(event) {
    const temp = parseFloat(event.target.value.replace(',', '.')).toFixed(2);
    this.props.setObjectif(this.props.id, temp);
  }

  render() {
    const {
      funnel,
      per,
      connected,
      id,
      filter,
      name,
      sources,
      connectors,
      addConversionRate
    } = this.props;
    const show =
      _.findIndex(
        connected,
        c => (c.childId === id || c.funnelId === id) && c.fetched
      ) >= 0;
    const connector = _.find(connected, c => c.funnelId === id);
    const hasData = connector && connector.data.length;
    const connectorData = hasData ? _.filter(connector.data, filter) : [];

    /* const reeltotal = [];
    connectorData.map(i => {
      reeltotal.push(i.cost);
      return i.cost;
    }); 

    let avg = reeltotal.reduce((p, c, l, a) => p + c / a.length, 0); */
    const totalDisplay = hasData ? _.sumBy(connectorData, c => c.display) : 0;
    const total = hasData ? _.sumBy(connectorData, c => c.value) : 0;
    const totalB = hasData ? _.sumBy(connectorData, c => c.budget) : 0;
    let avg = totalB / total;

    let top = 0;
    let flop = 0;
    if (hasData) {
      const min = _.minBy(connectorData, c => c.cost);
      const max = _.maxBy(connectorData, c => c.cost);
      top = min ? min.cost : 0;
      flop = max ? max.cost : 0;
    }
    if (per !== 0) {
      avg *= per;
      top *= per;
      flop *= per;
    }
    let className = avg.toFixed(2) <= this.state.value ? 'success' : 'alert';
    if (!this.state.value) className = '';
    let cr = null;
    const funnelIndex = _.findIndex(funnel.measure.funnels, f => f.id === id);

    if (funnelIndex > 0) {
      const parentFunnel = funnel.measure.funnels[funnelIndex - 1];
      const parent = _.find(connected, c => c.funnelId === parentFunnel.id);
      if (parent !== undefined) {
        const parentTotal = hasData
          ? _.sumBy(_.filter(parent.data, filter), c => c.value)
          : 0;

        cr = ((total / parentTotal) * 100).toFixed(1);
        if (cr != null && cr > 0 && cr !== undefined) {
          addConversionRate(cr);
          this.props.saveDataAnalyse(total / parentTotal);
        }
      }
    }
    if (name !== 'Baseline') {
      const nf = totalDisplay.toLocaleString().replace(/,/g, ' ');
      console.log(nf);
      return (
        <TableRowSynthesis
          className={show ? 'connected_row' : ''}
          conversionRate={cr}
        >
          <span>
            <ConversionRateSpan display={this.props.cr}>
              {cr}%
            </ConversionRateSpan>
          </span>
          <TableCellSynthesis className="b-top b-right b-bottom connector_td">
            <p className="kpi_title">{name}</p>
            <LinearProgressSynthesis
              variant="determinate"
              value={100 - funnelIndex * 25}
              textValue={nf}
            />
            {connectors.length ? (
              <Connector
                funnelId={id}
                childId={this.props.childId}
                forecast={this.props.forecast}
                funnel={funnel}
                target={this.props.target}
                sources={sources}
                connectors={connectors}
                setConnector={this.props.setConnector}
                onClose={this.onClose}
                setForecast={this.props.setForecast}
                setAnom={this.props.setAnom}
              />
            ) : (
              ''
            )}
          </TableCellSynthesis>
          <TableCellSynthesis
            className={`b-all ${this.state.active ? className : ''}`}
          >
            <div className="vertical">
              <span>{id}</span>
              <Reel objectif={this.state.value} real={avg.toFixed(2)}>
                {avg.toFixed(2)}€
              </Reel>
            </div>
          </TableCellSynthesis>
          <TableCellSynthesis className="b-all">
            <div className="vertical">
              <span>{id}</span>
              <strong>{top.toFixed(2)}€</strong>
            </div>
          </TableCellSynthesis>
          <TableCellSynthesis className="b-all">
            <div className="vertical">
              <span>{id}</span>
              <strong>{flop.toFixed(2)}€</strong>
            </div>
          </TableCellSynthesis>
          <TableCellSynthesis
            className={`b-top b-left b-bottom ${
              this.state.active ? 'active' : ''
            }`}
          >
            <p style={{ display: this.props.objectifable ? 'block' : 'none' }}>
              <div className="b-all vertical">
                <span className="buying_type">{id}</span>
                <span>
                  <InputSynthesis
                    type="text"
                    className="input-row"
                    value={this.state.value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                  €
                </span>
              </div>
            </p>
          </TableCellSynthesis>
          <TableCellSynthesis className="action_td">
            <div
              className="action_td_container"
              style={{ display: show ? 'block' : 'none' }}
            />
          </TableCellSynthesis>
        </TableRowSynthesis>
      );
    }
    return null;
  }
}

FunnelRow.propTypes = {
  addConversionRate: PropTypes.func.isRequired,
  baseline: PropTypes.string,
  childId: PropTypes.string,
  connected: PropTypes.array.isRequired,
  connectors: PropTypes.array.isRequired,
  conversionRate: PropTypes.number.isRequired,
  cr: PropTypes.bool,
  filter: PropTypes.func.isRequired,
  forecast: PropTypes.array,
  funnel: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  objectif: PropTypes.string,
  objectifable: PropTypes.bool,
  per: PropTypes.number,
  saveDataAnalyse: PropTypes.func,
  setAnom: PropTypes.func.isRequired,
  setConnector: PropTypes.func.isRequired,
  setFetched: PropTypes.func.isRequired,
  setForecast: PropTypes.func.isRequired,
  setObjectif: PropTypes.func.isRequired,
  sources: PropTypes.array.isRequired,
  target: PropTypes.bool
};

FunnelRow.defaultProps = {
  childId: null,
  cr: true,
  objectifable: true,
  per: 0,
  baseline: null,
  target: false,
  forecast: [],
  saveDataAnalyse: null,
  objectif: ''
};
