import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Modal from '@material-ui/core/Modal';
import { ButtonConnect } from 'components/Button';
import Link from 'components/Link';

import { useUser } from 'contexts/UserContext';

import styled from 'styled-components';
import constants from 'utils/constants';

const { colors, routes } = constants;

/**
 * Login
 * @component
 */
function LoginContainer({ history }) {
  const redirectToDashboard = () => history.push(routes.DASHBOARD);
  const { signIn, isAuthenticated } = useUser();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const connectUser = () => {
    if (isAuthenticated) {
      return redirectToDashboard();
    }
    setModalIsOpen(true);
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const hasAccount = await signIn({ email, password });
    if (hasAccount) redirectToDashboard();
  };
  return (
    <p>
      <Link href="mailto:contact@ermes.ai" className="test-link">
        Testez ERMES gratuitement pendant 2 mois
      </Link>
      <ButtonConnect onClick={connectUser} className="btn" type="button">
        {isAuthenticated ? 'DASHBOARD' : 'SE CONNECTER'}
      </ButtonConnect>

      <Modal
        aria-labelledby="Login Modal"
        aria-describedby="simple-modal-description"
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <Background>
          <Content>
            <div className="modal-content modal-login">
              <Title>Connectez vous à votre compte Ermes</Title>
              <form onSubmit={handleSubmit}>
                <InputContainer className="input-container">
                  <Label htmlFor="email">
                    Email
                    <input
                      type="text"
                      className="input"
                      name="uname"
                      id="email"
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                  </Label>
                </InputContainer>

                <InputContainer className="input-container">
                  <Label htmlFor="psw">
                    Mot de passe
                    <input
                      type="password"
                      className="input"
                      name="psw"
                      id="psw"
                      onChange={e => setPassword(e.target.value)}
                      required
                    />
                  </Label>
                </InputContainer>
                <button type="submit" className="btn">
                  Se connecter
                </button>
              </form>
            </div>
          </Content>
        </Background>
      </Modal>
    </p>
  );
}

const Background = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);

  .modal-content {
    border-radius: 14px;
    border: 1px solid #4e85ec;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-login {
    background-color: ${colors.light};
    padding: 35px 20px 20px 20px;
    color: ${colors.black};
  }
`;

const Content = styled.div`
  pointer-events: initial;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: none;
  background: none;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  .btn {
    display: block;
    margin: 20px auto;
    width: 200px;
    text-align: center;
    color: #3e6de7;
    height: 44px;
    line-height: 40px;
    border: 2px solid #4e85ec;
    border-radius: 22px;
    font-size: 16px;
    text-transform: uppercase;
    background-color: #ddd;

    &:hover {
      background-color: #4e85ec;
      color: ${colors.light};
      font-weight: bold;
    }
  }
`;

const Title = styled.h2`
  font-weight: 500;
  color: ${colors.black};
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const InputContainer = styled.div`
  max-width: 250px;
  margin: 0 auto;
`;

const Label = styled.label`
  display: block;
  margin 0 8px;

  .input {
    padding: 0 10px;
    border: 1px solid #4e85ec;
    border-radius: 10px;
    height: 35px;
    min-width: 250px;
    margin: 0;
    font-size: 16px;
  }
`;

LoginContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(LoginContainer);
