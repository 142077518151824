import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

export const Container = styled.div`
  background-color: ${colors.light};
  border-radius: 5px;
  margin: auto 10px;
  padding: 5px 20px;
  text-align: center;
  display: flex;
  .question_mark {
    height: 50px;
    width: 40px;
    margin-right: 10px;
  }
  .align_center {
    color: ${colors.dark};
  }
`;

export const LinkButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const LinkButton = styled.div`
  display: inline-flex;
  border: 1px solid ${colors.funnel};
  border-radius: 7px;
  margin: 30px 40px;
  min-width: 220px;
  height: 200px;
`;

export const ButtonContent = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.light};

  .icon {
    background-color: ${colors.funnel};
    border: 1px solid ${colors.funnel};
    color: ${colors.lignt};
    border-radius: 7px;
    padding: 5px 10px;
    display: inline-flex;
    width: 60px;
    height: 60px;
  }
  .description {
    margin: 10px 0;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    width: 200px;
    padding: 0 20px;
  }
  .acceder {
    margin: 10px 0;
    background-color: ${colors.funnel};
    border: 1px solid ${colors.funnel};
    padding: 10px 20px;
    color: ${colors.light};
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
  }
  .acceder:hover {
    background-color: ${colors.light};
    border: 1px solid ${colors.light};
    padding: 10px 20px;
    color: ${colors.funnel};
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: ${colors.primary};
  border-radius: 0;
`;

export const Titles = styled.thead`
  color: ${colors.light};
  text-align: left;
  font-weight: bold;
  tr {
    height: 60px;
    border-radius: 0;
  }
  tr th {
    padding-left: 20px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid ${colors.light};
    border-radius: 0;
  }
`;

export const TableContent = styled.tbody`
  color: ${colors.light};
  tr td {
    padding-left: 20px;
    height: 65px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid ${colors.light};
    border-radius: 0;
  }
`;

export const ErrorMessage = styled.p`
  color: white;
  font-size: 14px;
  padding: 10px 30px;
`;
