import React from 'react';
import { SectionDashboard } from 'components/Section';
import Dashboard from 'components/Dashboard';

/**
 * @component
 * DashboardContainer
 *
 */
function DashboardContainer(props) {
  return (
    <SectionDashboard {...props}>
      <Dashboard />
    </SectionDashboard>
  );
}

export default DashboardContainer;
