import styled from 'styled-components';
import constants from 'utils/constants';
import Link from 'components/Link';
import { Button } from '@material-ui/core';
import Icon from 'utils/icons';

const { colors } = constants;

export const Table = styled.div`
  color: ${colors.light};
  border-bottom: 2px solid ${colors.funnel};
  border-collapse: collapse;
  width: 100%;
  background-color: ${colors.secondary};
  margin : 50px auto 0px auto;
  max-width: 1500px;
  border-radius: 10px;
}
`;

export const TableBody = styled.section`
  height: 650px;
  max-height: 55vh;
  min-height: 100px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled(Button)`
&& {
  background-color: ${({ bgcolor }) => bgcolor || colors.red};
  color: ${({ color }) => color || colors.light};
  width: ${({ width }) => width || 'auto'};
  text-align : left;
  padding: 10px;
  svg {
    margin-right: 10px;
  }
}
&&:hover {
  background-color: ${({ bgcolor, disableHover }) =>
    disableHover && (bgcolor || colors.secondary)};
}
white-space: nowrap;
  }
`;

export const HeaderTable = styled.div`
  max-width: 730px;
  margin: 0px auto;
  text-align : left;
  padding : 25px;
  font-weight: 300;
  font-size: 20px;
  font-weight: bold;
  color: ${({ color }) => color || colors.light};
}
`;

export const TableContent = styled.div`
  border-bottom: 1px solid ${({ color }) => color || colors.light};
  color: ${({ color }) => color || colors.light};
  display: flex;
  justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  align-items: center;
`;

export const DefaultTableLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 2px solid ${colors.funnel};
  height: 105px;
`;

export const TableLine = styled(DefaultTableLine)`
  &:hover {
    background-color:${colors.tertiary};
  } 
  border-bottom: none;
}
`;

export const TableLink = styled(Link)`
  color: ${({ color }) => color || colors.light};
  font-size: 20px;
  display: block;
  text-decoration: none;
  padding: 25px;
  &:hover {
    text-decoration: none;
  } 
}
`;

export const Icons = styled(Icon)`
height:32px;
padding:8px;
}
`;
export default {};
