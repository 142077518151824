import React from 'react';
// import moment from 'utils/moment';
import constants from 'utils/constants';
import ConnectorFunnel, {
  InitialContent,
  CancelButton,
  CsvReader
} from 'Funnel/components/ConnectorFunnel';
import ConnectorForm from 'Funnel/components/ConnectorForm';
import ConnectorImg from 'Funnel/components/ConnectorImg';

import HiderFunnel from 'Funnel/components/HiderFunnel';

import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { formatCsv, handleForecast } from './functions';

const { connectors: source } = constants;

/**
 * ConnectorsContainer
 * @container
 *
 */

function ConnectorsContainer() {
  const { canSeeConnector, setHasConnector } = useFunnelStep();
  const { setFunnel, funnel } = useFunnel();
  const { measure, funnelRef } = funnel;
  const setFunnelRef = newRef => setFunnel({ ...funnel, funnelRef: newRef });
  const connectors = measure && measure.funnels;
  const [connector, updateConnector] = React.useState(null);
  const [connected, updateConnected] = React.useState(source.map(() => false));
  // debut comment
  const setConnector = (funnelId, oneConnector, data, childId) => {
    const connectedList = funnel.connected.filter(c => c.funnelId !== funnelId);
    const fields = { funnelId, connector: oneConnector, data };
    const oneConnected = [
      ...connectedList,
      childId ? { ...fields, childId } : fields
    ];
    setFunnel({ ...funnel, connected: oneConnected });
  };

  const setForecast = forecast => {
    setFunnel({ ...funnel, forecast });
  };
  //  fin comment

  const handleCsv = d => {
    updateConnector(null);
    updateConnected(
      connected.map((c, i) => c || i === source.find(co => co === connector.id))
    );
    console.log('Handle Csv : ', d);
    // console.log('Funnel : ', funnel);
    console.log('funnel csv', funnel);
    console.log('funnelRef', funnelRef);
    setConnector(
      funnelRef.id,
      source[4],
      formatCsv(funnelRef.id, d, funnel),
      funnelRef.childId
    );
    if (funnelRef.childId !== null) {
      if (Array.isArray(funnel.measure.funnels.childId)) {
        for (let i = 0; i < funnelRef.childId.length; i += 1) {
          setConnector(
            funnelRef.childId[i],
            source[4],
            formatCsv(funnelRef.childId[i], d, funnel),
            null
          );
        }
      } else {
        setConnector(
          funnelRef.childId,
          source[4],
          formatCsv(funnelRef.childId, d, funnel),
          null
        );
      }
    }
    if (connector.forecast.length) {
      setForecast(handleForecast(connector.forecast, d));
    }
  };

  const handleError = e => {
    throw new Error(e);
  };

  const cancel = () => null;

  const handleConnector = c => {
    const currentConnector = source.find(s => s.id === c.id);
    updateConnector(currentConnector);
    updateConnected(source.map(s => s.id === c.id));
  };

  console.log(connector, 'source');
  return (
    <HiderFunnel hide={!canSeeConnector}>
      <ConnectorFunnel
        cancel={cancel}
        connector={connector}
        setHasConnector={setHasConnector}
        connectors={connectors}
        setFunnelRef={setFunnelRef}
      >
        <InitialContent connector={connector}>
          {source.map((c, i) => (
            <ConnectorImg
              element={c}
              index={i}
              connected={connected}
              handleConnector={handleConnector}
            />
          ))}
        </InitialContent>
        <ConnectorForm
          updateConnected={updateConnected}
          updateConnector={updateConnector}
          connected={connected}
          connector={connector}
          source={source}
          cancel={cancel}
        />
        <CsvReader
          handleCsv={handleCsv}
          handleError={handleError}
          connector={connector}
        >
          <CancelButton cancel={cancel} />
        </CsvReader>
      </ConnectorFunnel>
    </HiderFunnel>
  );
}

export default ConnectorsContainer;
