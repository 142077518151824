import React from 'react';
import Footer from 'components/Footer';

/**
 * @component
 * @param {children} param0
 */
function FooterContainer() {
  return (
    <Footer>
      <div>
        <span>
          <strong>Nous Contacter :</strong>
        </span>
        <div>
          <a href="mailto:contact@ermes.ai">contact@ermes.ai</a>
        </div>
      </div>
      <div className="mobiletel">
        <a href="tel:+33140687850">01.40.68.78.50</a>
      </div>
      <div className="adresse">123 Avenue de Villiers, 75017 Paris</div>
      <div className="copyright">© 2019 Ermes</div>
    </Footer>
  );
}

export default FooterContainer;
