export default {
  ACTIVATION: '/activation',
  DASHBOARD: '/dashboard',
  DATA: '/data',
  DEFAULT: '/',
  FEATURE: '/features',
  FUNNEL: '/funnel',
  HISTORY: '/history',
  INSIGHT: '/insight'
};
