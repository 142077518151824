import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { FUNNEL } from 'utils/graphQL';
import Loading from 'components/Loading';
import HistoryFunnel, {
  HistoryFunnelItem
} from 'Funnel/components/HistoryFunnel';
import { useUser } from 'contexts/UserContext';

/** AddedItems @component  */
const AddedItems = ({ token, variables }) =>
  !token ? null : (
    <Query
      query={FUNNEL.LIST_FUNNEL}
      variables={{ ...variables, nextToken: token }}
    >
      {response => (
        <Loading {...response}>
          {FUNNEL.extractListFunnel(response).map(oneFunnel => (
            <HistoryFunnelItem funnel={oneFunnel} />
          ))}
          <AddedItems
            variables={variables}
            token={FUNNEL.extractTokenFunnel(response)}
          />
        </Loading>
      )}
    </Query>
  );
AddedItems.defaultProps = {
  token: '',
  variables: {}
};
AddedItems.propTypes = {
  token: PropTypes.string,
  variables: PropTypes.objectOf(PropTypes.any)
};

/**
 * HistoryFunnelContainer
 * @component
 * @see HistoryContainer
 * TODO:: creer les composants Loading, remove panel
 *
 *
 */
function HistoryFunnelContainer(props) {
  const { user, isAuthenticated } = useUser();
  const {
    match: { params }
  } = props;
  const { panel } = params;
  const variables = { userId: user.username || null, saved: true, limit: 100 };
  return (
    <HistoryFunnel panel={panel}>
      <Query
        query={FUNNEL.LIST_FUNNEL}
        skip={!isAuthenticated}
        variables={variables}
      >
        {response => (
          <Loading {...response}>
            {FUNNEL.extractListFunnel(response).map(oneFunnel => (
              <HistoryFunnelItem funnel={oneFunnel} />
            ))}
            <AddedItems
              variables={variables}
              token={FUNNEL.extractTokenFunnel(response)}
            />
          </Loading>
        )}
      </Query>
    </HistoryFunnel>
  );
}
HistoryFunnelContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(HistoryFunnelContainer);
