import React from 'react';
import styled from 'styled-components';
import Title from 'components/Title';
import constants from 'utils/constants';

const { colors } = constants;

/**
 * CriteriaTitle
 * @component
 *
 */
export const InsightTitle = props => <Title big {...props} />;

/**
 * BaseInsightInsight
 * @component
 *
 */
const BaseCriteriasForm = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  background-color: ${colors.secondary};
  color: ${colors.light};
  padding: 8px 16px;
  border-radius: 8px;
  height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  .legend {
    display: grid;
    grid-template-columns: ${({ displayRightBlock }) =>
      displayRightBlock ? '1fr 2fr 2fr' : '1fr 2fr'};
    div {
      text-transform: uppercase;
    }
    .ermes {
      color: ${colors.insight};
      text-align: ${({ displayRightBlock }) =>
        displayRightBlock ? 'right' : 'left'};
      margin-right: 15px;
    }
    .insee {
      color: #e0e0e0;
      margin-left: 15px;
    }
  }

  .InsightTitle {
    font-size: 36px;
    font-weight: bold;
    text-align: left;
    margin: 3px 0;
  }
`;

/**
 * InsightDesc
 * @component
 *
 */
export const InsightDesc = styled.p`
  color: ${colors.light};
  font-size: 18px;
  min-width: 650px;
  margin: 5px 0 10px 0;
`;

export default BaseCriteriasForm;
