import React from 'react';
import PropTypes from 'prop-types';

import BaseOperator from './style';

/**
 * OperatorInsight
 * @component
 * @see OperatorContainer
 *
 */
function OperatorInsight(props) {
  const { operator, hide, rounded, handleChange } = props;
  const handleClick = e => {
    e.preventDefault();
    handleChange();
  };
  return (
    <BaseOperator href="#" hide={hide} rounded={rounded} onClick={handleClick}>
      {operator}
    </BaseOperator>
  );
}
OperatorInsight.defaultProps = {
  hide: false,
  rounded: false
};
OperatorInsight.propTypes = {
  handleChange: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  operator: PropTypes.string.isRequired,
  rounded: PropTypes.bool
};

export default OperatorInsight;
