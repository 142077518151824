import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import * as csv from 'csvtojson';
import fetch from 'isomorphic-unfetch';
import _ from 'lodash';
import moment from 'moment';
import CSVReader from 'react-csv-reader';
import constants from 'utils/constants';
import styled from 'styled-components';

// import './Connector-style.css';

const { colors } = constants;

const customStyles = {
  content: {
    border: 'none',
    borderRadius: '10px',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '625px',
    backgroundColor: colors.secondary,
    display: 'flex',
    flexDirection: 'column',
    height: '807px',
    color: colors.light,
    overflow: 'none'
  }
};

const bodyStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '25px 45px'
};

const linkConnector = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  left: '7%',
  position: 'relative'
};

const linkConnectorItem = {
  height: '125px',
  width: '125px',
  backgroundColor: 'white',
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: '7%',
  marginBottom: '10%'
};

const connectorContainer = {
  display: 'flex',
  margin: 'auto',
  textAlign: 'center',
  minHeight: '125px',
  cursor: 'pointer'
};

const connectorContainerValid = {
  display: 'flex',
  margin: 'auto',
  textAlign: 'center',
  minHeight: '125px',
  position: 'relative',
  bottom: '30%',
  cursor: 'pointer'
};

const imgStyle = {
  width: 'min-content',
  margin: 'auto',
  maxWidth: '100px'
};

const nameStyle = {
  textAlign: 'center',
  fontWeight: 'bolder',
  marginTop: '3px',
  cursor: 'pointer'
};

const nameStyleValid = {
  textAlign: 'center',
  fontWeight: 'bolder',
  marginTop: '3px',
  cursor: 'pointer',
  bottom: '30%',
  position: 'relative'
};

const modalHeader = {
  display: 'inline-block'
};

const textCenter = {
  textAlign: 'center',
  fontSize: '20px'
};

const doneButton = {
  margin: '40px auto',
  width: '300px',
  height: '44px',
  textAlign: 'center',
  color: '#3e6de7',
  lineHeight: '40px',
  border: '2px solid #4e85ec',
  borderRadius: '22px',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  cursor: 'pointer'
};

const Style = styled.div`
  .csv-reader-input label {
    background-color: #06cb52;
    color: white;
    border-radius: 100px;
    padding: 10px;
    display: inline-block;
    margin: 35px;
  }
  .csv-reader-input input {
    opacity: 0;
    position: absolute;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 20px 0;
  flex-wrap: wrap;
  font-size: 20px;
  .title {
    display: inline-flex;
    align-items: center;
    align-content: center;
  }
  .modal-logo {
    display: inline-flex;
    width: 100px;
    align-items: flex-end;
  }
  .connector-name {
    display: inline-flex;
  }
  .logo-connect {
    display: inline-flex;
  }
`;

const Description = styled.div`
  line-height: 40px;
  .point {
    background-color: #1a71e8;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
  }
  .permission-text {
    font-size: 14px;
  }
`;

const Form = styled.form`
  .opt-in-container {
    font-size: 14px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  cursor: pointer;
`;

const Label = styled.label`
  margin 0 8px;
  display: inline-flex;
  align-items: center;

  .input {
    padding: 0 10px;
    border: 1px solid #4e85ec;
    border-radius: 10px;
    height: 35px;
    min-width: 250px;
    margin: 0;
    font-size: 16px;
  }
  .span {
    padding-right: 20px;
    width: 140px;
    min-width:120px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

const Button = styled.a`
  display: inline-flex;
  padding: 12px 25px;
  border-radius: 7px;
  color: ${({ color }) => color || colors.light};
  background-color: ${({ bgColor }) => bgColor || colors.secondary};
`;

Modal.setAppElement('#root');

export default class Connector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      connector: null,
      connected: props.connectors.map(() => false),
      email: '',
      password: ''
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSso = this.handleSso.bind(this);
    this.handleConnector = this.handleConnector.bind(this);
    this.handleCsv = this.handleCsv.bind(this);
    this.cancel = this.cancel.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
  }

  setEmail = event => {
    const email = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      email
    }));
  };

  setPassword = event => {
    const password = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      password
    }));
  };

  formatAnom = v => {
    let value = '0';
    if (v === '' || v === undefined) return value;
    value = v;
    return value;
  };

  handleConnector(id) {
    const connector = _.find(this.props.sources, s => s.id === id);
    this.setState({
      connector
    });
  }

  markAsConnected() {
    this.setState(prevState => ({
      connector: null,
      email: '',
      password: '',
      connected: prevState.connected.map(
        (c, i) =>
          c ||
          i ===
            _.findIndex(
              this.props.connectors,
              co => co === prevState.connector.id
            )
      )
    }));
  }

  handleSso() {
    this.markAsConnected();
  }

  handleLocal(idx) {
    this.fetchCsv().then(res => {
      const data = this.formatCsv(this.props.funnelId, res);

      this.props.setConnector(
        this.props.funnelId,
        this.props.connectors[idx],
        data,
        this.props.childId
      );
      if (this.props.childId !== null) {
        const child = this.formatCsv(this.props.childId, res);
        this.props.setConnector(
          this.props.childId,
          this.props.connectors[idx],
          child,
          null
        );
      }
    });
  }

  formatString(v, m = 1000) {
    let value = 0;
    if (_.isString(v) && !_.isEmpty(v)) {
      value = parseFloat(v.replace(/\s/g, ''));
      value *= m;
    }
    return value;
  }

  cancel() {
    this.setState({
      connector: null
    });
  }

  formatCsv(kpi, res) {
    const funnel = _.find(this.props.funnel.measure.funnels, f => f.id === kpi);
    const budget = _.omit(_.first(res), ['kpi', 'title']);
    const values = res[_.findIndex(res, r => r.kpi === kpi)];
    const anomValues = res[_.findIndex(res, r => r.kpi === 'ANOM')];
    const baselineValues = res[_.findIndex(res, () => funnel.baseline)];
    const akpi1Values = res[_.findIndex(res, r => r.kpi === 'AKPI1')];
    const akpi2Values = res[_.findIndex(res, r => r.kpi === 'AKPI2')];
    const akpi3Values = res[_.findIndex(res, r => r.kpi === 'AKPI3')];
    const akpi4Values = res[_.findIndex(res, r => r.kpi === 'AKPI4')];
    const tkpi1Values = res[_.findIndex(res, r => r.kpi === 'TKPI1')];
    const tkpi2Values = res[_.findIndex(res, r => r.kpi === 'TKPI2')];
    const tkpi3Values = res[_.findIndex(res, r => r.kpi === 'TKPI3')];
    const tkpi4Values = res[_.findIndex(res, r => r.kpi === 'TKPI4')];
    const ekpi1Values = res[_.findIndex(res, r => r.kpi === 'EKPI1')];
    const ekpi2Values = res[_.findIndex(res, r => r.kpi === 'EKPI2')];
    const ekpi3Values = res[_.findIndex(res, r => r.kpi === 'EKPI3')];
    const ekpi4Values = res[_.findIndex(res, r => r.kpi === 'EKPI4')];
    const mediaB1Values = res[_.findIndex(res, r => r.kpi === 'MEDIAB1')];
    const mediaB2Values = res[_.findIndex(res, r => r.kpi === 'MEDIAB2')];
    const mediaB3Values = res[_.findIndex(res, r => r.kpi === 'MEDIAB3')];
    const mediaB4Values = res[_.findIndex(res, r => r.kpi === 'MEDIAB4')];
    const mediaB5Values = res[_.findIndex(res, r => r.kpi === 'MEDIAB5')];
    const mediaB6Values = res[_.findIndex(res, r => r.kpi === 'MEDIAB6')];
    const mediaB7Values = res[_.findIndex(res, r => r.kpi === 'MEDIAB7')];
    // const mediaB8Values = res[_.findIndex(res, r => r.kpi === 'MEDIAB8')];
    // const mediaB9Values = res[_.findIndex(res, r => r.kpi === 'MEDIAB9')];
    // const mediaB10Values = res[_.findIndex(res, r => r.kpi === 'MEDIAB10')];
    const mediaI1Values = res[_.findIndex(res, r => r.kpi === 'MEDIAI1')];
    const mediaI2Values = res[_.findIndex(res, r => r.kpi === 'MEDIAI2')];
    const mediaI3Values = res[_.findIndex(res, r => r.kpi === 'MEDIAI3')];
    const mediaI4Values = res[_.findIndex(res, r => r.kpi === 'MEDIAI4')];
    const mediaI5Values = res[_.findIndex(res, r => r.kpi === 'MEDIAI5')];
    const mediaI6Values = res[_.findIndex(res, r => r.kpi === 'MEDIAI6')];
    const mediaI7Values = res[_.findIndex(res, r => r.kpi === 'MEDIAI7')];
    // const mediaI8Values = res[_.findIndex(res, r => r.kpi === 'MEDIAI8')];
    // const mediaI9Values = res[_.findIndex(res, r => r.kpi === 'MEDIAI9')];
    // const mediaI10Values = res[_.findIndex(res, r => r.kpi === 'MEDIAI10')];
    const mediaName1Values = res[_.findIndex(res, r => r.kpi === 'MEDIANAME1')];
    const mediaName2Values = res[_.findIndex(res, r => r.kpi === 'MEDIANAME2')];
    const mediaName3Values = res[_.findIndex(res, r => r.kpi === 'MEDIANAME3')];
    const mediaName4Values = res[_.findIndex(res, r => r.kpi === 'MEDIANAME4')];
    const mediaName5Values = res[_.findIndex(res, r => r.kpi === 'MEDIANAME5')];
    const mediaName6Values = res[_.findIndex(res, r => r.kpi === 'MEDIANAME6')];
    const mediaName7Values = res[_.findIndex(res, r => r.kpi === 'MEDIANAME7')];
    // const mediaName8Values = res[_.findIndex(res, r => r.kpi === 'MEDIANAME8')];
    // const mediaName9Values = res[_.findIndex(res, r => r.kpi === 'MEDIANAME9')];
    // const mediaName10Values =
    // res[_.findIndex(res, r => r.kpi === 'MEDIANAME10')];
    const dayOffvalues = res[_.findIndex(res, r => r.kpi === 'DAYOFF')];

    const dates = Object.keys(budget);
    const data = _.map(budget, (b, i) => {
      let value = this.formatString(values[i]);
      const display = value;
      if (!_.isUndefined(funnel.baseline)) {
        value = this.formatString(baselineValues[i]) - value;
      }
      const price = this.formatString(b);
      const anom = this.props.target ? this.formatString(anomValues[i]) : 0;
      const cost = value === 0 ? 0 : price / value;
      const graph = cost * funnel.per;
      let dayoff = 0;
      if (dayOffvalues) dayoff = this.formatAnom(dayOffvalues[i]);
      const akpi1 = this.props.target ? this.formatAnom(akpi1Values[i]) : '0';
      const akpi2 = this.props.target ? this.formatAnom(akpi2Values[i]) : '0';
      const akpi3 = this.props.target ? this.formatAnom(akpi3Values[i]) : '0';
      const akpi4 = this.props.target ? this.formatAnom(akpi4Values[i]) : '0';
      const tkpi1 = this.props.target ? this.formatAnom(tkpi1Values[i]) : '0';
      const tkpi2 = this.props.target ? this.formatAnom(tkpi2Values[i]) : '0';
      const tkpi3 = this.props.target ? this.formatAnom(tkpi3Values[i]) : '0';
      const tkpi4 = this.props.target ? this.formatAnom(tkpi4Values[i]) : '0';
      const ekpi1 = this.props.target ? this.formatAnom(ekpi1Values[i]) : '0';
      const ekpi2 = this.props.target ? this.formatAnom(ekpi2Values[i]) : '0';
      const ekpi3 = this.props.target ? this.formatAnom(ekpi3Values[i]) : '0';
      const ekpi4 = this.props.target ? this.formatAnom(ekpi4Values[i]) : '0';
      const mediaB1 = this.props.target
        ? this.formatAnom(mediaB1Values[i])
        : '0';
      const mediaB2 = this.props.target
        ? this.formatAnom(mediaB2Values[i])
        : '0';
      const mediaB3 = this.props.target
        ? this.formatAnom(mediaB3Values[i])
        : '0';
      const mediaB4 = this.props.target
        ? this.formatAnom(mediaB4Values[i])
        : '0';
      const mediaB5 = this.props.target
        ? this.formatAnom(mediaB5Values[i])
        : '0';
      const mediaB6 = this.props.target
        ? this.formatAnom(mediaB6Values[i])
        : '0';
      const mediaB7 = this.props.target
        ? this.formatAnom(mediaB7Values[i])
        : '0';
      const mediaI1 = this.props.target
        ? this.formatAnom(mediaI1Values[i])
        : '0';
      const mediaI2 = this.props.target
        ? this.formatAnom(mediaI2Values[i])
        : '0';
      const mediaI3 = this.props.target
        ? this.formatAnom(mediaI3Values[i])
        : '0';
      const mediaI4 = this.props.target
        ? this.formatAnom(mediaI4Values[i])
        : '0';
      const mediaI5 = this.props.target
        ? this.formatAnom(mediaI5Values[i])
        : '0';
      const mediaI6 = this.props.target
        ? this.formatAnom(mediaI6Values[i])
        : '0';
      const mediaI7 = this.props.target
        ? this.formatAnom(mediaI7Values[i])
        : '0';
      const mediaName1 = this.props.target
        ? this.formatAnom(mediaName1Values[Object.keys(mediaName1Values)[2]])
        : 'nodata';
      console.log(mediaName1, 'mediaName1');
      const mediaName2 = this.props.target
        ? this.formatAnom(mediaName2Values[Object.keys(mediaName2Values)[2]])
        : 'nodata';
      const mediaName3 = this.props.target
        ? this.formatAnom(mediaName3Values[Object.keys(mediaName3Values)[2]])
        : 'nodata';
      const mediaName4 = this.props.target
        ? this.formatAnom(mediaName4Values[Object.keys(mediaName4Values)[2]])
        : 'nodata';
      const mediaName5 = this.props.target
        ? this.formatAnom(mediaName5Values[Object.keys(mediaName5Values)[2]])
        : 'nodata';
      const mediaName6 = this.props.target
        ? this.formatAnom(mediaName6Values[Object.keys(mediaName6Values)[2]])
        : 'nodata';
      const mediaName7 = this.props.target
        ? this.formatAnom(mediaName7Values[Object.keys(mediaName7Values)[2]])
        : 'nodata';

      if (dates[0][0] === dates[1][0] && dates[0][1] === dates[1][1]) {
        return {
          kpi: values.kpi,
          title: values.title,
          date: moment(i, 'MM-DD-YYYY')
            .startOf('day')
            .toDate(),
          budget: price,
          value,
          display,
          cost,
          graph,
          anom,
          akpi1,
          akpi2,
          akpi3,
          akpi4,
          tkpi1,
          tkpi2,
          tkpi3,
          tkpi4,
          ekpi1,
          ekpi2,
          ekpi3,
          ekpi4,
          mediaB1,
          mediaB2,
          mediaB3,
          mediaB4,
          mediaB5,
          mediaB6,
          mediaB7,
          mediaI1,
          mediaI2,
          mediaI3,
          mediaI4,
          mediaI5,
          mediaI6,
          mediaI7,
          mediaName1,
          mediaName2,
          mediaName3,
          mediaName4,
          mediaName5,
          mediaName6,
          mediaName7,
          dayoff
        };
      }
      return {
        kpi: values.kpi,
        title: values.title,
        date: moment(i, 'DD-MM-YYYY')
          .startOf('day')
          .toDate(),
        budget: price,
        value,
        display,
        cost,
        graph,
        anom,
        akpi1,
        akpi2,
        akpi3,
        akpi4,
        tkpi1,
        tkpi2,
        tkpi3,
        tkpi4,
        ekpi1,
        ekpi2,
        ekpi3,
        ekpi4,
        mediaB1,
        mediaB2,
        mediaB3,
        mediaB4,
        mediaB5,
        mediaB6,
        mediaB7,
        mediaI1,
        mediaI2,
        mediaI3,
        mediaI4,
        mediaI5,
        mediaI6,
        mediaI7,
        mediaName1,
        mediaName2,
        mediaName3,
        mediaName4,
        mediaName5,
        mediaName6,
        mediaName7,
        dayoff
      };
    });

    return data;
  }

  async fetchCsv() {
    const res = await fetch(`${window.location.origin}/assets/data.csv`);
    const data = await res.text();
    const jsonArray = await csv().fromString(data);
    return jsonArray;
  }

  handleCsv(d) {
    this.markAsConnected();
    this.props.setConnector(
      this.props.funnelId,
      this.props.sources[5],
      this.formatCsv(this.props.funnelId, d),
      this.props.childId
    );
    if (this.props.childId !== null) {
      if (Array.isArray(this.props.childId)) {
        for (let i = 0; i < this.props.childId.length; i += 1) {
          this.props.setConnector(
            this.props.childId[i],
            this.props.sources[5],
            this.formatCsv(this.props.childId[i], d),
            null
          );
        }
      } else {
        this.props.setConnector(
          this.props.childId,
          this.props.sources[5],
          this.formatCsv(this.props.childId, d),
          null
        );
      }
    }
    if (this.props.forecast.length) {
      this.props.setForecast(this.handleForecast(this.props.forecast, d));
    }
  }

  handleForecast(forecasts, data) {
    const forecast = _.map(forecasts, f => {
      const budget = _.omit(_.first(data), ['kpi', 'title']);
      const forecastRow = _.findIndex(data, r => {
        return r.kpi === f;
      });
      const values = data[forecastRow];
      return _.map(budget, (b, i) => {
        const value = this.formatString(values[i], 1);
        return {
          kpi: values.kpi,
          title: values.title,
          date: moment(i, 'DD-MM-YYYY')
            .startOf('day')
            .toDate(),
          value
        };
      });
    });
    return forecast;
  }

  handleError(e) {
    throw new Error(e);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.cancel();
    this.props.onClose();
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  render() {
    const { connector, modalIsOpen, email, password } = this.state;
    const ConnectorImg = ({ id, valid }) => {
      const source = _.find(this.props.sources, s => s.id === id);
      if (this.state.connected[valid]) {
        return (
          <div style={connectorContainerValid} className="connectorContainer">
            {console.log(source.src)}
            <img src={source.src} alt={source.name} style={imgStyle} />
          </div>
        );
      }
      return (
        <div style={connectorContainer} className="connectorContainer">
          {console.log(source.src)}
          <img src={source.src} alt={source.name} style={imgStyle} />
        </div>
      );
    };
    const ConnectorName = ({ id, valid }) => {
      const source = _.find(this.props.sources, s => s.id === id);
      if (this.state.connected[valid]) {
        return (
          <p style={nameStyleValid} className="connector_title">
            {source.name}
          </p>
        );
      }
      return (
        <p style={nameStyle} className="connector_title">
          {source.name}
        </p>
      );
    };
    return (
      <div className="btn_plug_container">
        <button
          style={{ display: 'none' }}
          id={`${this.props.funnelId}-connector`}
          className="btn_plug"
          onClick={this.openModal}
          type="button"
        >
          <svg
            width="50"
            height="50"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="btn_plug_bg"
              cx="50"
              cy="50"
              r="47.5"
              fill="white"
              stroke="#4E85EC"
              strokeWidth="5"
            />
            <path
              className="btn_plug_path"
              d="M38.4159 41.1896L66.5835 41.1896C68.8952 41.1896 70.7707 43.064 70.7707 45.3768C70.7707 47.6887 68.8952 49.5636 66.5835 49.5636L65.3477 49.5636C65.4041 49.9363 65.4421 50.3158 65.4421 50.7045L65.4421 63.725C65.4421 67.9291 62.0345 71.3375 57.8296 71.3375L56.0785 71.3375L56.0785 74.6042C56.0785 76.0847 55.2832 76.6804 54.1001 76.9166L54.1001 96L49.5568 96L49.5568 76.8223C48.5862 76.5358 47.9581 75.9196 47.9581 74.6042L47.9581 71.3375L47.1712 71.3375C42.9677 71.3375 39.5587 67.9293 39.5587 63.725L39.5587 50.7045C39.5587 50.3158 39.598 49.9363 39.6537 49.5636L38.4175 49.5636C36.1057 49.5636 34.2305 47.6887 34.2305 45.3768C34.2306 43.064 36.1039 41.1896 38.4159 41.1896ZM44.6833 25C43.0437 25 41.7143 26.3304 41.7143 27.9692L41.7143 39.8952L47.6522 39.8952L47.6522 27.9692C47.6524 26.3304 46.324 25 44.6833 25ZM57.1441 27.9692L57.1441 39.8952L63.0821 39.8952L63.0821 27.9692C63.0821 26.3304 61.7525 25 60.1128 25C58.4732 25 57.1441 26.3304 57.1441 27.9692Z"
              fill="#4E85EC"
              stroke="#4E85EC"
            />
          </svg>
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {connector && connector.sso ? (
            <Content style={modalHeader} className="modal-header">
              <span className="title  is-flex">
                <img
                  className="modal-logo"
                  src="/assets/img/logo_ermes_homepage.svg"
                  alt="logo ermes funnel"
                />
                <span className="connector-name">
                  &nbsp;souhaite accéder à votre compte&nbsp;
                </span>
                &nbsp;
                <div className="logo-connect">
                  <img src={connector.src} alt={connector.name} />
                </div>
              </span>
            </Content>
          ) : (
            <div style={modalHeader} className="modal-header">
              <div className="modal_logo">
                <img
                  style={{ width: '250px' }}
                  src="/assets/img/logo_ermes_homepage.svg"
                  alt=""
                />
              </div>
              <h5 style={textCenter} className="modal-title align_center">
                Choisir mes connecteurs
              </h5>
            </div>
          )}
          <div className="modal-body" style={bodyStyle}>
            <div
              style={{
                display: connector && connector.sso ? 'block' : 'none'
              }}
            >
              <Description>
                <span>Cela permettra à&nbsp;</span>
                <img
                  width="100px"
                  src="/assets/img/logo_ermes_homepage.svg"
                  className="ermes-funnel"
                  alt="logo ermes funnel"
                />
                <span>&nbsp;d’effectuer les actions suivantes :</span>
                <div className="permission">
                  <span className="point" />
                  <span className="permission-text">
                    Afficher et extraire vos données analytics dans le but de
                    les analyser
                  </span>
                </div>
              </Description>

              <Form>
                <InputContainer className="input-container mail">
                  <Label htmlFor="email" className="is-flex align-center">
                    <span className="span">Email</span>
                    <input
                      type="text"
                      className="input"
                      name="uname"
                      id="email"
                      value={email}
                      onChange={this.setEmail}
                      required
                    />
                  </Label>
                </InputContainer>

                <InputContainer className="input-container password">
                  <Label htmlFor="psw" className="is-flex align-center">
                    <span className="span">Mot de passe</span>
                    <input
                      type="password"
                      className="input"
                      name="psw"
                      id="psw"
                      value={password}
                      onChange={this.setPassword}
                      required
                    />
                  </Label>
                </InputContainer>

                <div className="opt-in is-flex">
                  <label htmlFor="data" className="opt-in-container">
                    <input type="checkbox" id="data" name="data" />
                    J’autorise Ermes à accéder à mon compte pour en extraire les
                    données nécessaires à ErmesFunnel
                    <span className="checkmark" />
                  </label>
                </div>

                <ButtonContainer className="input-container is-flex justify-content-between">
                  <Button
                    color={colors.funnel}
                    className="link"
                    onClick={this.cancel}
                    onKeyPress={this.cancel}
                    role="button"
                    tabIndex="0"
                    style={{ cursor: 'pointer' }}
                  >
                    Annuler
                  </Button>
                  <Button
                    bgColor={colors.funnel}
                    className="btn btn--connector"
                    onClick={this.handleSso}
                    onKeyPress={this.handleSso}
                    role="button"
                    tabIndex="0"
                    style={{ cursor: 'pointer' }}
                  >
                    Valider
                  </Button>
                </ButtonContainer>
              </Form>
            </div>

            <div
              style={{
                // padding: '25px 45px',
                display: !this.state.connector ? 'block' : 'none'
              }}
            >
              <div
                style={linkConnector}
                className="flex_container modal_connector_container connectorGrid"
              >
                {this.props.connectors.map((c, i) => (
                  <div
                    className={`modal_connector ${
                      this.state.connected[i] ? 'valid' : ''
                    }`}
                    key={c}
                    style={linkConnectorItem}
                    onClick={this.handleConnector.bind(this, c)}
                    onKeyPress={this.handleConnector.bind(this, c)}
                    role="button"
                    tabIndex={i}
                  >
                    {this.state.connected[i] ? (
                      <img
                        className="valid_icon"
                        src="/assets/img/picto_checked.svg"
                        alt=""
                      />
                    ) : (
                      ''
                    )}
                    <ConnectorImg id={c} valid={i} />
                    <ConnectorName id={c} valid={i} />
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{
                border: '1px dashed #a0a0a0',
                borderRadius: '10px',
                textAlign: 'center',
                marginTop: '20px',
                display:
                  this.state.connector && this.state.connector.upload
                    ? 'block'
                    : 'none'
              }}
            >
              <Style>
                <CSVReader
                  label="Sélectionner un CSV"
                  onFileLoaded={this.handleCsv}
                  onError={this.handleError}
                  inputId="Csv"
                  parserOptions={{ header: true }}
                />
              </Style>
              <div
                className="pull-right"
                style={{ margin: '15px', cursor: 'pointer' }}
              >
                <a
                  className="link"
                  onClick={this.cancel}
                  onKeyPress={this.cancel}
                  role="button"
                  tabIndex="0"
                >
                  Annuler
                </a>
              </div>
            </div>
          </div>
          <button
            style={doneButton}
            className="btn"
            onClick={this.closeModal}
            type="button"
          >
            J’ai fini
          </button>
        </Modal>
      </div>
    );
  }
}

Connector.propTypes = {
  onClose: PropTypes.func.isRequired,
  funnel: PropTypes.object.isRequired,
  funnelId: PropTypes.string.isRequired,
  connectors: PropTypes.array.isRequired,
  sources: PropTypes.array.isRequired,
  setConnector: PropTypes.func.isRequired,
  setForecast: PropTypes.func.isRequired,
  setAnom: PropTypes.func.isRequired,
  childId: PropTypes.string,
  target: PropTypes.bool,
  forecast: PropTypes.array
};

Connector.defaultProps = {
  childId: null,
  target: false,
  forecast: []
};
