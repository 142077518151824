import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimeSeries, TimeRange } from 'pondjs';
import moment from 'utils/moment';

import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  Legend,
  LineChart,
  Resizable,
  styler,
  EventMarker
} from 'react-timeseries-charts';
import styled from 'styled-components';

const style = styler([
  { key: 'CPI', color: '#2F80ED', width: '2px' },
  { key: 'FCPI', color: '#009FE3', width: '2px' }
]);

const legendStyle = {
  margin: '10px',
  borderRadius: '7px',
  color: 'white',
  backgroundColor: 'white',
  width: '200px',
  height: 'fit-content',
  display: 'flex',
  justifyContent: 'center',
  padding: '8px 0'
};

const ZoomButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const GraphContainer = styled.div`
  padding: 0 15px;
`;

const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
`;

const Description = styled.div`
  display: flex;
  justify-content: flex-start;
  color: white;
  padding: 45px 11px;
`;

export default class Graph extends Component {
  constructor(props) {
    super(props);
    const { funnel } = this.props;
    this.state = {
      timerange: new TimeRange([
        moment(funnel.end).add(1, 'D'),
        moment(funnel.end).add(1, 'M')
      ]),
      mode: 'zoom',
      cpi: [],
      fcpi: [],

      tracker: null,
      trackerValue: '',
      trackerEvent: null,
      markerMode: 'flag'
    };
    this.creatSeries();
  }

  getseriescpi() {
    const seriescpi = new TimeSeries({
      name: 'CPI',
      columns: ['time', 'CPI'],
      points: this.state.cpi
    });
    return seriescpi;
  }

  getseriesfcpi() {
    const seriesfcpi = new TimeSeries({
      name: 'FCPI',
      columns: ['time', 'FCPI'],
      points: this.state.fcpi
    });
    return seriesfcpi;
  }

  dateValidefcpi = date => {
    if (this.state.fcpi.length > 25) {
      for (let i = 25; i < this.state.fcpi.length; i += 1) {
        if (
          date.format('DD-MM-YYYY') ===
          moment(this.state.fcpi[i][0]).format('DD-MM-YYYY')
        )
          return -1;
      }
    }
    return 0;
  };

  dateValidecpi = date => {
    if (this.state.cpi.length > 25) {
      for (let i = 25; i < this.state.cpi.length; i += 1) {
        if (
          date.format('DD-MM-YYYY') ===
          moment(this.state.cpi[i][0]).format('DD-MM-YYYY')
        )
          return -1;
      }
    }
    return 0;
  };

  creatSeries = () => {
    const { forecast, funnel } = this.props;
    forecast[0].map(item => {
      const date = moment(item.date);
      const check = this.dateValidecpi(date);
      if (date > moment(funnel.end).subtract(1, 'D') && check === 0)
        return this.state.cpi.push([date, item.value]);
      return -1;
    });
    forecast[1].map(item => {
      const date = moment(item.date);
      const check = this.dateValidefcpi(date);
      if (date > moment(funnel.end).subtract(1, 'D') && check === 0)
        return this.state.fcpi.push([date, item.value]);
      return -1;
    });
  };

  handleTimeRangeChange = timerange => {
    this.setState({ timerange });
  };

  handleTrackerChanged = t => {
    const seriescpi = this.getseriescpi();
    let eventValue;
    if (t) {
      const e = seriescpi.atTime(t);
      eventValue = e.get('CPI');
      const m = `${eventValue.toFixed(2)}`;

      const eventTime = new Date(
        e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );

      this.setState({
        tracker: eventTime,
        trackerValue: m,
        trackerEvent: e
      });
    } else {
      this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
    }
  };

  renderMarker = () => {
    if (!this.state.tracker) {
      return <g />;
    }
    if (this.state.markerMode === 'flag') {
      return (
        <EventMarker
          type="flag"
          axis="y"
          event={this.state.trackerEvent}
          column={['CPI']}
          info={[
            {
              label: this.props.kpi,
              value: this.state.trackerValue
            }
          ]}
          infoTimeFormat="%d/%m/%y"
          infoWidth={150}
          infoHeight={65}
          markerRadius={3}
          markerStyle={{ fill: 'white' }}
          infoStyle={{
            fill: 'white',
            stroke: 'black'
          }}
        />
      );
    }
  };

  renderChart = () => {
    if (this.state.cpi.length === 0) return <div>ERROR DATA</div>;
    const seriescpi = this.getseriescpi();
    const seriesfcpi = this.getseriesfcpi();
    const croppedSeries = seriescpi.crop(this.state.timerange);
    const e = seriescpi.max('CPI');
    const m = seriesfcpi.min('FCPI');
    return (
      <ChartContainer
        timeRange={this.state.timerange}
        onTimeRangeChanged={this.handleTimeRangeChange}
        onTrackerChanged={this.handleTrackerChanged}
        format="%d/%m"
      >
        <ChartRow height="300">
          <YAxis
            id="y"
            min={m - 0.1}
            max={e + 0.1}
            format=",.2f"
            width="90"
            type="linear"
          />
          <Charts>
            <LineChart
              axis="y"
              style={style}
              columns={['CPI']}
              series={croppedSeries}
            />
            {this.renderMarker()}
          </Charts>
        </ChartRow>
      </ChartContainer>
    );
  };

  renderLegend() {
    return (
      <div style={legendStyle}>
        <Legend
          type="line"
          align="left"
          stack
          style={style}
          categories={[{ key: 'CPI', label: this.props.kpi }]}
          select=""
        />
      </div>
    );
  }

  render() {
    const linkStyle = {
      fontWeight: 600,
      color: 'grey',
      cursor: 'default',
      margin: '5px',
      border: '1px solid steelblue',
      padding: '3px 15px',
      borderRadius: '5px'
    };

    const linkStyleActive = {
      color: 'steelblue',
      cursor: 'pointer',
      margin: '5px',
      border: '1px solid steelblue',
      padding: '3px 15px',
      borderRadius: '5px'
    };

    return (
      <GraphContainer>
        <Container>
          <ZoomButtonContainer>
            <span
              className="zoom_buttonplus"
              style={this.state.mode === 'zoom' ? linkStyleActive : linkStyle}
              onClick={this.timeZoom}
              onKeyPress={this.timeZoom}
              role="button"
              tabIndex="0"
            >
              +
            </span>
            <span
              className="zoom_buttonmoins"
              style={this.state.mode === 'dezoom' ? linkStyleActive : linkStyle}
              onClick={this.timedeZoom}
              onKeyPress={this.timedeZoom}
              role="button"
              tabIndex="-1"
            >
              -
            </span>
          </ZoomButtonContainer>
          <Description>
            Cliquez sur la légende pour sélectionner les courbes à afficher et
            remettre à l{`'`}échelle.
          </Description>
          <LegendContainer className="legend_container">
            {this.renderLegend()}
          </LegendContainer>
        </Container>

        <Resizable>{this.renderChart()}</Resizable>
      </GraphContainer>
    );
  }
}

Graph.propTypes = {
  funnel: PropTypes.object.isRequired,
  forecast: PropTypes.array.isRequired,
  kpi: PropTypes.string.isRequired,
  connected: PropTypes.object.isRequired
};
