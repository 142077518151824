import React from 'react';
import PropTypes from 'prop-types';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
// import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';

import ConnectorWrapper, {
  ConnectorButton,
  ConnectorModal,
  ConnectorSection,
  ConnectorSVG,
  Content,
  Header,
  DoneButton,
  ConnectorContainer,
  AddConnectorTitle,
  AddConnectorText,
  CSVstyle
} from './style';

/**
 * ConnectorFunnel
 * @component
 *
 */
function ConnectorFunnel(props) {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { funnel } = useFunnel();
  // const { setHasConnector } = useFunnelStep();
  // const { children, cancel, connector, connectors, setFunnelRef } = props;
  const { children, cancel, connector } = props;
  const handleClick = (e, index, f) => {
    e.preventDefault();
    console.log(index);
    const targetConnector = document.getElementById(`${f.id}-connector`);
    targetConnector.click();
    // setModalIsOpen(true);
    // setHasConnector();
    // setFunnelRef({ ...connectors[index] });
  };
  return (
    <ConnectorWrapper>
      <ConnectorModal
        isOpen={modalIsOpen}
        onAfterOpen={() => null}
        onRequestClose={() => {
          setModalIsOpen(false);
          cancel();
        }}
        contentLabel="Login Modal"
        arial-label="Connector Modal"
      >
        <Content>{children}</Content>
        <DoneButton
          connector={connector}
          className="btn"
          type="button"
          onClick={() => setModalIsOpen(false)}
        >
          J’ai fini
        </DoneButton>
      </ConnectorModal>
      <ConnectorSection>
        <AddConnectorTitle>
          <AddConnectorText>AJOUTER DES CONNECTEURS</AddConnectorText>
        </AddConnectorTitle>

        {funnel.measure
          ? funnel.measure.funnels.map((f, ind) => {
              return f.connectors.length ? (
                <ConnectorButton onClick={e => handleClick(e, ind, f)}>
                  <ConnectorSVG />
                  <strong>{f.name}</strong>
                </ConnectorButton>
              ) : null;
            })
          : null}
      </ConnectorSection>
    </ConnectorWrapper>
  );
}
ConnectorFunnel.defaultProps = {
  children: undefined,
  connectors: []
};
ConnectorFunnel.propTypes = {
  cancel: PropTypes.func.isRequired,
  connector: PropTypes.object.isRequired,
  connectors: PropTypes.arrayOf(PropTypes.any),
  setFunnelRef: PropTypes.func.isRequired,
  children: PropTypes.node
};

export function InitialContent(props) {
  console.log('canSee');
  return (
    <ConnectorContainer connector={props.connector}>
      <Header connector={props.connector}>Choisir mes connecteurs</Header>
      {props.children}
    </ConnectorContainer>
  );
}

InitialContent.defaultProps = {
  children: undefined
};
InitialContent.propTypes = {
  connector: PropTypes.object.isRequired,
  children: PropTypes.node
};

export function CancelButton(props) {
  return (
    <div className="pull-right" style={{ margin: '15px' }}>
      <a
        className="link"
        onClick={props.cancel}
        onKeyPress={props.cancel}
        role="button"
        tabIndex="0"
      >
        Annuler
      </a>
    </div>
  );
}

CancelButton.propTypes = {
  cancel: PropTypes.func.isRequired
};

export function CsvReader(props) {
  return (
    <div
      style={{
        display: props.connector && props.connector.upload ? 'block' : 'none'
      }}
    >
      <CSVstyle
        cssClass="csv-reader-input form-control"
        cssInputClass="form-control-file"
        label="Sélectionner un CSV"
        onFileLoaded={props.handleCsv}
        onError={props.handleError}
        inputId="Csv"
        parserOptions={{ header: true }}
      />
      {props.children}
    </div>
  );
}

CsvReader.defaultProps = {
  children: undefined
};
CsvReader.propTypes = {
  connector: PropTypes.object.isRequired,
  handleCsv: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default ConnectorFunnel;
