import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'utils/moment';

import constants from 'utils/constants';

const { measures, connectors } = constants;

const FunnelContext = React.createContext();

const createFunnel = gql`
  mutation createFunnel(
    $name: String!
    $measure: AWSJSON
    $start: AWSDateTime
    $end: AWSDateTime
    $budget: Float
    $userId: ID!
  ) {
    createFunnel(
      input: {
        name: $name
        measure: $measure
        start: $start
        end: $end
        budget: $budget
        userId: $userId
      }
    ) {
      id
      name
      measure
      start
      end
      userId
      createdAt
      updatedAt
    }
  }
`;

const deleteFunnel = gql`
  mutation deleteFunnel($id: ID!) {
    deleteFunnel(input: { id: $id }) {
      id
    }
  }
`;

const getFunnel = gql`
  query getFunnel($id: ID!) {
    getFunnel(id: $id) {
      id
      name
      start
      end
      measure
      budget
      connected
      objectifed
      forecast
      anom
      saved
      createdAt
      updatedAt
      userId
    }
  }
`;

const updateFunnel = gql`
  mutation updateFunnel(
    $id: ID!
    $start: AWSDateTime
    $end: AWSDateTime
    $budget: Float
    $connected: AWSJSON
    $objectifed: AWSJSON
    $forecast: AWSJSON
    $anom: AWSJSON
    $saved: Boolean
  ) {
    updateFunnel(
      input: {
        id: $id
        start: $start
        end: $end
        budget: $budget
        connected: $connected
        objectifed: $objectifed
        forecast: $forecast
        anom: $anom
        saved: $saved
      }
    ) {
      id
      name
      start
      end
      measure
      budget
      connected
      objectifed
      forecast
      anom
      saved
      createdAt
      updatedAt
    }
  }
`;
class FunnelProvider extends Component {
  state = {
    measures,
    connectors,
    funnel: {
      id: null,
      name: '',
      measure: _.find(measures, m => m.default),
      start: moment(),
      end: moment(),
      budget: 0,
      createdAt: moment().unix(),
      updatedAt: moment().unix(),
      saved: false,
      connected: [],
      objectifed: [],
      forecast: [],
      anom: []
    }
  };

  async componentDidMount() {
    console.log('start', this.props);
    try {
      await Auth.currentSession();
    } catch (err) {
      const { history } = this.props;
      console.log('4', this.props);
      history.push('/');
    }

    const { funnel } = this.props;

    if (funnel) {
      this.setState({ funnel });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { funnel } = this.props;
    if (nextProps.funnel !== funnel) {
      this.setState({ funnel: nextProps.funnel });
    }
  }

  setName = event => {
    const name = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      funnel: {
        ...prevState.funnel,
        name
      }
    }));
  };

  setBudget = event => {
    const budget = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      funnel: {
        ...prevState.funnel,
        budget
      }
    }));
  };

  setMeasure = measure => {
    this.setState(prevState => ({
      ...prevState,
      funnel: {
        ...prevState.funnel,
        measure
      }
    }));
  };

  setPeriod = (start, end) => {
    this.setState(prevState => ({
      ...prevState,
      funnel: {
        ...prevState.funnel,
        start,
        end
      }
    }));
  };

  setObjectif = (funnelId, value) => {
    this.setState(prevState => {
      const prev = _.filter(
        prevState.funnel.objectifed,
        o => o.funnelId !== funnelId
      );
      const objectifed = [...prev, { funnelId, value }];
      return {
        ...prevState,
        funnel: {
          ...prevState.funnel,
          objectifed
        }
      };
    });
  };

  setAnom = (funnelId, date, motifanom) => {
    this.setState(prevState => {
      const prev = _.filter(prevState.funnel.anom);
      const anom = [...prev, { funnelId, date, motifanom }];
      return {
        ...prevState,
        funnel: {
          ...prevState.funnel,
          anom
        }
      };
    });
  };

  setConnector = (funnelId, connector, data, childId) => {
    this.setState(prevState => {
      const prev = _.filter(prevState.funnel.connected, o => {
        return o.funnelId !== funnelId;
      });
      const fields = { funnelId, connector, data };
      const connected = [...prev, childId ? { ...fields, childId } : fields];
      return {
        ...prevState,
        funnel: {
          ...prevState.funnel,
          connected
        }
      };
    });
  };

  setForecast = forecast => {
    this.setState(prevState => {
      return {
        ...prevState,
        funnel: {
          ...prevState.funnel,
          forecast
        }
      };
    });
  };

  /* eslint no-param-reassign: ["error", { "props": false }] */
  setFetched = () => {
    this.setState(prevState => {
      const connected = _.map(prevState.funnel.connected, c => {
        c.fetched = true;
        return c;
      });
      return {
        ...prevState,
        funnel: {
          ...prevState.funnel,
          connected
        }
      };
    });
  };

  save = async saved => {
    const {
      id,
      name,
      measure,
      start,
      end,
      budget,
      connected,
      objectifed,
      forecast,
      anom
    } = this.state.funnel;
    const fields = {
      id,
      name,
      start,
      end,
      budget
    };

    if (id) {
      const { data } = await this.props.updateFunnel(
        saved
          ? {
              ...fields,
              connected: JSON.stringify(connected),
              objectifed: JSON.stringify(objectifed),
              forecast: JSON.stringify(forecast),
              anom: JSON.stringify(anom),
              saved
            }
          : fields
      );
      return data.updateFunnel;
    }
    const { data } = await this.props.createFunnel({
      name,
      measure: JSON.stringify(measure),
      start,
      end,
      budget,
      userId: 'demo@demo.fr'
    });
    return data.createFunnel;
  };

  delete = async deleted => {
    const { id } = this.state.funnel;
    const result = await this.props.deleteFunnel({
      id
    });
    if (!id) console.log(deleted);
    return result;
  };

  render() {
    return (
      <FunnelContext.Provider
        value={{
          funnel: this.state.funnel,
          measures: this.state.measures,
          connectors: this.state.connectors,
          connected: this.state.funnel.connected || [],
          objectifed: this.state.funnel.objectifed || [],
          saved: this.state.funnel.saved || false,
          forecast: this.state.funnel.forecast || [],
          anom: this.state.funnel.anom || [],
          setName: this.setName,
          setBudget: this.setBudget,
          setMeasure: this.setMeasure,
          setPeriod: this.setPeriod,
          setObjectif: this.setObjectif,
          setConnector: this.setConnector,
          setFetched: this.setFetched,
          setForecast: this.setForecast,
          setAnom: this.setAnom,
          save: this.save,
          delete: this.delete
        }}
      >
        {this.props.children}
      </FunnelContext.Provider>
    );
  }
}
FunnelProvider.propTypes = {
  children: PropTypes.any.isRequired,
  createFunnel: PropTypes.func.isRequired,
  updateFunnel: PropTypes.func.isRequired,
  deleteFunnel: PropTypes.func.isRequired,
  funnel: PropTypes.object,
  router: PropTypes.object.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

FunnelProvider.defaultProps = {
  funnel: null
};

const FunnelConsumer = FunnelContext.Consumer;

export default withRouter(
  compose(
    graphql(getFunnel, {
      skip: ({ router }) => !router.query.id,
      options: ({ router }) => ({
        variables: {
          id: router.query.id
        }
      }),
      props: props => {
        console.log(props);
        const funnel = props.data.getFunnel;
        if (!funnel) {
          return { ...props, funnel: null };
        }
        const fields = [
          'measure',
          'connected',
          'objectifed',
          'forecast',
          'anom'
        ];
        _.map(funnel, (v, k) => {
          if (fields.includes(k) && _.isString(v)) {
            funnel[k] = JSON.parse(v);
          }
        });
        return {
          ...props,
          funnel
        };
      }
    }),
    graphqlMutation(createFunnel, getFunnel, 'Funnel'),
    graphqlMutation(updateFunnel, getFunnel, 'Funnel'),
    graphqlMutation(deleteFunnel, getFunnel, 'Funnel')
  )(FunnelProvider)
);
export { FunnelConsumer, FunnelContext };

export function withFunnel(AnyComponent) {
  return function WrapperComponent(props) {
    return (
      <FunnelProvider>
        <AnyComponent {...props} />
      </FunnelProvider>
    );
  };
}
