import React from 'react';
import PropTypes from 'prop-types';
import { ImgContainer } from './style';

function ConnectorImg(props) {
  const { element, index, connected, handleConnector } = props;

  return (
    /* eslint-disable react/jsx-no-bind */
    <ImgContainer
      className={`${connected[index] ? 'valid' : ''}`}
      key={element}
      onClick={handleConnector.bind(this, element)}
      onKeyPress={handleConnector.bind(this, element)}
      role="button"
      tabIndex={index}
    >
      {connected[index] ? (
        <img
          className="valid_icon"
          src="/assets/img/picto_checked.svg"
          alt=""
        />
      ) : (
        ''
      )}
      <div className="connectorContainer">
        <img src={element.src} alt={element.name} />
        <p className="connector_title">{element.name}</p>
      </div>
    </ImgContainer>
  );
}

ConnectorImg.propTypes = {
  element: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  connected: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleConnector: PropTypes.func.isRequired
};
export default ConnectorImg;
