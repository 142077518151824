import React from 'react';
import PropTypes from 'prop-types';
import constants from 'utils/constants';
import Section from './index';
import { BaseSectionTableWrapper } from './style';

const { colors } = constants;

function SectionTable(props) {
  const { role, children, url } = props;
  const bgColor = colors[role] || 'transparent';
  return (
    <Section
      {...props}
      bgColor={bgColor}
      containerProps={{ maxWidth: false }}
      width="100%"
      minHeight="100vh"
      display="block"
      padding="90px 3em 3em 3em"
      boxSizing="border-box"
      position="relative"
      borderradius="0"
    >
      {children}
      {url && (
        <div className="plus">
          <a href={url}>
            <img src="assets/img/icons/more.svg" alt="plus icone" />
          </a>
        </div>
      )}
    </Section>
  );
}
SectionTable.defaultProps = {
  role: '',
  url: ''
};
SectionTable.propTypes = {
  role: PropTypes.string,
  children: PropTypes.node.isRequired,
  url: PropTypes.string
};

export const SectionTableWrapper = props => (
  <BaseSectionTableWrapper {...props} />
);

export default SectionTable;
