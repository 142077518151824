import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import History from 'components/History';
import HistoryFunnelContainer from 'Funnel/containers/HistoryFunnelContainer';

/**
 * @component
 * TODO:: creer les composants Loading
 */

function HistoryContainer(props) {
  const {
    match: { params }
  } = props;
  const { panel } = params;
  return (
    <History panel={panel}>
      {panel === 'funnel' && <HistoryFunnelContainer panel={panel} />}
    </History>
  );
}
HistoryContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(HistoryContainer);
