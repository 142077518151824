import React from 'react';
import { Auth } from 'aws-amplify';
import Cookie from 'js-cookie';
import { COGNITO_ID_TOKEN_COOKIE_NAME } from 'utils/api/aws/aws-export';

const UserContext = React.createContext();
const requestStates = {
  LOADING: 'loading',
  LOADED: 'loaded',
  NOT_LAUNCH: 'not_launch'
};

const { LOADING, LOADED, NOT_LAUNCH } = requestStates;

/**
 * UserProvider
 * @component
 */
export default function UserProvider(props) {
  const [user, setUser] = React.useState({});
  const [requestState, setRequestState] = React.useState(LOADING);
  const fetchUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
      setRequestState(LOADED);
    } catch (e) {
      setRequestState(NOT_LAUNCH);
    }
  };
  React.useEffect(() => {
    fetchUser();
  }, []);
  const signIn = async ({ email, password }) => {
    try {
      const signinUser = await Auth.signIn(email, password);
      setUser(signinUser);
      if (signinUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // const { requiredAttributes } = user.challengeParam;
        await Auth.completeNewPassword(signinUser, 'password');
      }
      if (!signinUser.attributes || !signinUser.attributes['custom:role']) {
        await Auth.updateUserAttributes(signinUser, {
          'custom:role': 'USER',
          'custom:nickname': 'Demo'
        });
      }
      const idToken = signinUser.signInUserSession.accessToken.jwtToken;
      Cookie.set(COGNITO_ID_TOKEN_COOKIE_NAME, idToken, {
        secure: false,
        expires: 1 / 48
      });
      setRequestState(LOADED);
      return true;
    } catch (err) {
      setRequestState(NOT_LAUNCH);
      return false;
    }
  };

  const updateUser = async () => {
    try {
      await Auth.updateUserAttributes(user, {
        'custom:role': 'ADMIN',
        'custom:nickname': 'William'
      });
    } catch (err) {
      console.log('err update user (userProvider)', err);
    }
  };
  const changePassword = async password => {
    const attributes = {};
    await Auth.completeNewPassword(user, password, attributes);
  };

  const logOut = async () => {
    setUser({});
    try {
      await Auth.signOut();
      setRequestState(NOT_LAUNCH);
    } catch (err) {
      console.log('err sign out  user (userProvider)', err);
    }
  };
  const value = {
    changePassword,
    isAuthenticated: !!user.username,
    isLoadingAuth: requestState === LOADING,
    logOut,
    signIn,
    updateUser,
    user
  };
  return <UserContext.Provider {...props} value={value} />;
}

export const useUser = () => {
  const context = React.useContext(UserContext);
  if (!context) throw new Error('UserContext must be in UserProvider');
  return context;
};
