import constants from 'utils/constants';
import { FilterSimple, FilterComposed } from './constructor';

const { insightSegmentTypes } = constants;
const { OR, AND, SIMPLE, EXCLUDE } = insightSegmentTypes;

/**
 * formatOperators
 * @constructor
 * @desc format operators to send to the API (graphql)
 *
 */
export default function formatOperators(operatorsGroups) {
  const filters = [];
  Object.values(operatorsGroups).forEach(group => {
    // here we are in group (eq operatorsGroups[categoryID])
    const groupfilter = [];
    Object.values(group)
      .filter(subGroup => subGroup.values.length)
      .forEach(subGroup => {
        // Hhere we are in subGroup (eq operatorsGroups[categoryID][subCategoryID]
        // item is a value (@see constructors.js -> DefaultValue)
        const filter = subGroup.values.reduce((acc, item) => {
          // Here we are in subGroup.values
          const filterItem = new FilterSimple(item);
          if (!acc) {
            // This is the first item :: create one filter
            // then return it for create composed filter (see below)
            return filterItem;
          }
          if (!acc.filters) {
            // When item is the second element :: start create a composed filter
            // then return it for next item (see below)
            return new FilterComposed({
              type: item.type,
              filters: [acc, filterItem]
            });
          }
          if (item.type.NAME === acc.type) {
            // If the 3rd (or more) item has the same type than
            // the current conposed filter, simply add it to the composed filter
            // values then return the completed composed filter
            return { ...acc, filters: [...acc.filters, filterItem] };
          }
          // Here we are in the case of 3rd item (or more) has not the same
          // type than the current composed filter
          const newAcc = { ...acc };
          if (item.type.NAME === AND.NAME) {
            // Last type is OR and current type is AND ::
            // replace the last Filter in acc by a composed filter of type 'AND'
            // containing the last filter and the incomming filter
            const lastItem = newAcc.filters.pop();
            newAcc.filters.push({
              type: AND,
              filters: [lastItem, filterItem]
            });
            return newAcc;
          }
          // Last type is AND, current type is OR
          // then create a composed Filter containing acc and incomming filter
          return new FilterComposed({
            type: OR,
            filters: [acc, filterItem]
          });
        }, null);
        groupfilter.push({ type: subGroup.type, filter });
      });
    const formatedGroupFilter = groupfilter.reduce((acc, groupItem) => {
      console.log('sub', groupItem);
      // subGroup logic
      if (!acc) return [groupItem.filter];
      const newAcc = [...acc];
      if (groupItem.type.NAME === AND.NAME) return [...acc, groupItem.filter];
      const lastItem = newAcc.pop();
      // pop modify the array
      if (lastItem.type === OR.NAME) {
        lastItem.filters.push(groupItem.filter);
        return [...acc, lastItem];
      }
      const newComposedGroup = new FilterComposed({
        filters: [lastItem, groupItem.filter]
      });
      return newComposedGroup;
    }, null);
    console.log('formatedGroupFilter', formatedGroupFilter);
    if (formatedGroupFilter) filters.push(formatedGroupFilter);
  });
  console.log('filters', filters);
  return filters.flat();
}

export const checkHasSubGroup = (params, ref) => {
  const { categoryID, subCategoryID } = params;
  if (!ref[categoryID]) return false;
  if (!ref[categoryID][subCategoryID]) return false;
  return true;
};

/**
 * reverseOperator
 * @param [Object] type
 * @return Object
 *
 */
export const reverseOperator = type => {
  if (type.NAME === OR.NAME) return AND;
  if (type.NAME === AND.NAME) return OR;
  if (type.NAME === SIMPLE.NAME) return EXCLUDE;
  return SIMPLE;
};
