import React from 'react';

import IndicatorsWrapper from './style';

/**
 * IndicatorFunnel
 * @component
 *
 */
function IndicatorsFunnel(props) {
  return <IndicatorsWrapper {...props} />;
}

export default IndicatorsFunnel;
