import styled from 'styled-components';
// import constants from 'utils/constants';
//
// const { colors } = constants;

/**
 * @component
 *
 */
const BaseOverview = styled.div`
  display: flex;
  justif-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default BaseOverview;
