import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Grid from 'components/Grid';
import Button from 'components/Button';
import constants from 'utils/constants';
import CSVReader from 'react-csv-reader';

const { colors } = constants;

const ConnectorWrapper = props => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    item
    xs={12}
    {...props}
  />
);

export const ConnectorButton = styled(Button)`
  && {
    color: #fff;
    width: auto;
    margin: 15px 0 15px 30px;
    border-radius: 4px;
    background-color: ${colors.funnel};
    &:hover {
      background-color: ${colors.funnel_dark};
    }
    &:disabled {
      background-color: ${colors.secondary};
    }
  }
  svg {
    margin-right: 10px;
    min-width: 26px;
  }
`;

export const ConnectorSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: transparent;
  padding: 0px 25px;
`;

export const AddConnectorText = styled.strong`
  @media (max-width: 1290px) {
    text-align: center;
    display: block;
    width: 100%;
  }
`;

const modalStyle = {
  content: {
    position: 'absolute',
    background: '#fff',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '70%',
    border: `1px solid ${colors.funnel}`,
    outline: 'none',
    padding: '20px'
  }
};

export const ConnectorModal = props => <Modal style={modalStyle} {...props} />;

export const ConnectorSVG = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="white" />
    <path
      d="M21 14.1429H14.1429V21H11.8571V14.1429H5V11.8571H11.8571V5H14.1429V11.8571H21V14.1429Z"
      fill="grey"
    />
  </svg>
);

export const Content = styled.div`
  position: relative;
  display: flex;
  border: none;
  background: none;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 15px;
`;

export const Header = styled.div`
  margin: auto;
  padding: 10px;
  height: 60px;
  width: 300px;
`;

export const DoneButton = styled.button`
  margin: 40px auto;
  width: 300px;
  height: 44px;
  text-align: center;
  color: #3e6de7;
  line-height: 40px;
  border: 2px solid #4e85ec;
  border-radius: 22px;
  font-weight: normal;
  text-transform: uppercase;
  display: ${props => (!props.connector ? 'block' : 'none')};
`;
export const ConnectorContainer = styled.div`
  display: ${props => (!props.connector ? 'block' : 'none')};
  margin: auto;
`;

export const AddConnectorTitle = styled.div`
  display: inline-block;
  padding: 27px;
`;

export const CSVstyle = styled(CSVReader)`
  .csv-reader-input form-control {
    text-align: center;
    font-size: 18px;
  }
  .form-control-file {
    padding: 50px 0;
    display: flex;
    margin: auto;
    width: 180px;
  }
  .label {
    background-color: #06cb52;
    color: white;
    border-radius: 100px;
    padding: 10px;
    display: inline-block;
    margin: 35px;
  }
`;

export default ConnectorWrapper;
