import React from 'react';
import PropTypes from 'prop-types';

import { BaseListItem, BaseListTitle } from './style';

/**
 * @component
 *
 */
function ArrowListFunnel(props) {
  const { children, value, onClick } = props;
  return (
    <BaseListItem onClick={onClick}>
      <div>
        {children}
        <b>{value}</b>
      </div>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="13" cy="13" r="13" fill="white" />
        <path
          d="M9.09091 7.64549L14.5975 13.0005L9.09091 18.3555L10.7862 20.0005L18 13.0005L10.7862 6.00049L9.09091 7.64549Z"
          fill="#686B6F"
        />
      </svg>
    </BaseListItem>
  );
}
ArrowListFunnel.defaultProps = {
  children: '',
  value: ''
};

ArrowListFunnel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.node,
  onClick: PropTypes.func.isRequired
};

/**
 * @component
 *
 */
export const ArrowListTitle = props => {
  return <BaseListTitle {...props} />;
};

export default ArrowListFunnel;
