const featureContent = {
  data: {
    name: 'data',
    img: '../../assets/img/detail-offer/mockup-data.png',
    description:
      'Enrichissez les données de vos DMP et CRM à partir de notre base de donnée interne comprenant 40 millions de contacts (Cookies, email, coordonnées, ID devices) et segmentés selon plus de 1 000 critères.',
    title:
      'Enrichissez vos données de CRM et DMP pour en savoir plus sur vos clients et améliorer vos campagnes',
    paragraphes: [
      'Elargissez vos moyens d’action en établissant une correspondance entre vos différents identifiants digitaux grâce à notre système d’onboarding (Matching email / adresse physique / cookies / Device ID).',
      'Votre data devient ainsi plus riche et votre connaissance client plus élevée.',
      'Si vous n’avez pas encore de base de données interne vous pouvez directement créer un segment correspondant à votre activité et l’activer sur différents canaux rapidement et simplement sur la plateforme.'
    ],
    link:
      'http://rms.redpillparis.com:3000/audience/new/Q2F0ZWdvcnk6QzFBMDY5NjAtMzUyQS05Q0FBLTkxNTYtNEYzMkVGMzU3NkQz'
  },
  insight: {
    name: 'insight',
    img: '../../assets/img/detail-offer/mockup-insight.png',
    description:
      'Explorez tous les critères de segmentation disponibles grâce à notre puissante base de données enrichie de 40 000 000 contacts actionnables et mesurables selon 1 000 critères mis à jour quotidiennement.',
    title:
      'Découvrez réellement qui est votre cible et comparez-là avec votre audience actuelle',
    paragraphes: [
      'Créez autant de segments que vous le souhaiter et activez les directement sur la plateforme en fonction de vos objectifs.',
      'Analysez en détail les profils de vos consommateurs et de vos visiteurs pour mieux les comprendre.',
      'Exportez vos rapports d’étude et partagez les au sein de votre équipe marketing.',
      'Si les données disponibles ne répondent pas à tous vos besoins, interrogez vos consommateurs en créant vos propres questionnaires et sondages online pour approfondir vos connaissances, vous pouvez même contacter un panel plus élargi.',
      'Les résultats sont triés et analysés pour venir enrichir votre base de données avec de nouveaux critères pertinents.'
    ],
    link:
      'http://rms.redpillparis.com:3000/audience/new/Q2F0ZWdvcnk6QzFBMDY5NjAtMzUyQS05Q0FBLTkxNTYtNEYzMkVGMzU3NkQz'
  },
  activation: {
    name: 'activation',
    img: '../../assets/img/detail-offer/mockup-activation.png',
    description:
      'Définissez simplement un plan d’activation media omnicanal pertinent en fonction de vos objectifs',
    title:
      'Réalisez des campagnes marketing très ciblées ou plus mass média et améliorer votre ROI',
    paragraphes: [
      'Notre simulateur vous permet de définir les performances intégrées attendues et d’optimiser votre plan de contact en fonction de vos moyens et objectifs.',
      'Partez de ce qui vous semble naturel, nos algorithmes vous accompagnent dans la démarche et vous suggèrent les meilleures optimisations possibles tout en respectant votre budget.',
      'Notre système de segmentation innovant vous permet d’acheter l’espace média moins cher et d’être présent où les autres ne sont pas.',
      'Grâce à nos partenariats avec les plus grande régies et médias, activez à la fois vos cibles online mais également offline et multipliez vos point de contacts.',
      'Une équipe d’expert est également à votre disposition pour vous accompagner dans votre stratégie de communication.'
    ],
    link:
      'http://rms.redpillparis.com:3000/audience/new/Q2F0ZWdvcnk6QzFBMDY5NjAtMzUyQS05Q0FBLTkxNTYtNEYzMkVGMzU3NkQz'
  },
  funnel: {
    name: 'funnel',
    img: '../../assets/img/detail-offer/mockup-funnel.png',
    description:
      'Ne perdez pas des heures à devoir paramétrer tous les détails, le processus de setup a été simplifié au maximum et vous n’avez plus qu’à vous laissez-vous guider.',
    title:
      'Comprenez, optimisez et anticipez vos performances quels que soient vos objectifs. Effort minimum. Suivi et compréhension maximum.',
    paragraphes: [
      'Nos stratèges et ingénieurs ont conçu 10 funnels de performance correspondant à la grande majorité des objectifs des marques, de la notoriété à l’acquisition client en passant par les ventes en ligne ou en magasin.',
      'Une connexion dynamique à vos sources de données pour faire ressortir uniquement les Insights qui vous intéresse.',
      'Un moteur d’intelligence artificielle pour une analyse fine et adaptée en fonction de votre industrie et les prévisions de vos performances futures.',
      'Vous êtes alerté sur les anomalies de vos campagnes et bénéficiez en temps réel de suggestions avec des actions concrètes à mettre en place pour améliorer vos performances.',
      'Et tout cela, chaque jour, tous les jours, pour toutes vos campagnes quelle que soit leur importance.'
    ]
  }
};

export default featureContent;
