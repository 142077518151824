import constants from 'utils/constants';

const { insightSegmentTypes } = constants;
const { OR, SIMPLE, EXCLUDE, AND } = insightSegmentTypes;

/**
 * DefaultOperator
 * @constructor
 * @param {Object} params
 *
 */
export default function DefaultOperator(params) {
  this.position = params.position || 0;
  this.type = OR;
}

/**
 * DefaultOperatorValue
 * @constructor
 * @param {Object} params
 */
export function DefaultSelected(params) {
  this.exclude = false;
  this.id = params.id;
  this.name = params.name;
  this.position = params.position;
  this.type = OR;
}

/**
 * DefaultSubGroup
 * @constructor
 *
 */
export function DefaultSubGroup() {
  this.type = AND;
  this.values = [];
}

/**
 * FilterSimple
 * @desc one segment
 * @see CriteriaContainer
 * @param [string] id
 */
export function FilterSimple({ id, exclude }) {
  this.type = exclude ? EXCLUDE.NAME : SIMPLE.NAME;
  this.flags = [];
  this.segmentID = id;
}

/**
 * FilterComposed
 * @desc filters is a array of FilterSimple (defined above)
 * @param [string] type
 * @param [array] filters
 * @return constructors
 *
 */
export function FilterComposed(params = {}) {
  const { type, filters } = params;
  this.type = type.NAME || OR.NAME;
  this.filters = filters || [];
}
