import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

const BaseInput = styled.input`
  width: 130px;
  height: 36px;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid ${colors.funnel};
  outline: none;
  color: ${colors.light};
  padding: 10px;
  background-color: ${colors.primary};
  font-family: inherit;
  border-radius: 4px;
  margin: 0;
`;

export default BaseInput;
