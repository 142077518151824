import React from 'react';
import PropTypes from 'prop-types';

import { useUser } from 'contexts/UserContext';
import Header from 'components/Header';

/**
 * @component
 * @param {children} param0
 */
function HeaderContainer({ children }) {
  const { isAuthenticated } = useUser();
  return <Header isConnected={isAuthenticated}>{children}</Header>;
}

HeaderContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default HeaderContainer;
