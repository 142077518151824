import styled from 'styled-components';
import { Button } from '@material-ui/core';
import k from 'utils/constants';

const { colors } = k;

/**
 * @params bgColor
 * @params color
 * @params width
 * @params disableHover
 *
 */
const Buttons = styled(Button)`
  && {
    background-color: ${({ bgcolor }) => bgcolor || colors.secondary};
    color: ${({ color }) => color || colors.secondary};
    width: ${({ width }) => width || 'auto'};
    border-radius: 50px;
    padding: 6px 15px;
  }
  &&:hover {
    background-color: ${({ bgcolor, disableHover }) =>
      disableHover && (bgcolor || colors.secondary)};
  }
  white-space: nowrap;
`;

export default Buttons;
