import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import FeatureDetails from 'components/FeatureDetails';
import { LinkButton } from 'components/FeatureDetails/style';
import Grid from 'components/Grid';
import Image from 'components/Image';
import Text from 'components/Text';
import constants from 'utils/constants';

const { featureContent, colors, routes } = constants;

/**
 *
 * FeatureDetailsContainer
 * @desc affiche les features sur une page
 * @component
 * @param {Object} props
 *
 */
function FeatureDetailsContainer(props) {
  const {
    match: { params },
    history
  } = props;
  const { feature } = params;
  const infos = featureContent[feature];
  if (!infos) {
    // TODO: alert an error message
    console.log("mettre un message d'error");
    history.push(routes.DEFAULT);
  }
  return !infos ? null : (
    <FeatureDetails>
      <Grid item sm={12} md={6} padding="40px">
        <Image width="100%" padding="0 30px" src={infos.img} alt="mockup" />
        <Text textAlign="center" fontSize="15px" fontWeight="700">
          {infos.description}
        </Text>
      </Grid>
      <Grid item sm={12} md={6}>
        <div>
          {/* Infos.title */}
          <h1>{infos.title}</h1>
          {/* Infos.text à faire en .map pour récuperer le tableau */}
          {infos.paragraphes.map(text => (
            <p>{text}</p>
          ))}
        </div>
        <LinkButton
          bgcolor={colors.light}
          color={colors[infos.name]}
          width="200px"
          margin="auto"
          textAlign="center"
          display="inline-block"
          href={infos.link}
        >
          {infos.name}
        </LinkButton>
      </Grid>
    </FeatureDetails>
  );
}

FeatureDetailsContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired // injected by withRouter
};

export default withRouter(FeatureDetailsContainer);
