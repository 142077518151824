import _ from 'lodash';
import moment from 'utils/moment';

export default {
  getsynthese(connected, id, filter) {
    const csvdata = [
      [''],
      ['Synthese de la periode'],
      ['', 'volume', 'kpi moyen', 'Top', 'Flop']
    ];
    for (let i = 0; i < 4; i += 1) {
      const connector = _.find(connected, c => c.funnelId === id[i]);
      const hasData = connector && connector.data.length;
      const connectorData = hasData ? _.filter(connector.data, filter) : [];
      const total = hasData ? _.sumBy(connectorData, c => c.value) : 0;
      const reeltotal = [];
      connectorData.map(d => {
        reeltotal.push(d.cost);
        return d.cost;
      });
      let reel = reeltotal.reduce((p, c, l, a) => p + c / a.length, 0);
      let top = 0;
      let flop = 0;
      if (hasData) {
        const min = _.minBy(connectorData, c => c.cost);
        const max = _.maxBy(connectorData, c => c.cost);
        top = min ? min.cost : 0;
        flop = max ? max.cost : 0;
        if (i === 0) {
          reel *= 1000;
          top *= 1000;
          flop *= 1000;
        }
      }
      csvdata.push([
        id[i],
        total.toFixed(2),
        reel.toFixed(2),
        top.toFixed(2),
        flop.toFixed(2)
      ]);
    }
    return csvdata;
  },

  getalldata(connected, id, funnel) {
    const csvdata = [['Toutes les donnees de la periode']];
    const cdate = [''];
    const cpm = [id[0]];
    const cpv = [id[1]];
    const cpd = [id[2]];
    const cpi = [id[3]];
    connected.map(item => {
      item.data.map(i => {
        const date = moment(i.date);
        if (date >= moment(funnel.start) && date <= moment(funnel.end)) {
          if (item.funnelId === id[0]) {
            cpm.push(i.graph.toFixed(2));
            cdate.push(date.format('YYYY-MM-DD'));
          }
          if (item.funnelId === id[1]) cpv.push(i.graph.toFixed(2));
          if (item.funnelId === id[2]) cpd.push(i.graph.toFixed(2));
          if (item.funnelId === id[3]) cpi.push(i.graph.toFixed(2));
        }
        return -1;
      });
      return 0;
    });
    csvdata.push([cdate.join(';')]);
    csvdata.push([cpm.join(';')]);
    csvdata.push([cpv.join(';')]);
    csvdata.push([cpd.join(';')]);
    csvdata.push([cpi.join(';')]);
    return csvdata;
  },

  getanalyse(connected, id, filter, funnel, name, objectifed, array) {
    const csvdata = [
      [''],
      ['Analyses'],
      ['', 'Objectif', 'Reel', 'Nouvel Objectif', 'Impact']
    ];
    if (objectifed.length <= 3) {
      csvdata.push([
        '',
        'no goal entered',
        'no goal entered',
        'no goal entered',
        'no goal entered'
      ]);
      return csvdata;
    }
    for (let j = 0; j < 4; j += 1) {
      const funnelIndex = _.findIndex(
        funnel.measure.funnels,
        f => f.id === id[j]
      );
      const objectif = _.find(objectifed, c => c.funnelId === id[j]);
      const value = parseFloat(objectif.value);
      const connector = _.find(connected, c => c.funnelId === id[j]);
      const connectorData = _.filter(connector.data, filter);
      const total = connector ? _.sumBy(connectorData, c => c.value) : 0;
      const budget = connector ? _.sumBy(connectorData, c => c.budget) : 0;
      let reel = parseFloat(budget / total);
      if (j === 0) {
        reel *= 1000;
      }
      reel = parseFloat(reel.toFixed(2));
      let reelParent = 0;
      let objectifRate = 0;
      if (funnelIndex > 0) {
        const parentFunnel = funnel.measure.funnels[funnelIndex - 1];
        const parent = _.find(connected, c => c.funnelId === parentFunnel.id);
        const parentObjectif = _.find(
          objectifed,
          c => c.funnelId === parentFunnel.id
        );
        let parentValue = parseFloat(parentObjectif.value);
        const parentData = _.filter(parent.data, filter);
        const totalParent = connector ? _.sumBy(parentData, c => c.value) : 0;
        const budgetParent = connector ? _.sumBy(parentData, c => c.budget) : 0;
        reelParent = parseFloat(budgetParent / totalParent);
        if (parentFunnel.per !== 1) {
          parentValue /= parentFunnel.per;
        }
        objectifRate = ((parentValue / value) * 100).toFixed();
      }
      let newObjectif = 0;
      if (reel - value > reel / 3) {
        newObjectif = reel - (reel * 10) / 100;
      } else if (reel <= value) {
        newObjectif = reel - (reel * 5) / 100;
      } else {
        newObjectif = _.mean([reel, value]);
      }
      let t = 1;
      for (let i = 0; i < 3; i += 1) {
        if (i === 0) t *= parseFloat(array[i]);
      }
      const t1 = reel / t;
      const t2 = newObjectif / t;
      const t3 = ((t1 - t2) / t1) * 100;
      const affichet3 = t3.toFixed(2);
      const { title } = funnel.measure.funnels[funnelIndex];
      if (j === 0) {
        csvdata.push([
          title,
          value.toFixed(2),
          reel.toFixed(2),
          newObjectif.toFixed(2),
          affichet3
        ]);
      } else {
        csvdata.push([
          title,
          objectifRate,
          ((reelParent / reel) * 100).toFixed(2),
          ((reelParent / newObjectif) * 100).toFixed(2),
          affichet3
        ]);
      }
    }
    return csvdata;
  },

  getbaseline(connected, id, filter) {
    const csvdata = [
      [''],
      ['Baseline'],
      ['', 'Volume total', 'kpi moyen', 'Top', 'Flop']
    ];
    const connector = _.find(connected, c => c.funnelId === id[4]);
    const hasData = connector && connector.data.length;
    const connectorData = hasData ? _.filter(connector.data, filter) : [];
    const total = hasData ? _.sumBy(connectorData, c => c.value) : 0;
    const totalB = hasData ? _.sumBy(connectorData, c => c.display) : 0;
    const budget = hasData ? _.sumBy(connectorData, c => c.budget) : 0;
    const reel = parseFloat(budget / total);
    let top = 0;
    let flop = 0;
    if (hasData) {
      const min = _.minBy(connectorData, c => c.cost);
      const max = _.maxBy(connectorData, c => c.cost);
      top = min ? min.cost : 0;
      flop = max ? max.cost : 0;
    }
    csvdata.push([
      'traffic naturel',
      totalB.toFixed(2),
      reel.toFixed(2),
      top.toFixed(2),
      flop.toFixed(2)
    ]);

    return csvdata;
  },

  dl(connected, id, filter, funnel, name, objectifed, array) {
    const csvsynthese = this.getsynthese(connected, id, filter);
    const csvalldata = this.getalldata(connected, id, funnel);
    const csvanalyse = this.getanalyse(
      connected,
      id,
      filter,
      funnel,
      name,
      objectifed,
      array
    );
    const csvbaseline = this.getbaseline(connected, id, filter);
    const csvRow = [];

    for (let j = 0; j < csvalldata.length; j += 1)
      csvRow.push(csvalldata[j].join(';'));
    for (let j = 0; j < csvsynthese.length; j += 1)
      csvRow.push(csvsynthese[j].join(';'));
    for (let j = 0; j < csvanalyse.length; j += 1)
      csvRow.push(csvanalyse[j].join(';'));
    for (let j = 0; j < csvbaseline.length; j += 1)
      csvRow.push(csvbaseline[j].join(';'));

    const csvString = csvRow.join('\n');
    const a = document.createElement('a');
    a.download = 'FunnelReports.csv';
    a.href = `data:text/csv;charset=utf-8,${csvString}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
};
