import React from 'react';
import styled from 'styled-components';
import Title from 'components/Title';
import constants from 'utils/constants';

const { colors } = constants;

const BaseTitleFunnel = styled(Title)`
  color: ${({ lightColor }) => (lightColor ? colors.light : 'currentcolor')};
  margin: ${({ big }) => (big ? '17px auto 0px' : '10px auto')};
  text-transform: uppercase;
`;

export default props => <BaseTitleFunnel medium {...props} />;
