import React from 'react';
import BaseContent, { Title } from './style';

/**
 * ResultSavingInsight
 * @component
 *
 */

function SurveyModalInsight() {
  return (
    <BaseContent>
      <Title>SONDAGE AD-HOC :</Title>
      <div className="description">{`Vous pouvez créer et envoyer un sondage personnalisé auprès d’un panel de consommateurs correspondant au segment de profils souhaité.
      ${'\n'}Vous serez averti dès la réception des résultats et votre segment initial sera enrichi avec les réponses du sondage.
      ${'\n\n'}Souhaitez-vous réaliser un sondage ? 
      `}</div>
    </BaseContent>
  );
}

export default SurveyModalInsight;
