import React from 'react';
// import PropTypes from 'prop-types'

import CategoriesContainer from 'Insight/containers/CategoriesContainer';
import ResultContainer from 'Insight/containers/ResultContainer';
import OperatorContainer from 'Insight/containers/OperatorContainer';
import CriteriaContainer from 'Insight/containers/CriteriaContainer';
import LayoutInsight from 'Insight/components/LayoutInsight';

/**
 * AppInsight
 * @component
 *
 */
function AppInsight() {
  return (
    <LayoutInsight.Body>
      <ResultContainer />
      <LayoutInsight.Row>
        <LayoutInsight.ColumnLeft>
          <CategoriesContainer />
          <OperatorContainer />
        </LayoutInsight.ColumnLeft>
        <LayoutInsight.ColumnRight>
          <CriteriaContainer />
        </LayoutInsight.ColumnRight>
      </LayoutInsight.Row>
    </LayoutInsight.Body>
  );
}
AppInsight.defaultProps = {};
AppInsight.propTypes = {};

export default AppInsight;
