import styled from 'styled-components';
/*
import constants from 'utils/constants';

const { colors } = constants;
*/

/**
 * BaseConnector
 * @component
 *
 */
const BaseOperatedGroup = styled.div`
  margin: 4px 4px;
  padding: 0;
  display: inline-flex;
  width: auto;
`;
export default BaseOperatedGroup;
