import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import constants from 'utils/constants';
import CSVReader from 'react-csv-reader';

const { colors } = constants;

export const TableSynthesis = styled(Table)`
  && {
    margin-left: 2rem;
    width: calc(100% - 2rem);
    color: white;
    p,
    div,
    span,
    th,
    td {
      color: white;
      text-align: center;
    }
  }
`;

export const TableBodySynthesis = styled(TableBody)``;

export const TableHeadSynthesis = styled(TableHead)`
  th {
    font-weight: 600;
    font-size: 1rem;
  }
`;

export const TableCellSynthesis = styled(TableCell)`
  && {
    padding: 0.8rem;
    border-bottom: 1px solid #7e7ead;
  }
  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &&.action_td {
    border: none;
  }
  &&.b-all {
    border: 1px solid #7e7ead;
  }
  &&.b-left {
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid #7e7ead;
  }
  &&.b-right {
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-right: 1px solid #7e7ead;
  }
  &&.b-top {
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid #7e7ead;
  }
  &&.b-bottom {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #7e7ead;
  }
`;

export const TableRowSynthesis = styled(TableRow)``;

export const InputSynthesis = styled.input`
   {
    width: 3.6rem;
    border: 1px solid ${colors.funnel};
    border-radius: 0.5rem;
    height: 1.8rem;
    text-align: center;
    overflow: hidden;
    margin: 0.4rem;
    background-color: ${colors.primary};
    color: white;
  }
`;
// export const ImgContainer = styled()`
//   display: inline-block;

//   .connectorContainer {
//     padding: 30px 20px;
//     display: inline-block;
//     margin: auto;
//     text-align: center;
//   }
// `;

export const Image = styled.div``;

export const ConversionRateSpan = styled.span`
  display: ${({ display }) => (display ? 'block' : 'none')};
  margin-right: 0px;
  position: relative;
  top: -20px;
  width: 45px;
  height: 45px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #1e1e2f;
  font-weight: bold;
  text-align: center;
  border: 1px solid ${colors.funnel};
  font-size: 14px;
}`;

export const Content = styled.span`
  margin: auto;
`;

export const LinearBar = styled.div`
  border-radius: 7px;
  display: flex;
  background-color: ${colors.funnel};
  width: ${props => props.value}%;
  height: 30px;
`;

const chooseColor = (real, obj) => {
  if (!obj || parseFloat(real) === parseFloat(obj)) return 'white';
  return parseFloat(real) < parseFloat(obj) ? 'green' : 'red';
};

export const Reel = styled.strong`
  color: ${({ real, objectif }) => chooseColor(real, objectif)};
`;

export const CSVstyle = styled(CSVReader)`
  .csv-reader-input form-control {
    text-align: center;
    font-size: 18px;
  }
  .form-control-file {
    padding: 50px 0;
    display: flex;
    margin: auto;
    width: 180px;
  }
  .label {
    background-color: #06cb52;
    color: white;
    border-radius: 100px;
    padding: 10px;
    display: inline-block;
    margin: 35px;
  }
`;
