const measures = [
  {
    name: 'Visites sur site web',
    description:
      'Analysez l’impact de vos campagnes médias sur vos visites web et augmentez l’engagement de vos visiteurs',
    preview: '/static/img/preview/website-preview.png',
    details: ['Impressions', 'Visites Homepage', 'Engagement', 'Engagement +'],
    baseline: {
      wording: {
        total: 'Engagements intenses au total',
        advertising_title:
          "Nombre d'engagements intenses provenant de la campagne.",
        advertising_description:
          '(Ce sont les nouveaux engagements intenses obtenus grâce à votre campagne média)',
        baseline_title:
          "Nombre d'engagements intenses provenant du trafic natuel.",
        baseline_description:
          "(C'est le nombre de nouveaux engagements intenses que vous auriez eu sans campagne média)",
        kpi_title: 'CPEI hors baseline.',
        pki_description:
          '(Correspond au coût pour chaque nouvel engagement intense provenant de la campagne uniquement)'
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 12, 22, 23, 5],
        button: ['Profils']
      },
      {
        id: 'CPV',
        name: 'Visites Homepage',
        title: 'Taux de visite',
        average: 0.75,
        per: 1,
        target: true,
        childId: ['CPE', 'CPEI', 'CPEIHB'],
        forecast: ['FCPEI', 'FACPEI'],
        connectors: [11, 13, 5],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPE',
        name: 'Engagement',
        title: 'Taux d’engagement',
        average: 60,
        per: 1,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPEI',
        name: 'Engagement intense',
        title: 'Taux d’engagement intense',
        average: 87,
        per: 1,
        childId: 'CPEIHB',
        forecast: ['FCPEI', 'FACPEI'],
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPEIHB',
        name: 'Baseline',
        title: 'Baseline',
        average: 60,
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPEI'
      }
    ]
  },
  {
    default: true,
    name: 'Download application mobile',
    description:
      'Analysez l’impact de vos campagnes médias sur vos downloads et optimisez vos performances en diminuant votre CPI',
    preview: '/static/img/preview/dl-app-preview.png',
    details: [
      'Impressions',
      'Visites pages stores',
      'Downloads application',
      'Inscriptions'
    ],
    baseline: {
      wording: {
        total: 'inscriptions au total',
        advertising_title: "Nombre d'inscriptions provenant de la campagne.",
        advertising_description:
          '(Ce sont les nouveaux inscrits obtenus grâce à votre campagne média)',
        baseline_title: "Nombre d'inscriptions provenant du trafic natuel.",
        baseline_description:
          "(C'est le nombre de nouveaux inscrits que vous auriez eu sans campagne média)",
        kpi_title: 'CPI hors baseline.',
        pki_description:
          '(Correspond au coût pour chaque nouvel inscrit provenant de la campagne uniquement)'
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 12, 22, 23, 5],
        button: ['Profils']
      },
      {
        id: 'CPV',
        name: 'Visites Pages Stores',
        title: 'Taux de visite',
        average: 0.75,
        per: 1,
        connectors: [6, 7, 21, 5],
        childId: 'CPD',
        button: ['Profils', 'Look Alike']
      },
      {
        id: 'CPD',
        name: 'Downloads',
        title: 'Taux de download',
        average: 60,
        per: 1,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPI',
        name: 'Inscriptions',
        title: 'Taux d’inscription',
        average: 87,
        per: 1,
        childId: 'CPIHB',
        forecast: ['FCPI', 'FACPI'],
        target: true,
        connectors: [8, 9, 10, 5],
        button: ['Look Alike']
      },
      {
        id: 'CPIHB',
        name: 'Baseline',
        title: 'Baseline',
        average: 60,
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPI'
      }
    ]
  },
  {
    name: 'Ventes en ligne',
    description:
      'Analysez l’impact de vos campagnes médias sur vos ventes en ligne et augmentez votre C.A',
    preview: '/static/img/preview/sell-online-preview.png',
    details: [
      'Impressions',
      'Visites Homepage',
      'Paniers de vente initialisés',
      'Achats en ligne'
    ],
    baseline: {
      wording: {
        total: 'achats en ligne au total',
        advertising_title: "Nombre d'achats en ligne provenant de la campagne.",
        advertising_description:
          '(Ce sont les nouveaux achats en ligne obtenus grâce à votre campagne média)',
        baseline_title: "Nombre d'achats en ligne provenant du trafic natuel.",
        baseline_description:
          "(C'est le nombre de nouveaux achats en ligne que vous auriez eu sans campagne média)",
        kpi_title: 'CPAL hors baseline.',
        pki_description:
          '(Correspond au coût pour chaque nouvel achat en ligne provenant de la campagne uniquement)'
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 12, 22, 23, 5],
        button: ['Profils']
      },
      {
        id: 'CPV',
        name: 'Visites Homepage',
        title: 'Taux de visite',
        average: 0.75,
        per: 1,
        childId: 'CPPV',
        connectors: [11, 13, 5],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPPV',
        name: 'Paniers de ventes initialisés',
        title: 'Taux de ventes initialisées',
        average: 60,
        per: 1,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPAL',
        name: 'Achats en ligne',
        title: "Taux d'achat en ligne",
        average: 87,
        per: 1,
        childId: 'CPALHB',
        forecast: ['FCPAL', 'FACPAL'],
        target: true,
        connectors: [5],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPALHB',
        name: 'Baseline',
        title: 'Baseline',
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPAL'
      }
    ]
  },
  {
    name: 'Ventes en magasin',
    description:
      'Analysez l’impact de vos campagnes médias sur vos visites et ventes en magasin et augmentez votre C.A',
    preview: '/static/img/preview/sell-offline-preview.png',
    details: [
      'Impressions',
      'Visites Homepage',
      'Visites en magasin',
      'Achats en magasin'
    ],
    baseline: {
      wording: {
        total: 'achats en magasin au total',
        advertising_title:
          "Nombre d'achats en magasin provenant de la campagne.",
        advertising_description:
          '(Ce sont les nouveaux achats en magasin obtenus grâce à votre campagne média)',
        baseline_title:
          "Nombre d'achats en magasin provenant du trafic natuel.",
        baseline_description:
          "(C'est le nombre de nouveaux achats en magasin que vous auriez eu sans campagne média)",
        kpi_title: 'CPAM hors baseline.',
        pki_description:
          '(Correspond au coût pour chaque nouvel achat en magasin provenant de la campagne uniquement)'
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 12, 22, 23, 5],
        button: ['Profils']
      },
      {
        id: 'CPV',
        name: 'Visites Homepage',
        title: 'Taux de visite',
        average: 0.75,
        per: 1,
        connectors: [11, 13, 5],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPVM',
        name: 'Visites en magasin',
        title: 'Taux de visite magasin',
        average: 60,
        per: 1,
        connectors: [14, 5],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPVAM',
        name: 'Achats en magasin',
        title: "Taux d'achat en magasin",
        average: 87,
        per: 1,
        childId: 'CPVAMHB',
        forecast: ['FCPVAM', 'FACPVAM'],
        target: true,
        connectors: [15, 16, 17, 18, 19, 5],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPVAMHB',
        name: 'Baseline',
        title: 'Baseline',
        average: 60,
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPVAM'
      }
    ]
  },
  {
    name: 'Ventes omnicanales',
    description:
      'Analysez l’impact de vos campagnes médias sur vos ventes omnicanales et augmentez votre C.A',
    preview: '/static/img/preview/sell-total-preview.png',
    details: [
      'Impressions',
      'Visites Homepage',
      'Visites en magasin',
      'Achats totaux'
    ],
    baseline: {
      wording: {
        total: 'achats au total',
        advertising_title: "Nombre d'achats provenant de la campagne.",
        advertising_description:
          '(Ce sont les nouveaux achats obtenus grâce à votre campagne média)',
        baseline_title: "Nombre d'achats provenant du trafic natuel.",
        baseline_description:
          "(C'est le nombre de nouveaux achats que vous auriez eu sans campagne média)",
        kpi_title: 'CPAT hors baseline.',
        pki_description:
          '(Correspond au coût pour chaque nouvel achat provenant de la campagne uniquement)'
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 12, 22, 23, 5],
        button: ['Profils']
      },
      {
        id: 'CPV',
        name: 'Visites Homepage',
        title: 'Taux de visite',
        average: 0.75,
        per: 1,
        connectors: [11, 13, 5],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPVM',
        name: 'Visites en magasin',
        title: 'Taux de visite magasin',
        average: 60,
        per: 1,
        connectors: [14],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPAT',
        name: 'Achats totaux',
        title: "Taux d'achats totaux",
        average: 87,
        per: 1,
        childId: 'CPATHB',
        forecast: ['FCPAT', 'FACPAT'],
        target: true,
        connectors: [15, 16, 17, 18, 19, 5],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPATHB',
        name: 'Baseline',
        title: 'Baseline',
        average: 60,
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPAT'
      }
    ]
  },
  {
    name: 'Leads ou Inscrits',
    description:
      'Analysez l’impact de vos campagnes médias sur vos nouveaux leads et augmentez votre taux d’acquisition',
    preview: '/static/img/preview/leads-preview.png',
    details: ['Impressions', 'Visites Homepage', 'Engagements', 'Leads'],
    baseline: {
      wording: {
        total: 'leads ou inscrits au total',
        advertising_title:
          "Nombre de leads ou d'inscrits provenant de la campagne.",
        advertising_description:
          '(Ce sont les nouveaux leads ou inscrits obtenus grâce à votre campagne média)',
        baseline_title:
          "Nombre de leads ou d'inscrits provenant du trafic natuel.",
        baseline_description:
          "(C'est le nombre de nouveaux leads ou inscrits que vous auriez eu sans campagne média)",
        kpi_title: 'CPI hors baseline.',
        pki_description:
          '(Correspond au coût pour chaque nouveau lead ou inscrit provenant de la campagne uniquement)'
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 12, 22, 23, 5],
        button: ['Profils']
      },
      {
        id: 'CPV',
        name: 'Visites Homepage',
        title: 'Taux de visite',
        average: 0.75,
        per: 1,
        target: true,
        childId: ['CPL', 'CPI', 'CPIHB'],
        forecast: ['FCPI', 'FACPI'],
        connectors: [11, 13, 5],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPL',
        name: 'Inscrits',
        title: "Taux d'acquisition",
        average: 87,
        per: 1,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPI',
        name: 'Leads',
        title: 'Taux de leads',
        average: 60,
        per: 1,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPIHB',
        name: 'Baseline',
        title: 'Baseline',
        average: 60,
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPI'
      }
    ]
  },
  {
    name: 'Notoriété',
    description:
      "Analysez l’impact de vos campagnes médias sur votre notoriété et augmentez l'engagement envers votre marque",
    preview: '/static/img/preview/crm-offline-preview.png',
    details: [
      'Impressions publicitaires ciblées',
      'Notoriété assistée',
      'Notoriété spontanée',
      'Recommandation'
    ],
    baseline: {
      wording: {
        total: 'recommandations au total',
        advertising_title:
          'Nombre de recommandations provenant de la campagne.',
        advertising_description:
          '(Ce sont les nouvelles recommandations obtenues grâce à votre campagne média)',
        baseline_title: 'Nombre de recommandations provenant du trafic natuel.',
        baseline_description:
          "(C'est le nombre de nouvelles recommandations que vous auriez eu sans campagne média)",
        kpi_title: 'CPR hors baseline.',
        pki_description:
          '(Correspond au coût pour chaque nouvelle recommandation provenant de la campagne uniquement)'
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 12, 22, 23, 5],
        button: ['Profils']
      },
      {
        id: 'CPNA',
        name: 'Notoriété assistée',
        title: 'Taux de notoriété assistée',
        per: 1,
        cr: false,
        average: 2.6,
        childId: ['CPNS', 'CPR'],
        connectors: [20],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPNS',
        name: 'Notoriété spontanée',
        title: 'Taux de notoriété spontanée',
        per: 1,
        average: 2.6,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPR',
        name: 'Recommandation',
        title: 'Taux de recommandation',
        average: 2.6,
        per: 1,
        childId: 'CPRHB',
        forecast: ['FCPR', 'FACPR'],
        target: true,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPRHB',
        name: 'Baseline',
        title: 'Baseline',
        average: 60,
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPR'
      }
    ]
  },
  {
    name: "Intention d'achat",
    description:
      "Analysez l’impact de vos campagnes médias sur les intentions d'achats et augmentez son taux",
    preview: '/static/img/preview/notoriete-preview.png',
    details: [
      'Impressions',
      'Notoriété Assistée',
      'Notoriété Spontanée',
      "Intention d'achat"
    ],
    baseline: {
      wording: {
        total: "intentions d'achat au total",
        advertising_title:
          "Nombre d'intentions d'achat provenant de la campagne.",
        advertising_description:
          "(Ce sont les nouvelles intentions d'achat obtenues grâce à votre campagne média)",
        baseline_title:
          "Nombre d'intentions d'achat provenant du trafic natuel.",
        baseline_description:
          "(C'est le nombre de nouvelles intentions d'achat que vous auriez eu sans campagne média)",
        kpi_title: 'CPIA hors baseline.',
        pki_description:
          "(Correspond au coût pour chaque nouvel intention d'achat provenant de la campagne uniquement)"
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 12, 22, 23, 5],
        button: ['Profils']
      },
      {
        id: 'CPNA',
        name: 'Notoriété assistée',
        title: 'Taux de notoriété assistée',
        per: 1000,
        cr: false,
        average: 2.6,
        childId: ['CPNS', 'CPIA'],
        connectors: [20],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPNS',
        name: 'Notoriété spontanée',
        title: 'Taux de notoriété spontanée',
        per: 1,
        average: 2.6,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPIA',
        name: "Intention d'achat",
        title: "Taux d'intention d'achat",
        average: 2.6,
        per: 1,
        childId: 'CPIAHB',
        forecast: ['FCPIA', 'FACPIA'],
        target: true,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPIAHB',
        name: 'Baseline',
        title: 'Baseline',
        average: 60,
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPIA'
      }
    ]
  },
  {
    name: 'Visite en magasin',
    description:
      'Analysez l’impact de vos campagnes médias sur les intentions d’achats et augmentez son taux',
    preview: '/static/img/preview/intention-preview.png',
    details: [
      'Impressions',
      'Visites homepage',
      'Visites store locator',
      'Visite en magasin'
    ],
    baseline: {
      wording: {
        total: 'engagements consommateurs au total',
        advertising_title:
          "Nombre d'engagements consommateurs provenant de la campagne.",
        advertising_description:
          '(Ce sont les nouveaux engagements consommateurs obtenus grâce à votre campagne média)',
        baseline_title:
          "Nombre d'engagements consommateurs provenant du trafic natuel.",
        baseline_description:
          "(C'est le nombre de nouveaux engagements consommateurs que vous auriez eu sans campagne média)",
        kpi_title: 'CPEC hors baseline.',
        pki_description:
          '(Correspond au coût pour chaque nouvel engagement consommateur provenant de la campagne uniquement)'
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 22, 23, 5]
      },
      {
        id: 'CPV',
        name: 'Visites homepage',
        title: 'Taux de visite',
        per: 1000,
        cr: false,
        average: 2.6,
        childId: 'CPVS',
        connectors: [11, 13, 5]
      },
      {
        id: 'CPVS',
        name: 'Visites store locator',
        title: 'Taux de visite store locator',
        per: 1,
        average: 2.6,
        connectors: []
      },
      {
        id: 'CPVM',
        name: 'Visite en magasin',
        title: 'Taux de visite en magasin',
        per: 1,
        average: 2.6,
        connectors: [5]
      },
      {
        id: 'CPVMHB',
        name: 'Baseline',
        title: 'Baseline',
        average: 60,
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPVM'
      }
    ]
  },
  {
    name: 'Fan Facebook',
    description:
      'Analysez l’impact de vos campagnes médias sur l’activité de votre page Facebook et optimisez votre influence',
    preview: '/static/img/preview/facebook-preview.png',
    details: [
      'Impresions',
      'Visites page Facebook',
      'Nouveaux fans',
      'Engagement'
    ],
    baseline: {
      wording: {
        total: 'engagements au total',
        advertising_title: "Nombre d'engagements provenant de la campagne.",
        advertising_description:
          '(Ce sont les nouveaux engagements obtenus grâce à votre campagne média)',
        baseline_title: "Nombre d'engagements provenant du trafic natuel.",
        baseline_description:
          "(C'est le nombre de nouveaux engagements que vous auriez eu sans campagne média)",
        kpi_title: 'CPE hors baseline.',
        pki_description:
          '(Correspond au coût pour chaque nouvel engagement provenant de la campagne uniquement)'
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 12, 22, 23, 5],
        button: ['Profils']
      },
      {
        id: 'CPV',
        name: 'Visites page Facebook',
        title: 'Taux de visite',
        per: 1,
        cr: false,
        average: 2.6,
        childId: ['CPF', 'CPE'],
        connectors: [1, 5],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPF',
        name: 'Nombre de fan',
        title: "taux d'acquisition",
        average: 2.6,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPE',
        name: 'Engagement',
        title: 'Taux d’engagement',
        average: 2.6,
        per: 1,
        childId: 'CPEHB',
        forecast: ['FCPE', 'FACPE'],
        target: true,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPEHB',
        name: 'Baseline',
        title: 'Baseline',
        average: 60,
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPE'
      }
    ]
  },
  {
    name: 'Funnel personnalisé',
    description:
      'Créer votre propre Funnel en important simplement un fichier csv avec toutes les données nécessaires, et au minimum sur les 60 derniers jours pour bénéficiez d’une précision optimale dans les analyses',
    preview: '/static/img/preview/custom-preview.png',
    details: ['Custom', 'custom', 'custom', 'Custom'],
    baseline: {
      wording: {
        total: 'engagements au total',
        advertising_title: "Nombre d'engagements provenant de la campagne.",
        advertising_description:
          '(Ce sont les nouveaux engagements obtenus grâce à votre campagne média)',
        baseline_title: "Nombre d'engagements provenant du trafic natuel.",
        baseline_description:
          "(C'est le nombre de nouveaux engagements que vous auriez eu sans campagne média)",
        kpi_title: 'CPE hors baseline.',
        pki_description:
          '(Correspond au coût pour chaque nouvel engagement provenant de la campagne uniquement)'
      }
    },
    funnels: [
      {
        id: 'CPM',
        name: 'Impressions publicitaires ciblées',
        title: 'CPM',
        per: 1000,
        cr: false,
        average: 2.6,
        connectors: [1, 2, 3, 4, 12, 5],
        button: ['Profils']
      },
      {
        id: 'CPV',
        name: 'Visites page Facebook',
        title: 'Taux de visite',
        per: 1,
        cr: false,
        average: 2.6,
        childId: 'CPF',
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPF',
        name: 'Nombre de fan',
        title: "Taux d'acquisition",
        per: 1,
        average: 2.6,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPE',
        name: 'Engagement',
        title: 'Taux d’engagement',
        average: 2.6,
        per: 1,
        childId: 'CPEHB',
        forecast: ['FCPE', 'FACPE'],
        target: true,
        connectors: [],
        button: ['Profils', 'Look Alike', 'Retargeting']
      },
      {
        id: 'CPEHB',
        name: 'Baseline',
        title: 'Baseline',
        average: 60,
        per: 1,
        connectors: [],
        cr: false,
        objectifable: false,
        baseline: 'CPE'
      }
    ]
  }
];

export default measures;
