import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { NavImg, BaseDefaultHeader } from './styles';

const HEADER_HEIGHT = 70;

/**
 * checkScroll
 * @param {Function} handleSetScroll
 */
const checkScroll = handleSetScroll => {
  if (
    document.body.scrollTop > HEADER_HEIGHT ||
    document.documentElement.scrollTop > HEADER_HEIGHT
  ) {
    handleSetScroll(true);
  } else {
    handleSetScroll(false);
  }
};

/**
 *
 * DefaultHeader
 * @param {Object} param0
 * @comp
 *
 *
 */
function DefaultHeader({ children }) {
  const [isScrolling, setIsScrolling] = React.useState(
    window.scrollY > HEADER_HEIGHT
  );
  const handleScroll = () => checkScroll(setIsScrolling);
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <BaseDefaultHeader isOnScroll={isScrolling} id="headerHome">
      {isScrolling && (
        <Link to="/">
          <NavImg src="/assets/img/logo_ermes_opacity.svg" alt="ERMES" />
        </Link>
      )}
      {!isScrolling && (
        <Link to="/">
          <NavImg src="/assets/img/logo_ermes_homepage.svg" alt="ERMES" />
        </Link>
      )}
      {children}
    </BaseDefaultHeader>
  );
}

DefaultHeader.propTypes = {
  children: PropTypes.node.isRequired
};
export default DefaultHeader;
