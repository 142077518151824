import React from 'react';
import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

const BaseLayout = styled.div`
  background-color: ${colors.primary};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

function Layout(props) {
  return <BaseLayout {...props} />;
}

export default Layout;
