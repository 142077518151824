import React from 'react';
import PropTypes from 'prop-types';
import { Content, LinearBar } from './style';

const LinearProgressSynthesis = props => (
  <div className="progress-container">
    <LinearBar {...props}>
      <Content>{props.textValue}</Content>
    </LinearBar>
  </div>
);

export default LinearProgressSynthesis;

LinearProgressSynthesis.propTypes = {
  textValue: PropTypes.string
};

LinearProgressSynthesis.defaultProps = {
  textValue: ''
};
