export default {
  primary: '#1e1e2f',
  secondary: '#383B50',
  tertiary: '#5A5A76',
  funnel: '#009fe3',
  funnel_dark: '#0086bf',
  data: '#e5007d',
  insight: '#ef7d25',
  activation: '#312782',
  dark: '#000',
  light: '#fff',
  red: '#FF0000'
};
