import React from 'react';

import moment from 'moment';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import ObjectifRow from 'Funnel/__sale__/components/ObjectifRow';
import ForecastChart from 'Funnel/__sale__/components/ForecastChart';
import BaselineRow from 'Funnel/__sale__/components/BaselineRow';
import AnomalyRow from 'Funnel/__sale__/components/AnomalyRow';
import DistributionMediaContainer from 'Funnel/containers/DistributionMediaContainer';
import { useFunnelGraph } from 'Funnel/contexts/FunnelGraphContext';
import {
  Container,
  LinkButtonContainer,
  LinkButton,
  ButtonContent,
  Table,
  Titles,
  TableContent,
  ErrorMessage
} from './style';

// import PropTypes from 'prop-types'

/**
 * InsightDetailsContainer
 * @component
 *
 */

const AnomalyTableStyle = {
  table: {
    color: '#fff',
    borderCollapse: 'collapse',
    width: '100%',
    margin: '50px auto 0px auto',
    maxWidth: '1500px'
  },
  case: {
    borderBottom: '2px solid #fff',
    maxWidth: '150px',
    margin: '0px auto',
    textAlign: 'left',
    padding: '15px',
    fontWeight: 'bold',
    color: '#fff'
  }
};

function InsightDetailsContainer() {
  const { graphSubTab, setSubTab } = useFunnelGraph();
  // const { array } = useFunnelGraph();
  const array = [1.4, 50.9, 74.5];
  const [worstlevierCPI, setWorsLevierCPI] = React.useState('');
  const [savet3, setSavet3] = React.useState(0);
  const { funnel, setFunnel } = useFunnel();
  const { connected, objectifed, measure, forecast } = funnel;
  const targetFunnel = measure.funnels.find(({ target }) => target);
  const setAnom = anom => {
    const indexAnom = funnel.anom.findIndex(
      ({ funnelId, date }) => anom.date === date && anom.funnelId === funnelId
    );
    const newAnom = [...funnel.anom];
    if (indexAnom === -1) newAnom.push(anom);
    else newAnom.splice(indexAnom, 1, anom);
    setFunnel({ ...funnel, anom: newAnom });
  };

  const connector = Object.values(connected).find(
    oneConnected => oneConnected.funnelId === targetFunnel.id
  );
  const objectif = Object.values(objectifed).find(
    oneObjectifed => oneObjectifed.funnelId === targetFunnel.id
  );
  const allkpi = measure.funnels.map(({ id }) => id);

  let comp = 0;
  const budget = 0;
  let connectorData = {};
  let pourcentageBudget;
  // let bool = true;
  // let analysebool = true;

  if (connector !== undefined) {
    connectorData = Object.values(connector.data).reduce(
      (acc, current) => acc + current.budget
    );
    pourcentageBudget = budget - funnel.budget;
    if (pourcentageBudget < 0) {
      // bool = false;
      pourcentageBudget *= -1;
    }
    pourcentageBudget = (pourcentageBudget * 100) / funnel.budget;
  }
  if (connector && objectif) {
    const total = Object.values(connectorData).reduce(
      (acc, current) => acc + current.value
    );
    const reel = parseFloat(budget / total);
    const value = parseFloat(objectif.value);
    comp = ((value - reel) / value) * 100;
    if (comp < 0) {
      // analysebool = false;
      comp *= -1;
    }
    if (comp < 1) {
      comp = 0;
    }
  }

  const newt3 = (item, name) => {
    setSavet3(item);
    setWorsLevierCPI(name);
  };

  const filter = data => {
    return moment(data.date).isBetween(
      moment(funnel.start).startOf('day'),
      moment(funnel.end).startOf('day'),
      null,
      '[]'
    );
  };

  function checkBaseline(id) {
    const newConnector = Object.values(connected).find(
      oneConnected => oneConnected.funnelId === id
    );
    console.log(id, newConnector, connected);
    const hasData = newConnector && newConnector.data.length;
    const newConnectorData = hasData
      ? Object.values(newConnector.data).filter(filter)
      : [];
    const totalB = hasData
      ? Object.values(newConnectorData).filter(total => total.display !== 0)
      : [];
    if (totalB.length === 0) {
      return (
        <td className="kpi_table_description">
          <ErrorMessage>
            {
              "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
            }
          </ErrorMessage>
        </td>
      );
    }

    return (
      <div className="pb-data">
        <Container>
          <img
            src="/assets/img/info.svg"
            alt="? icon"
            className="question_mark"
          />
          <div className="kpi_table_description align_center">
            {`La Baseline est calculée suite à l'analyse historique de vos données de campagne et vous permet de comprendre instantanément
             le traffic naturel que vous auriez eu sans aucune campagne média.`}
          </div>
        </Container>
        <div className="table_blue_container">
          {!allkpi[4] ? (
            <td className="kpi_table_description"> {'missing data'}</td>
          ) : (
            <BaselineRow
              id={allkpi[4]}
              connected={connected}
              filter={filter}
              kpi={allkpi[3]}
              funnel={funnel}
            />
          )}
        </div>
      </div>
    );
  }

  const checkAnomalie = () => {
    let anomData = 0;
    connected[3].data.map(i => {
      const date = moment(i.date);
      if (date >= moment(funnel.start) && date <= moment(funnel.end)) {
        if (i.anom !== 0) {
          if (i.akpi1 === '1') anomData += 1;
          if (i.akpi2 === '1') anomData += 1;
          if (i.akpi3 === '1') anomData += 1;
          if (i.akpi4 === '1') anomData += 1;
        }
      }
      return i;
    });
    if (anomData === 0)
      return (
        <ErrorMessage className="kpi_table_description error">
          {
            "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
          }
        </ErrorMessage>
      );
    return (
      <div className="pb-data">
        <Container>
          <img
            src="/assets/img/info.svg"
            alt="? icon"
            className="question_mark"
          />
          <div className="kpi_table_description align_center">
            {`Le tableau d’anomalies fait ressortir rapidement et simplement les dates où certains KPIs
             ont des valeurs significativement éloignées, positivement ou négativement, de la tendance des 5 jours précédents.`}
          </div>
        </Container>
        <table className="table table_blue" style={AnomalyTableStyle.table}>
          <tr>
            <th style={AnomalyTableStyle.case} className="uppercase">
              Date
            </th>
            <th style={AnomalyTableStyle.case}>KPI</th>
            <th style={AnomalyTableStyle.case}>Valeur KPI</th>
            <th style={AnomalyTableStyle.case}> Moyenne 5 jours précedents</th>
            <th style={AnomalyTableStyle.case}> Ecart KPI/5jours précedents</th>
            <th style={AnomalyTableStyle.case} className="uppercase">
              Piste explication anomalies
            </th>
          </tr>
          <AnomalyRow
            connected={connected}
            allkpi={allkpi}
            funnel={funnel}
            setAnom={setAnom}
          />
        </table>
      </div>
    );
  };

  const checkPrevision = () => {
    const totalA = forecast[0]
      ? Object.values(forecast[0]).filter(
          oneForecast => oneForecast.value !== 0
        )
      : [];
    const totalB = forecast[1]
      ? Object.values(forecast[1]).filter(
          oneForecast => oneForecast.value !== 0
        )
      : [];
    if (totalA.length === 0 || totalB.length === 0) {
      return (
        <td className="kpi_table_description">
          <ErrorMessage>
            {
              "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
            }
          </ErrorMessage>
        </td>
      );
    }
    return (
      <div className="pb-data">
        <Container>
          <img
            src="/assets/img/info.svg"
            alt="? icon"
            className="question_mark"
          />
          <div className="kpi_table_description align_center">
            {`Vos performances futures sont déterminées suite à l’analyse de vos campagnes historiques et 
            prennent en compte un budget identique à celui dépensé sur la période actuellement analysée.`}
          </div>
        </Container>
        <div className="graph_container">
          {!allkpi[3] || !allkpi[4] ? (
            <td className="kpi_table_description"> {'missing data'}</td>
          ) : (
            <ForecastChart
              funnel={funnel}
              forecast={forecast}
              kpi={allkpi[3]}
              kpiForecast={allkpi[4]}
              connected={connected}
            />
          )}
        </div>
      </div>
    );
  };

  // ------------------ render:
  return (
    <React.Fragment>
      {graphSubTab === 'default' ? (
        <div>
          <LinkButtonContainer>
            <LinkButton>
              <ButtonContent>
                <img
                  alt="icon analyse"
                  src="/assets/img/icons/icon_analyse.svg"
                  className="icon"
                />
                <div className="description">
                  Analyse du Funnel et impact sur objectif
                </div>
                <button
                  type="button"
                  className="acceder"
                  onClick={() => {
                    setSubTab('tabAnalyse');
                  }}
                >
                  Accéder
                </button>
              </ButtonContent>
            </LinkButton>
            <LinkButton>
              <ButtonContent>
                <img
                  alt="icon baseline"
                  src="/assets/img/icons/icon_baseline.svg"
                  className="icon"
                />
                <div className="description">
                  Impact de la campagne sur la Baseline
                </div>
                <button
                  type="button"
                  className="acceder"
                  onClick={() => {
                    setSubTab('tabBaseline');
                  }}
                >
                  Accéder
                </button>
              </ButtonContent>
            </LinkButton>
            <LinkButton>
              <ButtonContent>
                <img
                  alt="icon anomalies"
                  src="/assets/img/icons/icon_anomalies.svg"
                  className="icon"
                />
                <div className="description">
                  Anomalies détéctées pendant la campagne
                </div>
                <button
                  type="button"
                  className="acceder"
                  onClick={() => {
                    setSubTab('tabAnomalies');
                  }}
                >
                  Accéder
                </button>
              </ButtonContent>
            </LinkButton>
            <LinkButton>
              <ButtonContent>
                <img
                  alt="icon prevision"
                  src="/assets/img/icons/icon_prevision.svg"
                  className="icon"
                />
                <div className="description">
                  Prévisions des performances futures
                </div>
                <button
                  type="button"
                  className="acceder"
                  onClick={() => {
                    setSubTab('graphPrevision');
                  }}
                >
                  Accéder
                </button>
              </ButtonContent>
            </LinkButton>
            <LinkButton>
              <ButtonContent>
                <img
                  alt="icon distribution media"
                  src="/assets/img/icons/icon_distribution_media.svg"
                  className="icon"
                />
                <div className="description">
                  Distribution des budgets et des impressions
                </div>
                <button
                  type="button"
                  className="acceder"
                  onClick={() => {
                    setSubTab('tabDistribMedia');
                  }}
                >
                  Accéder
                </button>
              </ButtonContent>
            </LinkButton>
          </LinkButtonContainer>
        </div>
      ) : null}
      {graphSubTab === 'tabAnalyse' && connected.length >= 4 ? (
        <div>
          {objectifed.length > 0 ? (
            <div className="kpi_table_container">
              {/* <p className="kpi_table_description align_center">
                Votre <strong className="text_blue">{targetFunnel.id}</strong>{' '}
                est{' '}
                <strong className="text_blue">
                  {comp.toFixed() !== 0 ? comp.toFixed(2) : 0} %{' '}
                  {analysebool === true ? (
                    <span>moins</span>
                  ) : (
                    <span>plus</span>
                  )}{' '}
                  élevé
                </strong>{' '}
                que votre objectif {savet3}
                <br />
                En termes d’amélioration de performances, votre principal levier
                de diminution du {targetFunnel.id} est le{' '}
                <strong className="text_blue">{worstlevierCPI}</strong>
              </p>
              <p style={{ color: 'white' }}>
                C’est sur le{' '}
                <strong className="text_blue">{worstlevierCPI}</strong> qu’il y
                a eu les meilleurs résultats
              </p>

              */}
              <div className="pb-data">
                <Container>
                  <img
                    src="/assets/img/info.svg"
                    alt="? icon"
                    className="question_mark"
                  />
                  <div className="kpi_table_description align_center">
                    {`Retrouvez ici l’impact de vos différents KPI sur votre objectif final et identifiez 
                    immédiatement le niveau du funnel sur lequel vous devez agir pour améliorer vos performances.`}
                  </div>
                </Container>
                <Table>
                  <Titles>
                    <tr>
                      <th className="uppercase">KPI</th>
                      <th>Objectif</th>
                      <th>Réel</th>
                      {/* <th className="uppercase">
                        <strong>Nouvel objectif</strong>
                      </th> */}
                      <th className="uppercase">Impact {allkpi[3]}</th>
                    </tr>
                  </Titles>
                  <TableContent>
                    {measure
                      ? measure.funnels
                          .filter(
                            f =>
                              Object.values(objectifed).findIndex(
                                oneObjectifed => oneObjectifed.funnelId === f.id
                              ) >= 0
                          )
                          .map(f => (
                            <ObjectifRow
                              key={f.name}
                              {...f}
                              connected={connected}
                              objectifed={objectifed}
                              filter={filter}
                              funnel={funnel}
                              array={array}
                              savet3={savet3}
                              newt3={newt3}
                              worstlevierCPI={worstlevierCPI}
                            />
                          ))
                      : null}
                  </TableContent>
                </Table>
              </div>
            </div>
          ) : (
            <div className="kpi_table_description align_center">
              <ErrorMessage>
                veuillez rentrer les objectifs afin d&#39;avoir les analyses
              </ErrorMessage>
            </div>
          )}
        </div>
      ) : null}

      {graphSubTab === 'tabBaseline' && connected.length >= 4 ? (
        <div>{checkBaseline(allkpi[4])}</div>
      ) : null}

      {graphSubTab === 'tabAnomalies' && connected.length >= 4 ? (
        <div className="graph-section scroll">{checkAnomalie()}</div>
      ) : null}

      {graphSubTab === 'graphPrevision' && connected.length >= 4 ? (
        <div>{checkPrevision()}</div>
      ) : null}
      {graphSubTab === 'tabDistribMedia' && connected.length >= 4 ? (
        <DistributionMediaContainer />
      ) : null}
    </React.Fragment>
  );
}

InsightDetailsContainer.defaultProps = {};

InsightDetailsContainer.propTypes = {};

export default InsightDetailsContainer;
