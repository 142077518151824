import React from 'react';
import PropTypes from 'prop-types';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';

// import SectionFunnel from 'Funnel/components/SectionFunnel';
import IndicatorsFunnel from 'Funnel/components/IndicatorsFunnel';
import PurposeFunnel from 'Funnel/components/PurposeFunnel';
import PeriodFunnel from 'Funnel/components/PeriodFunnel';
import BudgetFunnel from 'Funnel/components/BudgetFunnel';

import constants from 'utils/constants';

const { measures } = constants;

/**
 * IndicatorsContainer (Funnel Module)
 * @component
 *
 *
 */
function IndicatorsContainer(props) {
  const { isRecoveryMode } = props;
  const { setHasPurpose, setHasPeriod, setHasBudget } = useFunnelStep();
  const { setFunnel, funnel, toggleChangePurpose } = useFunnel();
  const setMeasure = measure => {
    setHasPurpose();
    setFunnel({ ...funnel, measure });
  };
  const setPeriod = ({ startDate, endDate }) => {
    setHasPeriod();
    setFunnel({ ...funnel, start: startDate, end: endDate });
  };
  const setBudget = budget => {
    setHasBudget();
    setFunnel({ ...funnel, budget });
  };

  const { start, end } = funnel;
  return (
    <IndicatorsFunnel>
      <PurposeFunnel
        funnelMeasure={funnel.measure}
        measures={measures}
        setMeasure={setMeasure}
        isFrozen={isRecoveryMode}
        toggleChangePurpose={toggleChangePurpose}
      />
      <PeriodFunnel start={start} end={end} setPeriod={setPeriod} />
      <BudgetFunnel funnel={funnel} setBudget={setBudget} />
    </IndicatorsFunnel>
  );
}
IndicatorsContainer.propTypes = {
  isRecoveryMode: PropTypes.bool.isRequired
};

export default IndicatorsContainer;
