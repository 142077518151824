import moment from 'utils/moment';

export default () => null;

const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

const formatAnom = value => {
  if (['', undefined].includes(value)) return '0';
  return value;
};

const formatString = (v, coeff = 1000) => {
  let value = 0;
  if (typeof v === 'string' && !isEmpty(v)) {
    value = parseFloat(v.replace(/\s/g, ''));
    value *= coeff;
  }
  return value;
};

export const formatCsv = (currentKpi, res, funnel) => {
  const currentFunnel = funnel.measure.funnels.find(f => f.id === currentKpi);
  console.log('currentFunnel', currentFunnel);
  console.log('currentFunnel @', funnel);
  console.log('currentFunnel @oo', currentKpi);
  const { kpi, title, ...budget } = res[0];
  const row = res.findIndex(r => {
    return r.kpi === currentKpi;
  });
  const values = res[row];

  const anomRow = res.findIndex(r => {
    return r.kpi === 'ANOM';
  });
  const anomValues = res[anomRow];

  const baseline = res.findIndex(r => {
    return r.kpi === funnel.baseline;
  });
  const baselineValues = res[baseline];

  const akpi1Row = res.findIndex(r => {
    return r.kpi === 'AKPI1';
  });
  const akpi1Values = res[akpi1Row];

  const akpi2Row = res.findIndex(r => {
    return r.kpi === 'AKPI2';
  });
  const akpi2Values = res[akpi2Row];

  const akpi3Row = res.findIndex(r => {
    return r.kpi === 'AKPI3';
  });
  const akpi3Values = res[akpi3Row];

  const akpi4Row = res.findIndex(r => {
    return r.kpi === 'AKPI4';
  });
  const akpi4Values = res[akpi4Row];

  const tkpi1Row = res.findIndex(r => {
    return r.kpi === 'TKPI1';
  });
  const tkpi1Values = res[tkpi1Row];

  const tkpi2Row = res.findIndex(r => {
    return r.kpi === 'TKPI2';
  });
  const tkpi2Values = res[tkpi2Row];

  const tkpi3Row = res.findIndex(r => {
    return r.kpi === 'TKPI3';
  });
  const tkpi3Values = res[tkpi3Row];

  const tkpi4Row = res.findIndex(r => {
    return r.kpi === 'TKPI4';
  });
  const tkpi4Values = res[tkpi4Row];

  const ekpi1Row = res.findIndex(r => {
    return r.kpi === 'EKPI1';
  });
  const ekpi1Values = res[ekpi1Row];

  const ekpi2Row = res.findIndex(r => {
    return r.kpi === 'EKPI2';
  });
  const ekpi2Values = res[ekpi2Row];

  const ekpi3Row = res.findIndex(r => {
    return r.kpi === 'EKPI3';
  });
  const ekpi3Values = res[ekpi3Row];

  const ekpi4Row = res.findIndex(r => {
    return r.kpi === 'EKPI4';
  });
  const ekpi4Values = res[ekpi4Row];

  const dates = Object.keys(budget);
  const data = Object.values(budget).map((b, i) => {
    let value = formatString(b);
    const display = value;
    if (currentFunnel.baseline !== undefined) {
      value = formatString(baselineValues[i]) - value;
    }
    const price = formatString(b);
    const anom = funnel.measure.funnels.target
      ? formatString(anomValues[i])
      : 0;
    const cost = value === 0 ? 0 : price / value;
    const graph = cost * funnel.per;
    const akpi1 = funnel.measure.funnels.target
      ? formatAnom(akpi1Values[i])
      : '0';
    const akpi2 = funnel.measure.funnels.target
      ? formatAnom(akpi2Values[i])
      : '0';
    const akpi3 = funnel.measure.funnels.target
      ? formatAnom(akpi3Values[i])
      : '0';
    const akpi4 = funnel.measure.funnels.target
      ? formatAnom(akpi4Values[i])
      : '0';
    const tkpi1 = funnel.measure.funnels.target
      ? formatAnom(tkpi1Values[i])
      : '0';
    const tkpi2 = funnel.measure.funnels.target
      ? formatAnom(tkpi2Values[i])
      : '0';
    const tkpi3 = funnel.measure.funnels.target
      ? formatAnom(tkpi3Values[i])
      : '0';
    const tkpi4 = funnel.measure.funnels.target
      ? formatAnom(tkpi4Values[i])
      : '0';
    const ekpi1 = funnel.measure.funnels.target
      ? formatAnom(ekpi1Values[i])
      : '0';
    const ekpi2 = funnel.measure.funnels.target
      ? formatAnom(ekpi2Values[i])
      : '0';
    const ekpi3 = funnel.measure.funnels.target
      ? formatAnom(ekpi3Values[i])
      : '0';
    const ekpi4 = funnel.measure.funnels.target
      ? formatAnom(ekpi4Values[i])
      : '0';
    if (dates[0][0] === dates[1][0] && dates[0][1] === dates[1][1]) {
      return {
        kpi: values.kpi,
        title: values.title,
        date: moment(i, 'MM-DD-YYYY')
          .startOf('day')
          .toDate(),
        budget: price,
        value,
        display,
        cost,
        graph,
        anom,
        akpi1,
        akpi2,
        akpi3,
        akpi4,
        tkpi1,
        tkpi2,
        tkpi3,
        tkpi4,
        ekpi1,
        ekpi2,
        ekpi3,
        ekpi4
      };
    }

    return {
      kpi: values.kpi,
      title: values.title,
      date: moment(i, 'DD-MM-YYYY')
        .startOf('day')
        .toDate(),
      budget: price,
      value,
      display,
      cost,
      graph,
      anom,
      akpi1,
      akpi2,
      akpi3,
      akpi4,
      tkpi1,
      tkpi2,
      tkpi3,
      tkpi4,
      ekpi1,
      ekpi2,
      ekpi3,
      ekpi4
    };
  });
  return data;
};

export const handleForecast = (forecasts, data) => {
  const forecast = forecasts.map(f => {
    const { kpi, title, ...budget } = data[0];
    const forecastRow = data.findIndex(r => {
      return r.kpi === f;
    });
    const values = data[forecastRow];
    return budget.map((b, i) => {
      const value = formatString(values[i], 1);
      return {
        kpi: values.kpi,
        title: values.title,
        date: moment(i, 'DD-MM-YYYY')
          .startOf('day')
          .toDate(),
        value
      };
    });
  });
  return forecast;
};
