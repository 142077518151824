import styled from 'styled-components';
import constant from 'utils/constants';
import { Link } from 'react-router-dom';

const { colors } = constant;

/**
 * @params isActive
 * @params nav
 *
 */
const BaseLink = styled(Link)`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  color: ${({ color }) => color || colors.secondary};
  padding: ${({ padding }) => padding || 'auto'};
  display: inline-block;
  &.arrow-home {
    background-color: ${colors.funnel};
    display: block;
    text-align: center;
    padding-bottom: 5px;
  }

  &.arrow-home.arrow-home--white {
    background-color: ${colors.light};
  }

  &.arrow-home img {
    height: 25px;
  }
  &.test-link {
    font-size: 1.2em;
    cursor: pointer;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const HrefLink = styled.a`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  color: ${({ color }) => color || colors.secondary};
  padding: ${({ padding }) => padding || 'auto'};
  display: inline-block;
  &.arrow-home {
    background-color: ${colors.funnel};
    display: block;
    text-align: center;
    padding-bottom: 5px;
  }

  &.arrow-home.arrow-home--white {
    background-color: ${colors.light};
  }

  &.arrow-home img {
    height: 25px;
  }
  &.test-link {
    font-size: 1.2em;
    cursor: pointer;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export default BaseLink;
