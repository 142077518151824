import React from 'react';
import BaseContent, { Title } from './style';

/**
 * ResultSavingInsight
 * @component
 *
 */

function CautionModalInsight() {
  return (
    <BaseContent>
      <Title>Attention :</Title>
      <div className="description">{`L'audience à cibler est trop petite, les résultats de ce ciblage risquent d'être faibles. 
      ${'\n'}Nous vous suggérons de revoir votre sélection de segments afin de cibler plus large et de maximiser vos résultats.`}</div>
    </BaseContent>
  );
}

export default CautionModalInsight;
