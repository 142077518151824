import React from 'react';
import moment from 'utils/moment';
import constants from 'utils/constants';

const { measures } = constants;

const FunnelContext = React.createContext();

const parse = funnel => ({
  ...funnel,
  anom: JSON.parse(funnel.anom) || [],
  connected: JSON.parse(funnel.connected) || [],
  forecast: JSON.parse(funnel.forecast) || [],
  measure: JSON.parse(funnel.measure),
  objectifed: JSON.parse(funnel.objectifed) || []
});

const stringify = funnel => ({
  ...funnel,
  anom: JSON.stringify(funnel.anom),
  connected: JSON.stringify(funnel.connected),
  forecast: JSON.stringify(funnel.forecast),
  measure: JSON.stringify(funnel.measure),
  objectifed: JSON.stringify(funnel.objectifed)
});

/**
 * FunnelProvider
 * @context
 * @component
 */
export default function FunnelProvider(props) {
  const defaultMesure = measures.find(({ default: d }) => d);
  const [funnel, setFunnel] = React.useState({
    anom: [],
    budget: 0,
    connected: [],
    createdAt: moment().unix(),
    end: moment(),
    forecast: [],
    funnelRef: {}, // ajout par jl, a enlever
    id: null,
    measure: defaultMesure,
    name: '',
    objectifed: [],
    saved: false,
    start: moment(),
    updatedAt: moment().unix(),
    userId: null
  });
  const funneUpdatelMiddleware = funnelUpdated => {
    setFunnel(funnelUpdated);
  };
  console.log('FUNNEL UPDATED ::::', funnel);
  const value = {
    funnel,
    funnelStringified: stringify(funnel),
    parseFunnel(jsonFunnel) {
      return parse(jsonFunnel);
    },
    setFunnel: funneUpdatelMiddleware,
    toggleChangePurpose() {
      setFunnel(currentFunnel => ({
        ...currentFunnel,
        connected: [],
        objectifed: []
      }));
    }
  };
  return <FunnelContext.Provider {...props} value={value} />;
}

/**
 * useFunel
 * @function
 */
export const useFunnel = () => {
  const context = React.useContext(FunnelContext);
  if (!context)
    throw new Error(
      'FunnelContext must be used in FunnelProvider(FunnelModule)'
    );
  return context;
};
