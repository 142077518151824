import React from 'react';
import PropTypes from 'prop-types';
import PurposeWrapper, { Label, DropdownPurpose } from './style';

/**
 * PurposeFunnel
 * @component
 * @see IndicatorsContainer
 *
 */
function PurposeFunnel(props) {
  const {
    measures,
    setMeasure,
    isFrozen,
    funnelMeasure,
    toggleChangePurpose
  } = props;
  const measureName = funnelMeasure && funnelMeasure.name;
  const [selected, setSelected] = React.useState(measureName);
  const label = measureName || 'Sélectionner un objectif';
  const options = measures.map(({ name }) => name);
  const toggleValue = value => {
    setSelected(value);
    setMeasure(measures.find(({ name }) => name === value));
    toggleChangePurpose();
  };
  return (
    <PurposeWrapper>
      <Label>
        <span className="span">OBJECTIF MESURÉ : </span>
        {isFrozen ? (
          <strong>{selected}</strong>
        ) : (
          <DropdownPurpose
            options={options}
            onChange={toggleValue}
            value={selected}
            placeholder={label}
          />
        )}
      </Label>
    </PurposeWrapper>
  );
}

PurposeFunnel.propTypes = {
  funnelMeasure: PropTypes.objectOf(PropTypes.any).isRequired,
  measures: PropTypes.arrayOf(PropTypes.any).isRequired,
  setMeasure: PropTypes.func.isRequired,
  isFrozen: PropTypes.bool.isRequired,
  toggleChangePurpose: PropTypes.func.isRequired
};

export default PurposeFunnel;
