import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimeSeries } from 'pondjs';
import moment from 'utils/moment';
import Graph from './chartKpiVolume';
// import ChartTconversion from './ChartTconversion';

const getMoment = date => moment(date, 'DD-MM-YYYY', 'fr', true);

export default class Chart extends Component {
  render() {
    const { connected, graphId, funnel } = this.props;
    const cpm = [];
    const cpv = [];
    const cpd = [];
    const cpi = [];
    const anom = [];
    const cid = [];
    const cdv = [];
    const cvi = [];
    const dayoff = [];

    funnel.connected[0].data.map(i => {
      const date = moment(i.date);
      if (parseInt(i.dayoff, 10) === 1) dayoff.push([date, 0.1]);
      return i;
    });
    funnel.connected[3].data.map(i => {
      const date = moment(i.date);
      if (i.anom !== 0) anom.push([date, 0.1]);
      return i;
    });
    const allkpi = funnel.measure.funnels.map(({ id }) => id);
    const allname = funnel.measure.funnels.map(({ name }) => name);
    connected.map(item => {
      item.data.map(i => {
        const date = moment(i.date);
        const { start, end } = funnel;
        const funnelStart = getMoment(start);
        const funnelEnd = getMoment(end);
        if (
          graphId === 1 &&
          date > funnelStart.subtract(1, 'day') &&
          date <= funnelEnd
        ) {
          if (item.funnelId === allkpi[0]) return cpm.push([date, i.graph]);
          if (item.funnelId === allkpi[1]) return cpv.push([date, i.graph]);
          if (item.funnelId === allkpi[2]) return cpd.push([date, i.graph]);
          if (parseInt(i.dayoff, 10) !== 1) {
            if (item.funnelId === allkpi[3]) return cpi.push([date, i.graph]);
          }
        }
        if (
          graphId !== 1 &&
          date > funnelStart.subtract(1, 'day') &&
          date <= funnelEnd
        ) {
          if (item.funnelId === allkpi[0])
            return cpm.push([date, i.value / 1000]);
          if (item.funnelId === allkpi[1])
            return cpv.push([date, i.value / 1000]);
          if (item.funnelId === allkpi[2])
            return cpd.push([date, i.value / 1000]);
          if (item.funnelId === allkpi[3])
            return cpi.push([date, i.value / 1000]);
        }
        return -1;
      });
      return 0;
    });

    if (graphId === 3) {
      cpm.map(function(i, item) {
        cvi.push(
          cpm[item][1] !== 0
            ? [i[0], (cpv[item][1] / cpm[item][1]) * 100]
            : [i[0], 0]
        );
        cid.push(
          cpd[item][1] !== 0
            ? [i[0], (cpi[item][1] / cpd[item][1]) * 100]
            : [i[0], 0]
        );
        cdv.push(
          cpd[item][1] !== 0
            ? [i[0], (cpd[item][1] / cpv[item][1]) * 100]
            : [i[0], 0]
        );
        return 0;
      });
    }
    if (cpm.length === 0) return <div>ERROR DATA</div>;
    const series = new TimeSeries({
      name: 'CPM',
      columns: ['time', 'CPM'],
      points: cpm
    });
    const e = series.max('CPM');
    console.log(dayoff);
    if (graphId !== 3) {
      return (
        <Graph
          start={moment(funnel.start, 'DD-MM-YYYY').subtract(1, 'days')}
          end={moment(funnel.end, 'DD-MM-YYYY')}
          series={series}
          seriescpv={
            new TimeSeries({
              name: 'CPV',
              columns: ['time', 'CPV'],
              points: cpv
            })
          }
          seriescpd={
            new TimeSeries({
              name: 'CPD',
              columns: ['time', 'CPD'],
              points: cpd
            })
          }
          seriescpi={
            new TimeSeries({
              name: 'CPI',
              columns: ['time', 'CPI'],
              points: cpi
            })
          }
          seriesanom={
            new TimeSeries({
              name: 'ANOM',
              columns: ['time', 'ANOM'],
              points: anom
            })
          }
          seriesdayoff={
            new TimeSeries({
              name: 'DAYOFF',
              columns: ['time', 'DAYOFF'],
              points: dayoff
            })
          }
          funnel={this.props.funnel}
          seriesBegin={0}
          seriesEnd={e + 1}
          seriesZoom={(e * 5) / 100}
          graphId={graphId}
          allkpi={allkpi}
          allname={allname}
        />
      );
    }
    return (
      <Graph
        series={
          new TimeSeries({
            name: 'CPM',
            columns: ['time', 'CPM'],
            points: cid
          })
        }
        seriescpv={
          new TimeSeries({
            name: 'CPV',
            columns: ['time', 'CPV'],
            points: []
          })
        }
        seriescpd={
          new TimeSeries({
            name: 'CPD',
            columns: ['time', 'CPD'],
            points: cdv
          })
        }
        seriescpi={
          new TimeSeries({
            name: 'CPI',
            columns: ['time', 'CPI'],
            points: cvi
          })
        }
        seriesanom={
          new TimeSeries({
            name: 'ANOM',
            columns: ['time', 'ANOM'],
            points: anom
          })
        }
        seriesdayoff={
          new TimeSeries({
            name: 'DAYOFF',
            columns: ['time', 'DAYOFF'],
            points: dayoff
          })
        }
        start={moment(funnel.start, 'DD-MM-YYYY').subtract(1, 'days')}
        end={moment(funnel.end, 'DD-MM-YYYY')}
        funnel={this.props.funnel}
        seriesBegin={0}
        seriesEnd={150}
        seriesZoom={(e * 5) / 100}
        graphId={graphId}
        allkpi={allkpi}
        allname={allname}
      />
    );
  }
}

Chart.propTypes = {
  funnel: PropTypes.object.isRequired,
  connected: PropTypes.array.isRequired,
  graphId: PropTypes.array.isRequired,
  allkpi: PropTypes.array.isRequired,
  allname: PropTypes.array.isRequired
};
