import React from 'react';
import styled from 'styled-components';
import Grid from 'components/Grid';
import BaseLabel from 'components/Label';
import BaseInput from 'components/Input';

const BudgetWrapper = props => (
  <Grid
    container
    direction="row"
    justify="end"
    alignItems="center"
    item
    xs={3}
    {...props}
  />
);

export const Input = styled(BaseInput)``;
export const Label = styled(BaseLabel)``;
export default BudgetWrapper;
