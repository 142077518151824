import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Grid from 'components/Grid';
import Button from 'components/Button';
import Input from 'components/Input';
import Link from 'components/Link';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/icons/Close';
// import constants from 'constants';
//
// const { colors } = constants;

const PersistWrapper = props => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    item
    xs={12}
    {...props}
  />
);

export const PersistButton = styled(Button)`
  && {
    background-color: #009fe3;
    color: #fff;
    margin-top: 16px;

    &:hover {
      background-color: rgba(0, 159, 227, 0.8);
    }
  }
  svg {
    margin-left: 5px;
  }
`;

export const PersistButtonCSV = styled(Button)`
  && {
    background-color: #383b50;
    color: #fff;
    margin-top: 16px;
    margin-right: 3%;
  }
  &:hover {
    background-color: rgba(0, 159, 227, 0.8);
  }
  svg {
    margin-left: 5px;
  }
`;

export const PersistDButton = styled(Button)`
  && {
    background-color: #000;
    color: #fff;
    margin-top: 16px;

    &:hover {
      background-color: rgba(0, 159, 227, 0.8);
    }
  }
  svg {
    margin-left: 5px;
  }
`;

const CloseButtonStyle = {
  position: 'absolute',
  right: '1rem',
  top: '1rem',
  color: '#666',
  cursor: 'pointer'
};

export const CloseButton = props => (
  <Icon style={CloseButtonStyle} {...props} />
);

export const PersistLink = styled(Link)``;

export const PersistInput = styled(Input)``;

export const PersistTextField = styled(TextField)``;

export const modalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0,0,0,0.8)'
  },
  content: {
    position: 'relative',
    background: 'white',
    border: 'none',
    width: '65vw',
    height: '45vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    width: '50%',
    margin: '2rem'
  }
};

export const PersistModal = props => <Modal style={modalStyle} {...props} />;

export default PersistWrapper;
