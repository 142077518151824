// import React from 'react';
// import PropTypes from 'prop-types'
import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

/**
 * @component
 *
 */
const Body = styled.div`
  display: grid;
  background-color: ${colors.primary};
  grid-template-rows: 72px auto;
`;

/**
 * @component
 *
 */
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

/**
 * @component
 *
 */
const ColumnLeft = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  padding-top: 6px;
  width: 50%;
  min-width: 400px;
`;

/**
 * @component
 *
 */
const ColumnRight = styled.div`
  display: flex;
  width: 50%;
  padding-right: 16px;
  padding-top: 4px;
`;

export default {
  Body,
  Row,
  ColumnLeft,
  ColumnRight
};
