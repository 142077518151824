import styled from 'styled-components';
import Button from 'components/Button';

const FeatureDetails = styled.div``;

export const LinkButton = styled(Button)`
  margin: 5px auto;
  display: inherit;
  text-align: center;
  &&:hover {
    font-weight: bold;
  }
`;

export default FeatureDetails;
