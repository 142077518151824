import React from 'react';
// import PropTypes from 'prop-types';
import DefaultInsight from './constructors';

const InsightContext = React.createContext();

/**
 * InsightProvider
 * @provider
 * @desc manage all the insight process
 *
 */
export default function InsightProvider(props) {
  const [insight, setInsight] = React.useState(new DefaultInsight());
  const value = {
    insight,
    updateInsight(data) {
      console.log('data', data);
      // deprecated : cause infinite loop when called
      const { nbProfiles, nbProfilesCookies, nbProfilesEmails } = data;
      const nextInsight = { ...insight };
      nextInsight.contacts.value = nbProfiles || 0;
      nextInsight.cookies.value = nbProfilesCookies || 0;
      nextInsight.mails.value = nbProfilesEmails || 0;
      setInsight(nextInsight);
    }
  };
  return <InsightContext.Provider {...props} value={value} />;
}

/**
 * useInsight
 * @function
 * @return value defined above (InsightProvider)
 *
 */
export const useInsight = () => {
  const context = React.useContext(InsightContext);
  if (!context)
    throw new Error('useInsight must be called inside InsightProvider');
  return context;
};
