import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'components/Grid';

function FeatureDetails(props) {
  const { children } = props;
  return (
    <Grid container padding="150px 40px 0 40px">
      {children}
    </Grid>
  );
}

FeatureDetails.defaultProps = {};

FeatureDetails.propTypes = {
  children: PropTypes.node.isRequired
};

export default FeatureDetails;
