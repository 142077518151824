import React from 'react';
import PropTypes from 'prop-types';
import BaseSectionFunnel from './style';

/**
 * @component
 *
 */
function SectionFunnel(props) {
  const addedStyle = {
    height: (props.maxHeight && '100%') || ''
  };
  return <BaseSectionFunnel {...addedStyle} {...props} />;
}

SectionFunnel.defaultProps = {
  maxHeight: false
};

SectionFunnel.propTypes = {
  maxHeight: PropTypes.bool
};

export default SectionFunnel;
