import styled from 'styled-components';

const BaseFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding: 20px 35px;
  background-color: ${({ color }) => color || 'transparent'};
  color: #fff;
  a {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;

    > div {
      margin-bottom: 15px;
    }
  }
`;
export default BaseFooter;
