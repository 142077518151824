import styled from 'styled-components';
import Input from '@material-ui/core/TextField';
import constants from 'utils/constants';

const { colors } = constants;

/**
 * @component
 *
 */
const BaseResultSaving = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-left: 3em;
  flex-direction: column;
`;

export const Title = styled.div`
  color: ${colors.light};
  font-size: 24px;
  font-weight: bold;
  margin: 1em 0;
`;

export const ModalInput = styled(Input)`
  border-radius: 7px;
  margin-right: 3em !important;
  height: 40px;
  background-color: #1e1e2f;
  margin: 10px 0;
  outline: none;

  fieldset,
  div {
    height: 40px;
    outline: none;
  }
  fieldset {
    border: none;
  }
  input {
    padding: 0 0 0 7px;
    height: 40px;
    outline: none;
    color: ${colors.light};
    font-weight: 500;
    font-size: 14px;
    ::placeholder {
      font-style: italic;
      color: ${colors.light} !important;
      font-size: 14px !important;
    }
    :focus {
      outline: none;
      border: 2px solid ${colors.light};
      border-radius: 7px;
    }
  }
`;

export default BaseResultSaving;
