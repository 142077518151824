import React from 'react';
import PropTypes from 'prop-types';
import constants from 'utils/constants';
import { VictoryChart, VictoryBar, VictoryLabel, VictoryAxis } from 'victory';
import { TableCellDistrib, TableRowDistrib, LabelStyle } from './style';

const { colors } = constants;

function Distrib(props) {
  const { name, tmpData, maxBudget, maxImpression } = props;

  return (
    <TableRowDistrib>
      <TableCellDistrib className="b-right" fontWeight="true">
        <div>{name}</div>
      </TableCellDistrib>
      <TableCellDistrib className="b-right" fontWeight="true">
        <div className="label">{tmpData.CPM}€</div>
      </TableCellDistrib>
      <TableCellDistrib className="b-right">
        <VictoryChart
          alignment="left"
          horizontal
          height={90}
          style={{ parent: { position: 'relative', right: '10%' } }}
          domain={{ y: [0, maxBudget] }}
        >
          <VictoryBar
            data={[tmpData.budget]}
            labels={() => tmpData.budget}
            style={{
              data: { fill: colors.funnel, strokeWidth: '20px' },
              labels: { fontSize: '14px', fill: colors.light }
            }}
            labelComponent={
              <VictoryLabel
                y={10}
                x={70}
                textAnchor="middle"
                style={LabelStyle}
              />
            }
          />
          <VictoryAxis
            style={{
              axis: { stroke: 'none' },
              tickLabels: {
                fontSize: '0px'
              }
            }}
          />
        </VictoryChart>
      </TableCellDistrib>
      <TableCellDistrib>
        <VictoryChart
          alignment="left"
          horizontal
          height={90}
          style={{ parent: { position: 'relative', right: '10%' } }}
          domain={{ y: [0, maxImpression] }}
        >
          <VictoryBar
            y="impression"
            x="CPM"
            data={[tmpData.impression]}
            labels={() => tmpData.impression}
            style={{
              data: { fill: colors.funnel, strokeWidth: '20px' },
              labels: {
                fontSize: '14px',
                fill: colors.light
              }
            }}
            labelComponent={
              <VictoryLabel
                y={10}
                x={70}
                textAnchor="middle"
                style={LabelStyle}
              />
            }
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: 'none' },
              tickLabels: {
                fontSize: '0px'
              }
            }}
          />
        </VictoryChart>
      </TableCellDistrib>
    </TableRowDistrib>
  );
}

Distrib.propTypes = {
  name: PropTypes.string.isRequired,
  tmpData: PropTypes.object.isRequired,
  maxBudget: PropTypes.number.isRequired,
  maxImpression: PropTypes.number.isRequired
};

export default Distrib;
