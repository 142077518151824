import React from 'react';
// import PropTypes from 'prop-types'
import PreviewFunnel, { PreviewItem } from 'Funnel/components/PreviewFunnel';
// import GuidelineFunnel from 'Funnel/components/GuidelineFunnel';
import SectionFunnel from 'Funnel/components/SectionFunnel';
import LinkFunnel from 'Funnel/components/LinkFunnel';
import TitleFunnel from 'Funnel/components/TitleFunnel';
import OverviewFunnel from 'Funnel/components/OverviewFunnel';

import { useFunnel } from 'Funnel/contexts/FunnelContext';

/**
 * OverviewContainer
 * @component
 *
 */
function OverviewContainer() {
  const { funnel } = useFunnel();
  const { measure } = funnel;
  const isCustomized = measure && measure.name === 'Funnel personnalisé';
  return (
    <SectionFunnel>
      <OverviewFunnel>
        {/* <GuidelineFunnel /> */}
        <PreviewFunnel>
          <TitleFunnel>Aperçu du Funnel sélectionné</TitleFunnel>
          {measure && (
            <React.Fragment>
              <p>{measure.description || ''}</p>
              {measure.details &&
                measure.details.map((detail, index) => (
                  <PreviewItem
                    key={detail + index}
                    width={`${340 - 40 * index}px`}
                  >
                    {detail}
                  </PreviewItem>
                ))}
            </React.Fragment>
          )}
          {isCustomized && (
            <div className="dl-csv-container">
              <LinkFunnel
                href="/assets/template-csv.xlsm"
                download="Template_Csv"
                className="dl-csv"
              >
                <img src="/assets/img/connector/CSV_Icon.png" alt="CSV" />
                <p className="text">Télécharger template</p>
              </LinkFunnel>
            </div>
          )}
        </PreviewFunnel>
      </OverviewFunnel>
    </SectionFunnel>
  );
}

OverviewContainer.defaultProps = {};

OverviewContainer.propTypes = {};

export default OverviewContainer;
