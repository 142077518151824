import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

/**
 * ConnectorOperator
 * @component
 *
 */
const BaseOperator = styled.a`
  display: ${({ hide }) => (hide ? 'none' : 'inline-flex')};
  text-decoration: none;
  background-color: ${colors.light};
  color: ${colors.primary};
  border-radius: ${({ rounded }) => (rounded ? '24px' : 'unset')};
  padding: 1px 5px;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid ${colors.light};
`;

export default BaseOperator;
