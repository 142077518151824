import React from 'react';
import PropTypes from 'prop-types';

import BaseModal, { modalStyle } from './style';

/**
 * Modal
 * @component
 *
 */
function Modal(props) {
  const { isOpen, onAfterOpen, contentStyle, onClose } = props;
  const [opened, setOpened] = React.useState(isOpen);
  const style = {
    ...modalStyle,
    content: { ...modalStyle.content, ...contentStyle }
  };
  React.useEffect(() => {
    setOpened(isOpen);
  }, [isOpen]);
  return (
    <BaseModal
      style={style}
      isOpen={opened}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      contentLabel="Sauvegarde du funnel"
      {...props}
    />
  );
}
Modal.defaultProps = {
  onAfterOpen: () => null,
  contentLabel: '',
  contentStyle: {}
};
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAfterOpen: PropTypes.func,
  contentLabel: PropTypes.string,
  contentStyle: PropTypes.objectOf(PropTypes.any)
};

export default Modal;
