import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import FunnelProvider from 'Funnel/contexts/FunnelContext';
import FunnelStepProvider from 'Funnel/contexts/FunnelStepContext';
import FunnelGraphProvider from 'Funnel/contexts/FunnelGraphContext';
import Loading from 'components/Loading';
import { useUser } from 'contexts/UserContext';
import { FUNNEL } from 'utils/graphQL';
import AppFunnel from './AppFunnelSale';

/**
 * AppFunnel
 */
function Funnel(props) {
  const { match, ...rest } = props;
  const { id: urlFunnelId } = match.params;
  const { isAuthenticated } = useUser();
  return (
    <FunnelProvider>
      <FunnelStepProvider>
        <FunnelGraphProvider>
          {urlFunnelId ? (
            <Query
              query={FUNNEL.GET_FUNNEL}
              skip={!isAuthenticated}
              variables={{ id: urlFunnelId }}
            >
              {response => (
                <Loading {...response}>
                  <AppFunnel
                    {...rest}
                    recoveredFunnel={response.data.getFunnel}
                  />
                </Loading>
              )}
            </Query>
          ) : (
            <AppFunnel {...rest} />
          )}
        </FunnelGraphProvider>
      </FunnelStepProvider>
    </FunnelProvider>
  );
}
Funnel.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(Funnel);
