import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/** * @component */
const BaseLayoutFunnel = styled.div``;

/**
 * LayoutFunnel
 *
 */
function LayoutFunnel(props) {
  return <BaseLayoutFunnel {...props} />;
}

/**
 * GridFunnel
 * @desc contains the two columns (left and right)
 * @component
 */
const GridFunnel = styled.div`
  display: grid;
  grid-template-columns: 6fr 10fr;
  padding: 0 16px;
  grid-column-gap: 16px;
`;

/**
 * BaseColumnFunnel
 * @desc the style of one ColumnFunnel
 * @components
 *
 */
const BaseColumnFunnel = styled.div`
  .verticalySpaced {
    display: grid;
    grid-row-gap: 16px;
  }
  .one-block {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

/**
 * ColumnFunnel
 * @desc adapt children wrapper for the ColumnFunnel
 * @components
 */
const ColumnFunnel = props => {
  const { verticalySpaced, children } = props;
  return (
    <BaseColumnFunnel>
      {verticalySpaced ? (
        <div className="verticalySpaced">{children}</div>
      ) : (
        <div className="one-block">{children}</div>
      )}
    </BaseColumnFunnel>
  );
};
ColumnFunnel.defaultProps = {
  verticalySpaced: false
};
ColumnFunnel.propTypes = {
  children: PropTypes.node.isRequired,
  verticalySpaced: PropTypes.bool
};

export default {
  Wrapper: LayoutFunnel,
  Grid: GridFunnel,
  Column: ColumnFunnel
};
