import React from 'react';

import SectionFunnel from 'Funnel/components/SectionFunnel';
import ActionFunnel from 'Funnel/components/ActionFunnel';
import HiderFunnel from 'Funnel/components/HiderFunnel';

import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';

/**
 * @component
 *
 */
function ActionContainer() {
  const { isFinalStep } = useFunnelStep();
  return (
    <HiderFunnel hide={!isFinalStep}>
      <SectionFunnel>
        <ActionFunnel actionHandler={() => console.log(test)} />
      </SectionFunnel>
    </HiderFunnel>
  );
}

export default ActionContainer;
