import PropTypes from 'prop-types';
import moment from 'utils/moment';
import React from 'react';
import { Mutation } from 'react-apollo';
import { FUNNEL } from 'utils/graphQL';
import constants from 'utils/constants';
import {
  Buttons,
  DefaultTableLine,
  HeaderTable,
  Icons,
  Table,
  TableContent,
  TableLine,
  TableLink,
  TableBody
} from './style';

const { routes } = constants;
/**
 * HistoryFunnel
 * @component
 * @see HistoryFunnelContainer
 *
 */
function HistoryFunnel(props) {
  const { children } = props;
  return (
    <Table>
      <DefaultTableLine>
        <HeaderTable> Nom du Funnel </HeaderTable>
        <HeaderTable> Objectif analysé </HeaderTable>
        <HeaderTable> Date de sauvegarde </HeaderTable>
        <HeaderTable> Période analysée </HeaderTable>
        <HeaderTable> Actions </HeaderTable>
      </DefaultTableLine>
      <TableBody>{children}</TableBody>
    </Table>
  );
}
HistoryFunnel.defaultProps = {
  children: undefined
};
HistoryFunnel.propTypes = {
  children: PropTypes.node
};

/**
 * HistoryFunnelItem
 * @component
 * @see HistoryFunnelContainer
 *
 */
export const HistoryFunnelItem = props => {
  const { funnel } = props;
  const measure = JSON.parse(funnel.measure);
  const localisation = `${routes.FUNNEL}/${funnel.id}`;
  return (
    <TableLine>
      <TableContent>
        <TableLink to={localisation}>{funnel.name}</TableLink>
      </TableContent>
      <TableContent>
        <TableLink to={localisation}> {measure.name} </TableLink>
      </TableContent>
      <TableContent>
        <TableLink to={localisation}>
          {moment.unix(funnel.updatedAt).format('L')}
        </TableLink>
      </TableContent>
      <TableContent>
        <TableLink to={localisation}>
          {' '}
          {moment(funnel.start).format('L')}-{moment(funnel.end).format('L')}
        </TableLink>
      </TableContent>
      <TableContent centered>
        <Mutation mutation={FUNNEL.DELETE_FUNNEL}>
          {deleteFunnel => (
            <Buttons
              onClick={() => {
                deleteFunnel({
                  variables: {
                    id: funnel.id
                  }
                });
              }}
              type="button"
              href="../history/funnel"
            >
              <Icons icon="trash" />
              Supprimer
            </Buttons>
          )}
        </Mutation>
      </TableContent>
    </TableLine>
  );
};
HistoryFunnelItem.propTypes = {
  funnel: PropTypes.objectOf(PropTypes.any).isRequired
};

export default HistoryFunnel;
