import React from 'react';
import PropTypes from 'prop-types';
import ModalInsight from 'Insight/components/ModalInsight';
import DescriptionModalInsight from 'Insight/components/DescriptionModalInsight';
import BaseCriteriaInsight, { CriteriaCheckbox } from './style';

/**
 * CriteriaInsight
 * @see CriteriasListContainer
 * @desc:: one criteria (eq one right line)
 *
 */
function CriteriaInsight(props) {
  const {
    checked,
    ermes,
    insee,
    title,
    description,
    hasInsee,
    toggleCheck
  } = props;
  const [currentModal, setCurrentModal] = React.useState(null);
  const closeModal = () => {
    setCurrentModal(null);
  };

  const handleCheck = () => {
    toggleCheck();
  };
  const id = `criteria-${title}`;
  return (
    <BaseCriteriaInsight
      inseePercent={insee}
      ermesPercent={ermes}
      hasInsee={hasInsee}
    >
      <label htmlFor={id} className="label">
        <CriteriaCheckbox
          id={id}
          className="checkbox"
          checked={checked}
          onChange={handleCheck}
        />
        <span>{title}</span>
      </label>
      <div
        role="presentation"
        className="description-icon"
        onClick={() => setCurrentModal('modal_description')}
      >
        <img src="/assets/img/icon-caret-down.svg" alt="questionMarkIcon" />
      </div>
      <div className="graph left" value={ermes}>
        <span>{ermes}%</span>
        <div className="gauge ermes" />
      </div>
      {hasInsee ? (
        <div className="graph" value={ermes}>
          <div className="gauge insee" />
          <span>{insee}%</span>
        </div>
      ) : null}
      <ModalInsight
        isOpen={currentModal === 'modal_description'}
        handleClose={closeModal}
        handleValidation={() => {}}
        label="Description"
        hasButton={false}
      >
        <DescriptionModalInsight title={title} description={description} />
      </ModalInsight>
    </BaseCriteriaInsight>
  );
}
CriteriaInsight.defaultProps = {
  insee: 0
};
CriteriaInsight.propTypes = {
  checked: PropTypes.bool.isRequired,
  ermes: PropTypes.number.isRequired,
  hasInsee: PropTypes.bool.isRequired,
  insee: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  toggleCheck: PropTypes.func.isRequired
};

export default CriteriaInsight;
