import React from 'react';

const STEPS = {
  BEGINNING: 'beginning',
  PURPOSE: 'purpose',
  PERIOD: 'period',
  BUDGET: 'budget',
  CONNECTOR: 'connector'
};

const FunnelStepContext = React.createContext();

/**
 * FunnelStepProvider
 * @Provider
 * @desc step management for know what the user can see per step
 *
 */
function FunnelStepProvider(props) {
  const [achieved, setAchieved] = React.useState([]);
  const addAchieved = newStep => {
    if (achieved.includes(newStep)) return;
    console.log('new achieved :', newStep);
    setAchieved([...achieved, newStep]);
  };
  const value = {
    isFinalStep: achieved.includes(STEPS.CONNECTOR),
    hasBudget: achieved.includes(STEPS.BUDGET),
    canSeeConnector: [STEPS.PURPOSE, STEPS.PERIOD, STEPS.BUDGET].every(step => {
      console.log('call canSeeConnector');
      return achieved.includes(step);
    }),
    setHasGetFunnel() {
      setAchieved(Object.values(STEPS));
    },
    setHasPurpose() {
      addAchieved(STEPS.PURPOSE);
    },
    setHasPeriod() {
      addAchieved(STEPS.PERIOD);
    },
    setHasBudget() {
      addAchieved(STEPS.BUDGET);
    },
    setHasConnector() {
      addAchieved(STEPS.CONNECTOR);
    }
  };
  return <FunnelStepContext.Provider {...props} value={value} />;
}

/**
 * useFunelStep
 *
 */
export const useFunnelStep = () => {
  const context = React.useContext(FunnelStepContext);
  if (!context)
    throw new Error(
      'FunnelStepContext must be called in FunnelStepContext Provider'
    );
  return context;
};

export default FunnelStepProvider;
