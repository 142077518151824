import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

const BaseResult = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${colors.insight};
  .value-result-insight {
    color: ${colors.light};
    font-weight: bold;
    font-size: 24px;
  }
  .title {
    font-size: 16px;
    margin: 0;
  }
`;

export default BaseResult;
