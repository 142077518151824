import React from 'react';

const CategoryContext = React.createContext();

/**
 * CategoryProvider
 * @provider
 * @desc manage all the insight process
 *
 */
export default function CategoryProvider(props) {
  const [currentCategory, setCurrentCategory] = React.useState('');
  const [allCategories, setAllCategories] = React.useState([]);
  const value = {
    currentCategory,
    setCurrentCategory,
    allCategories,
    setAllCategories
  };
  return <CategoryContext.Provider {...props} value={value} />;
}

/**
 * useCategory
 * @function
 * @return value defined above (CategoryProvider)
 *
 */
export const useCategory = () => {
  const context = React.useContext(CategoryContext);
  if (!context)
    throw new Error('useCategory must be called inside CategoryProvider');
  return context;
};
