import React from 'react';

import BaseGrid from './style';

/**
 * @component
 *
 */
function Grid(props) {
  return <BaseGrid {...props} />;
}

export default Grid;
