function DefaultInsight() {
  this.contacts = {
    label: 'Contacts (ID ERMES)',
    value: 0
  };
  this.cookies = {
    label: 'Cookies',
    value: 0
  };
  this.mails = {
    label: 'Emails hachés',
    value: 0
  };
}
export default DefaultInsight;
