import React from 'react';
import BaseLabel from './style';

/**
 * @component
 *
 */
function Label(props) {
  return <BaseLabel {...props} />;
}

export default Label;
