import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

const BottomBordered = styled.div`
  border-bottom: 1px solid ${colors.light};
`;

export const BaseListTitle = styled(BottomBordered)`
  padding: 8px 0;
  align-items: center;
`;

export const BaseListItem = styled(BottomBordered)`
  display: grid;
  grid-template-columns: auto 30px;
  padding: 8px 0;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${colors.funnel};
  }
`;

export default BaseListTitle;
