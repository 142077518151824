import React from 'react';
// import PropTypes from 'prop-types'

import BaseOverview from './style';

/**
 * OverviewFunnel
 * @component
 *
 */
function OverviewFunnel(props) {
  return <BaseOverview {...props} />;
}

OverviewFunnel.defaultProps = {};

OverviewFunnel.propTypes = {};

export default OverviewFunnel;
