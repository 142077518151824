import React from 'react';
import PropTypes from 'prop-types';
import CsvDownload from 'Funnel/__sale__/components/CsvDownload';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import moment from 'utils/moment';
import PersistWrapper, {
  PersistButton,
  PersistModal,
  PersistTextField,
  modalStyle,
  PersistDButton,
  PersistButtonCSV,
  CloseButton
} from './style';

/**
 * PersistFunnel
 * @component
 * TODO:: utiliser le createFunnel pour persister le funnel
 */
function PersistFunnel(props) {
  const [currentName, updateCurrentName] = React.useState(props.name);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { funnel } = useFunnel();
  const { saveFunnel } = props;
  const handlePersist = () => {
    saveFunnel(currentName);
    setIsModalOpen(false);
  };
  React.useEffect(() => {
    updateCurrentName(props.name);
  }, [props.name]);

  const filter = data => {
    return moment(data.date).isBetween(
      moment(funnel.start)
        .startOf('day')
        .format(),
      moment(funnel.end)
        .startOf('day')
        .format(),
      null,
      '[]'
    );
  };

  function downloadCsv() {
    CsvDownload.dl(
      funnel.connected,
      funnel.measure.funnels.map(({ id }) => id),
      filter,
      funnel,
      funnel.name,
      funnel.objectifed,
      []
    );
  }

  return (
    <PersistWrapper>
      <PersistModal
        isOpen={isModalOpen}
        onAfterOpen={() => null}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Sauvegarde du funnel"
      >
        <CloseButton onClick={() => setIsModalOpen(false)} />
        <h1 style={modalStyle.title}>Donner un nom à votre funnel</h1>
        <PersistTextField
          label="Nom du funnel"
          style={modalStyle.form}
          placeholder="Nom du funel"
          fullWidth
          margin="normal"
          value={currentName}
          onChange={({ target }) => updateCurrentName(target.value)}
          InputLabelProps={{
            shrink: true
          }}
        />
        <PersistDButton onClick={handlePersist}>SAUVEGARDER</PersistDButton>
      </PersistModal>
      <PersistButtonCSV
        style={{ backgroundColor: '#383b50' }}
        onClick={() => downloadCsv()}
      >
        Rapport CSV
      </PersistButtonCSV>
      <PersistButton onClick={() => setIsModalOpen(true)}>
        Sauvegarder le FUNNEL
      </PersistButton>
    </PersistWrapper>
  );
}

PersistFunnel.propTypes = {
  name: PropTypes.string.isRequired,
  saveFunnel: PropTypes.func.isRequired
};

export default PersistFunnel;
