import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import constants from 'utils/constants';

const { colors } = constants;

const BaseGrid = styled(Grid)`
  color: ${({ color }) => color || colors.light};
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  padding: ${({ padding }) => padding || '0'};
  width: 100%;
`;

export default BaseGrid;
