import React from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';

import LoadingInsight from 'Insight/components/LoadingInsight';
import AppInsight from 'Insight/AppInsight';
import InsightProvider from 'Insight/contexts/InsightContext';
import CategoryProvider from 'Insight/contexts/CategoryContext';
import OperatorProvider from 'Insight/contexts/OperatorContext';
import SegmentProvider from 'Insight/contexts/SegmentContext';

import getSchemaQuery from './schemasQuery';

// TODO ::: replace 8182 by 8080 when new schema ll be available
const graphqlUri = 'http://rms.redpillparis.com:8080/graphql';
const xToken =
  '1AEF56F88590D8E33AA8552A8F6EFF26D40AE658C297EED1C9C56C5D827BABC1';
const generateClient = () => {
  return new Promise(resolve => {
    getSchemaQuery(graphqlUri, xToken).then(schemasQuery => {
      const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData: schemasQuery
      });
      const apolloClient = new ApolloClient({
        uri: graphqlUri,
        cache: new InMemoryCache({ fragmentMatcher }),
        request: async operation =>
          operation.setContext({
            headers: {
              'x-token': xToken
            }
          })
      });
      resolve(apolloClient);
    });
  });
};

/**
 * Insight
 * @component
 * @desc generate schemaQuery at the start for avoid fragment warning
 */
function InsightModule() {
  const [client, setClient] = React.useState(null);
  const [hasClient, setHasClient] = React.useState(false);
  React.useEffect(() => {
    generateClient().then(newClient => {
      setHasClient(true);
      setClient(newClient);
    });
  }, [hasClient]);
  if (!client) return <LoadingInsight loading />;
  return (
    <ApolloProvider client={client}>
      <InsightProvider>
        <CategoryProvider>
          <SegmentProvider>
            <OperatorProvider>
              <AppInsight />
            </OperatorProvider>
          </SegmentProvider>
        </CategoryProvider>
      </InsightProvider>
    </ApolloProvider>
  );
}

export default InsightModule;
