import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

export const Table = styled.table`
  color: #fff;
  width: 100%;
  padding: 20px 30px;
  border-radius: 4px;
  font-weight: normal;
  white-space: pre-wrap;
`;

export const Row = styled.tr`
  height: ${({ height }) => height || 'auto'};
`;

export const Column = styled.th`
  font-weight: normal;
  text-align: ${({ textAlign }) => textAlign || 'justify'};
  padding: ${({ padding }) => padding || '30px'} 0;
  background-color: ${({ bgColor }) => colors[bgColor] || colors.secondary};
  color: ${({ color }) => colors[color] || colors.light};
  ${({ paddingLeft }) => (paddingLeft ? `padding-left: ${paddingLeft}px` : '')};
  .div {
    display: inline-flex;
  }
`;

export const Title = styled.div`
  height: 20px;
`;

export const Data = styled.div`
  font-size: ${({ light }) => (light ? '16px' : '20px')};
  font-weight: bold;
  display: inline-flex;
`;
