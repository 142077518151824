const fetch = require('node-fetch');

/**
 * getSchemaQuery
 * @desc get schemas query from api
 * for use it as apollo cache (for avoid fragment warning in console)
 *
 */
function getSchemaQuery(uri, xToken) {
  return new Promise(resolve => {
    fetch(uri, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-token': xToken },
      body: JSON.stringify({
        variables: {},
        query: `
      {
        __schema {
          types {
            kind
            name
            possibleTypes {
              name
            }
          }
        }
      }
    `
      })
    })
      .then(result => result.json())
      .then(result => {
        // here we're filtering out any type information unrelated to unions or interfaces
        // eslint-disable-next-line
        const filteredData = result.data.__schema.types.filter(
          type => type.possibleTypes !== null
        );
        // eslint-disable-next-line
        result.data.__schema.types = filteredData;
        resolve(result.data);
      });
  });
}

export default getSchemaQuery;
