import React from 'react';
import styled from 'styled-components';
import constants from 'utils/constants';

import Title from 'components/Title';

const { colors } = constants;

/**
 * @component
 * @see OperatorLayoutInsight/style.js for synchronize height in grid
 */
const BaseCategoriesInsight = styled.div`
  display: grid;
  grid-template-rows: repeat(
    ${({ length }) => `${length},${Math.round(100 / length)}%`}
  );
`;

const borderRadius = '0 8px 8px 0';

/**
 * BaseCategoriesItem
 * @component
 *
 */
export const BaseCategoriesItem = styled.a`
  text-decoration: none;
  background-color: ${({ active }) =>
    active ? colors.tertiary : colors.secondary};
  display: grid;
  grid-template-columns: 20% 72% 8%;
  min-width: 180px;
  color: ${colors.light};
  border: 1px solid ${colors.tertiary};
  border-radius: ${borderRadius};

  .logo-insight {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .desc-insight {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .border {
    background: ${({ active }) => (active ? colors.insight : colors.secondary)};
    border-radius: ${borderRadius};
  }
  .infos-insight {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h3 {
      margin: 0 0 7px 0;
    }
  }
  .desc-insight {
    font-size: 14px;
    font-weight: normal;
    color: ${({ active }) => (active ? colors.light : '#bdbdbd')};
    text-transform: none;
  }
  &:hover {
    background-color: ${colors.tertiary};
    .desc-insight {
      color: ${colors.light};
    }
    .border {
      background: ${({ active }) =>
        active ? colors.insight : colors.tertiary};
    }
  }
`;

export const CategoriesTitle = props => <Title small {...props} />;
export default BaseCategoriesInsight;
