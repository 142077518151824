import styled from 'styled-components';

const BaseLoading = styled.div`
  padding: 16px;
  min-height: 24px;
  min-width: 24px;
`;

export const BaseLoadingError = styled(BaseLoading)`
  background-color: red;
`;

export default BaseLoading;
