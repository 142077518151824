import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimeRange } from 'pondjs';
import moment from 'utils/moment';

import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  Legend,
  LineChart,
  Resizable,
  styler,
  EventMarker,
  ScatterChart
} from 'react-timeseries-charts';

const motif = [
  { label: 'Meteo', value: 0 },
  { label: 'Problème technique', value: 1 },
  { label: 'Defaut de paiement', value: 2 },
  { label: 'Changement de publicité', value: 3 },
  { label: 'Changement de cible', value: 4 },
  { label: 'Autre', value: 5 }
];

const style = styler([
  { key: 'CPM', color: '#CA4040' },
  { key: 'CPV', color: '#9467bd' },
  { key: 'CPD', color: '#987951' },
  { key: 'CPI', color: '#CC862A' },
  { key: 'ANOM', color: '#CA4040', symbolType: 'dot' }
]);

/* eslint no-underscore-dangle: ["error", { "allow": ["_eventList", "_collection"] }] */
/* eslint no-nested-ternary: 0 */

export default class Graph extends Component {
  constructor(props) {
    super(props);
    const { seriesBegin, seriesEnd } = this.props;
    this.state = {
      mode: 'zoom',
      max: seriesEnd,
      min: seriesBegin,
      timerange: new TimeRange([
        moment(props.start).subtract(1, 'day'),
        moment(props.end).add(1, 'day')
      ]),
      tracker: null,
      trackerValue: '',
      trackerValuecpv: '',
      trackerValuecpd: '',
      trackerValuecpi: '',
      trackerEvent: null,
      markerMode: 'flag',
      zoom: this.props.seriesZoom,
      kpi: {
        CPM: { show: false },
        CPD: { show: false },
        CPI: { show: false },
        CPV: { show: false },
        ANOM: { show: false }
      }
    };
    this.timeZoom = this.timeZoom.bind(this);
    this.timedeZoom = this.timedeZoom.bind(this);
  }

  getMotif = (kpi, time) => {
    const { funnel } = this.props;
    if (funnel.anom) {
      const kpis = funnel.anom.filter(e => e.funnelId === kpi);
      if (kpis.length > 0) {
        const f = kpis.filter(i => i.date === time.format('DD-MM-YYYY'));
        if (f.length > 0) {
          return `(anomalie : ${motif[f[f.length - 1].motifanom].label})`;
        }
      }
    }
    return '';
  };

  handleTrackerChanged = t => {
    const { series, seriescpd, seriescpi, seriescpv } = this.props;
    let d;
    let v;
    let i;
    let eventValue;
    if (t) {
      const e = series.atTime(t);
      eventValue = e.get('CPM');
      const m = `${eventValue.toFixed(2)}${this.getMotif(
        this.props.allkpi[0],
        moment(t)
      )}`;
      if (seriescpd._collection._eventList.size !== 0) {
        const ed = seriescpd.atTime(t);
        const eventValuedd = ed.get('CPD');
        d = `${eventValuedd.toFixed(2)}${this.getMotif(
          this.props.allkpi[1],
          moment(t)
        )}`;
      }
      if (seriescpv._collection._eventList.size !== 0) {
        const ev = seriescpv.atTime(t);
        const eventValuedv = ev.get('CPV');
        v = `${eventValuedv.toFixed(2)}${this.getMotif(
          this.props.allkpi[2],
          moment(t)
        )}`;
      }
      if (seriescpi._collection._eventList.size !== 0) {
        const ei = seriescpi.atTime(t);
        const eventValuedi = ei.get('CPI');
        i = `${eventValuedi.toFixed(2)}${this.getMotif(
          this.props.allkpi[3],
          moment(t)
        )}`;
      }
      const eventTime = new Date(
        e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );

      this.setState({
        tracker: eventTime,
        trackerValue: m,
        trackerValuecpd: d,
        trackerValuecpv: v,
        trackerValuecpi: i,
        trackerEvent: e
      });
    } else {
      this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
    }
  };

  renderMarker = () => {
    if (!this.state.tracker) {
      return <g />;
    }
    if (this.state.markerMode === 'flag') {
      return (
        <EventMarker
          type="flag"
          axis="y"
          event={this.state.trackerEvent}
          info={
            this.props.graphId === 1
              ? [
                  {
                    label: `${this.props.allkpi[0]}`,
                    value: `${this.state.trackerValue} €`
                  },
                  {
                    label: this.props.allkpi[1],
                    value: `${this.state.trackerValuecpv} €`
                  },
                  {
                    label: this.props.allkpi[2],
                    value: `${this.state.trackerValuecpd} €`
                  },
                  {
                    label: this.props.allkpi[3],
                    value: `${this.state.trackerValuecpi} €`
                  }
                ]
              : this.props.graphId === 2
              ? [
                  {
                    label: this.props.allname[0],
                    value: this.state.trackerValue * 1000
                  },
                  {
                    label: this.props.allname[1],
                    value: this.state.trackerValuecpv * 1000
                  },
                  {
                    label: this.props.allname[2],
                    value: this.state.trackerValuecpd * 1000
                  },
                  {
                    label: this.props.allname[3],
                    value: this.state.trackerValuecpi * 1000
                  }
                ]
              : [
                  {
                    label: `${this.props.allname[1].split(' ')[0]} / ${
                      this.props.allname[0].split(' ')[0]
                    }`,
                    value: `${this.state.trackerValuecpi} %`
                  },
                  {
                    label: `${this.props.allname[2].split(' ')[0]} / ${
                      this.props.allname[1].split(' ')[0]
                    }`,
                    value: `${this.state.trackerValuecpd} %`
                  },
                  {
                    label: `${this.props.allname[3].split(' ')[0]} / ${
                      this.props.allname[2].split(' ')[0]
                    }`,
                    value: `${this.state.trackerValue} %`
                  }
                ]
          }
          infoTimeFormat="%d/%m/%y"
          infoWidth={200}
          infoHeight={65}
          markerRadius={3}
          markerStyle={{ fill: 'white' }}
          infoStyle={{
            fill: 'white',
            stroke: 'black'
          }}
        />
      );
    }
    return (
      <EventMarker
        type="point"
        axis="axis"
        event={this.state.trackerEvent}
        markerLabel={this.state.trackerValue}
        markerLabelAlign="left"
        markerLabelStyle={{ fill: 'black', stroke: 'white' }}
        markerRadius={3}
        markerStyle={{ fill: 'black' }}
        infoStyle={{
          fill: 'white',
          stroke: 'black'
        }}
      />
    );
  };

  handleTimeRangeChange = timerange => {
    this.setState({ timerange });
  };

  timeZoom = () => {
    const { max, zoom } = this.state;
    const newmax = max - zoom;
    if (newmax <= 1) return 0;
    this.setState({ mode: 'zoom' });
    this.setState({ max: newmax });
  };

  timedeZoom = () => {
    const { max, zoom } = this.state;
    const newmax = max + zoom;
    if (newmax <= 1) return 0;
    this.setState({ mode: 'dezoom' });
    this.setState({ max: newmax });
  };

  renderChart = () => {
    const { series, seriescpv, seriescpd, seriescpi, seriesanom } = this.props;
    // const { max, min } = this.state;
    const { timerange, max, min } = this.state;
    const croppedSeries = series.crop(timerange);
    return (
      <ChartContainer
        timeRange={timerange}
        onTimeRangeChanged={this.handleTimeRangeChange}
        onTrackerChanged={this.handleTrackerChanged}
        format="%d/%m"
        trackerPosition={this.state.tracker}
      >
        <ChartRow height="300">
          <YAxis
            id="y"
            min={min}
            max={max}
            format=",.2f"
            width="40"
            height={10}
            style={style}
            type="linear"
          />
          <Charts>
            <LineChart
              axis="y"
              style={style}
              columns={['CPM']}
              series={croppedSeries}
              visible={!this.state.kpi.CPM.show}
            />
            <LineChart
              axis="y"
              style={style}
              columns={['CPV']}
              series={seriescpv}
              visible={!this.state.kpi.CPV.show}
            />
            <LineChart
              axis="y"
              style={style}
              columns={['CPD']}
              series={seriescpd}
              visible={!this.state.kpi.CPD.show}
            />
            <LineChart
              axis="y"
              style={style}
              columns={['CPI']}
              series={seriescpi}
              visible={!this.state.kpi.CPI.show}
            />
            <ScatterChart
              axis="y"
              style={style}
              columns={['ANOM']}
              series={seriesanom}
              radius={5}
              visible={!this.state.kpi.ANOM.show}
            />
            {this.renderMarker()}
          </Charts>
        </ChartRow>
      </ChartContainer>
    );
  };

  handleActiveChange = channelName => {
    const { series, seriescpv, seriescpd, seriescpi, graphId } = this.props;
    let e = series.max('CPM');
    let d = seriescpd.max('CPD');
    let i = seriescpi.max('CPI');
    let v = seriescpv.max('CPV');
    this.state.kpi[channelName].show = !this.state.kpi[channelName].show;
    if (this.state.kpi.CPM.show) e = 0;
    if (this.state.kpi.CPD.show) d = 0;
    if (this.state.kpi.CPI.show) i = 0;
    if (this.state.kpi.CPV.show) v = 0;
    let total;
    if (graphId !== 3) total = Math.max(e, d, i, v);
    else total = Math.max(e, d, i);
    this.setState({ max: total });
  };

  renderLegend() {
    const { graphId } = this.props;
    if (graphId === 1)
      return (
        <Legend
          type="line"
          align="left"
          stack
          style={style}
          categories={[
            { key: 'CPM', label: this.props.allkpi[0] },
            { key: 'CPV', label: this.props.allkpi[1] },
            { key: 'CPD', label: this.props.allkpi[2] },
            { key: 'CPI', label: this.props.allkpi[3] },
            { key: 'ANOM', label: 'Anomalies', symbolType: 'dot' }
          ]}
          select=""
          onSelectionChange={this.handleActiveChange}
        />
      );
    if (graphId === 2)
      return (
        <Legend
          type="line"
          align="left"
          stack
          style={style}
          categories={[
            { key: 'CPM', label: this.props.allname[0].split(' ')[0] },
            { key: 'CPV', label: this.props.allname[1].split(' ')[0] },
            { key: 'CPD', label: this.props.allname[2].split(' ')[0] },
            { key: 'CPI', label: this.props.allname[3].split(' ')[0] },
            { key: 'ANOM', label: 'Anomalies', symbolType: 'dot' }
          ]}
          onSelectionChange={this.handleActiveChange}
        />
      );
    if (graphId === 3)
      return (
        <Legend
          type="line"
          align="left"
          stack
          style={style}
          categories={[
            {
              key: 'CPI',
              label: `${this.props.allname[1].split(' ')[0]}/${
                this.props.allname[0].split(' ')[0]
              }`,
              disable: !false
            },
            {
              key: 'CPD',
              label: `${this.props.allname[2].split(' ')[0]}/${
                this.props.allname[1].split(' ')[0]
              }`
            },
            {
              key: 'CPM',
              label: `${this.props.allname[3].split(' ')[0]}/${
                this.props.allname[2].split(' ')[0]
              }`
            },
            { key: 'ANOM', label: 'Anomalies', symbolType: 'dot' }
          ]}
          onSelectionChange={this.handleActiveChange}
        />
      );
  }

  render() {
    const linkStyle = {
      fontWeight: 600,
      color: 'grey',
      cursor: 'default'
    };

    const linkStyleActive = {
      color: 'steelblue',
      cursor: 'pointer'
    };
    console.log('test', this.renderChart);
    console.log('test', this.renderChart());

    return (
      <div>
        <div>
          <span
            className="zoom_buttonplus"
            style={this.state.mode === 'zoom' ? linkStyleActive : linkStyle}
            onClick={this.timeZoom}
            onKeyPress={this.timeZoom}
            role="button"
            tabIndex="0"
          >
            +
          </span>
          <span
            className="zoom_buttonmoins"
            style={this.state.mode === 'dezoom' ? linkStyleActive : linkStyle}
            onClick={this.timedeZoom}
            onKeyPress={this.timedeZoom}
            role="button"
            tabIndex="-1"
          >
            -
          </span>
        </div>
        <div>
          <div>
            <Resizable>{this.renderChart()}</Resizable>
            <div className="legend_container">{this.renderLegend()}</div>
          </div>
        </div>
      </div>
    );
  }
}

Graph.propTypes = {
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  series: PropTypes.object.isRequired,
  seriescpv: PropTypes.object.isRequired,
  seriescpd: PropTypes.object.isRequired,
  allkpi: PropTypes.array.isRequired,
  allname: PropTypes.array.isRequired,
  seriescpi: PropTypes.object.isRequired,
  seriesanom: PropTypes.object.isRequired,
  funnel: PropTypes.object.isRequired,
  seriesBegin: PropTypes.object.isRequired,
  seriesEnd: PropTypes.object.isRequired,
  graphId: PropTypes.object.isRequired,
  seriesZoom: PropTypes.object.isRequired
};
