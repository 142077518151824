import React from 'react';
import { Mutation } from 'react-apollo';

import PersistFunnel from 'Funnel/components/PersistFunnel';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { FUNNEL } from 'utils/graphQL';
import { useUser } from 'contexts/UserContext';

/**
 * PersistContainer
 * @desc funnel saving management (button: sauvegarder funnel)
 * @component
 *
 */
function PersistContainer() {
  const { user } = useUser();
  const { setFunnel, funnel, funnelStringified } = useFunnel();
  return (
    <Mutation mutation={FUNNEL.UPDATE_FUNNEL}>
      {updateFunnel => (
        <Mutation mutation={FUNNEL.CREATE_FUNNEL}>
          {createFunnel => (
            <PersistFunnel
              name={funnel.name}
              saveFunnel={name => {
                const persistFunnel = funnel.id ? updateFunnel : createFunnel;
                setFunnel({ ...funnel, name });
                persistFunnel({
                  variables: {
                    ...funnelStringified,
                    saved: true,
                    userId: user.username,
                    name
                  }
                })
                  .then(({ data }) => {
                    const idResponse =
                      data.createFunnel && data.createFunnel.id;
                    if (idResponse) setFunnel({ ...funnel, id: idResponse });
                    console.log('PERSIST RESPONSE', data);
                  })
                  .catch(c => {
                    console.log('PERSIST ERROR:', c);
                  });
              }}
            />
          )}
        </Mutation>
      )}
    </Mutation>
  );
}

export default PersistContainer;
