import React from 'react';
// import PropTypes from 'prop-types'

import { DefaultButtonInsight, UnColoredButton } from './style';

/**
 * ButtonInsight
 * @component
 *
 *
 */
function ButtonInsight(props) {
  return <DefaultButtonInsight {...props} />;
}
ButtonInsight.defaultProps = {};
ButtonInsight.propTypes = {};
export default ButtonInsight;

/** @see ResultContainer  */
export const ActionButton = props => <UnColoredButton {...props} />;
