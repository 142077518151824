import constants from 'utils/constants';

const { insightSegmentTypes } = constants;

const { SIMPLE } = insightSegmentTypes;

/**
 * SegmentSimple
 * @desc one segment
 * @see CriteriaContainer
 * @param [string] id
 */
function SegmentSimple(id) {
  this.type = SIMPLE.NAME;
  this.flags = [];
  this.segmentID = id;
}

/**
 * SegmentComposed
 * @desc filters is a array of SegmentSimple (defined above)
 * @param [string] type
 * @param [array] filters
 * @return constructors
 *
 */
function SegmentComposed({ type, filters }) {
  this.type = type;
  this.filters = filters;
}

/**
 * DefaultSegment strategy
 * @constructor
 * @param [object]
 * @see SegmentComposed
 * @see SegmentSimple
 */
export default function DefaultSegment(params) {
  const { type, id, filters } = params;
  if (type) {
    return new SegmentComposed({ type, filters });
  }
  return new SegmentSimple(id);
}
