import React, { useState } from 'react';
import Modal from 'react-modal';
import constants from 'utils/constants';
import ActionWrapper, {
  ActionButtonB,
  ActionButtonY,
  ActionTextTitle,
  ActionSVGpro,
  ActionSVGarrow
} from './style';

/**
 * ActionFunnel
 * @component
 */

const { colors } = constants;
const customStyles = {
  content: {
    borderColor: colors.funnel,
    backgroundColor: colors.tertiary,
    color: colors.light,
    maxWidth: '33%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
    // background: colors.white,
    // position: 'relative',
    // border: 'none',
    // height: '70vh',

    // width: '30%',
    // margin: '2rem'
  },
  text: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-50px'
  }
};

function ActionFunnel() {
  const [modalProfilIsOpen, openProfilModal] = useState(false);
  const [modalRetargetingOpen, openRetargetingModal] = useState(false);
  return (
    <ActionWrapper>
      <div>
        <ActionTextTitle>
          ACTIONS RAPIDES VERS INSIGHT ET ACTIVATION
        </ActionTextTitle>
        <div>
          <div style={{ marginBottom: '3%' }}>
            <ActionButtonY
              className="btn"
              title=""
              role="button"
              onClick={() => {
                openProfilModal(true);
              }}
            >
              <ActionSVGpro />
              {"ANALYSER L'AUDIENCE"}
            </ActionButtonY>
          </div>
          <div style={{ marginBottom: '3%' }}>
            <ActionButtonB
              className="btn"
              title=""
              role="button"
              onClick={() => {
                openRetargetingModal(true);
              }}
            >
              <ActionSVGarrow />
              {"RETARGERTER L'AUDIENCE"}
            </ActionButtonB>
          </div>
          <div style={{ marginBottom: '3%' }}>
            <ActionButtonB
              className="btn"
              title=""
              role="button"
              onClick={() => {
                openRetargetingModal(true);
              }}
            >
              <ActionSVGarrow />
              {"FAIRE DU LOOK ALIKE SUR L'AUDIENCE"}
            </ActionButtonB>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalProfilIsOpen}
        onRequestClose={() => openProfilModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-content modal-help text-center">
          <div>
            <center>
              <p>
                <strong>Profils</strong>
              </p>
              <p>
                Cette fonctionnalité est en cours de développement et sera
                disponible très prochainement !
              </p>
              <p>
                “Profils” vous permettra de découvrir votre audience réelle.
                Vous pourrez ainsi analyser en détails les profils de vos
                consommateurs et adapter votre communication.
              </p>
            </center>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalRetargetingOpen}
        onRequestClose={() => openRetargetingModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-content modal-help text-center">
          <div>
            <center>
              <p>
                <strong>Retargeting</strong>
              </p>
              <p>
                Cette fonctionnalité est en cours de développement et sera
                disponible très prochainement !
              </p>
              <p>
                “Retargeting” vous permettra de relancer une campagne en
                quelques clics auprès de toute ou partie de votre cible. Vous
                pourrez ainsi toucher à nouveau de potentiels consommateurs qui
                n’aurait pas été convertis la première fois.
              </p>
            </center>
          </div>
        </div>
      </Modal>
    </ActionWrapper>
  );
}

export default ActionFunnel;
