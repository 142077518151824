import React from 'react';
import BaseInput from './style';

/**
 * Input
 * @component
 */
function Input(props) {
  return <BaseInput {...props} />;
}

export default Input;
