import React from 'react';
// import PropTypes from 'prop-types'

import BasePreviewFunnel from './style';

/**
 * PreviewFunnel
 * @component
 *
 */
function PreviewFunnel(props) {
  return <BasePreviewFunnel {...props} />;
}

PreviewFunnel.defaultProps = {};

PreviewFunnel.propTypes = {};

export { PreviewItem } from './style';
export default PreviewFunnel;
