import React from 'react';
import { useUser } from 'contexts/UserContext';
import { Query } from 'react-apollo';
import { FUNNEL } from 'utils/graphQL';
import moment from 'moment';
import constants from 'utils/constants';
import DashboardWrapper, {
  DashboardBoxInsight,
  DashboardBoxActivation,
  DashboardBoxData,
  DashboardBoxFunnel,
  DashboardBoxSubtile,
  DashboardBoxCta,
  DashboardTextSubtile,
  DashboardBoxElement,
  DashboardButton,
  DashboardTitle,
  DashboardText,
  DashboardImage,
  Links,
  LinkHistory
} from './style';

const { routes } = constants;

/**
 * Dashboard
 * @component
 */

function Dashboard() {
  const { user } = useUser();
  const role =
    user && user.attributes ? user.attributes['custom:role'] : 'USER';
  const opaq = role === 'USER' ? 0.3 : 1;
  return (
    <DashboardWrapper>
      <DashboardBoxData opacity={opaq}>
        <div>
          <DashboardTitle medium>
            <DashboardImage src="./assets/img/icon-data.png" alt="Ermes data" />
            DATA
          </DashboardTitle>
        </div>
        <DashboardText>
          Enrichissez vos données de CRM et DMP pour en savoir plus sur vos
          clients et améliorer vos campagnes
        </DashboardText>
        <div>
          <DashboardBoxSubtile>
            <DashboardTextSubtile>3 DERNIERS DATA</DashboardTextSubtile>
            <DashboardTextSubtile>{'>'}</DashboardTextSubtile>
          </DashboardBoxSubtile>
          <DashboardBoxElement>
            <div>
              <strong>Segment campagne Facebook</strong>
            </div>
            <span>04/2018</span>
          </DashboardBoxElement>
          <DashboardBoxElement>
            <div>
              <strong>Segment campagne Display</strong>
            </div>
            <span>01/2018</span>
          </DashboardBoxElement>
          <DashboardBoxElement>
            <div>
              <strong>Segment campagne Facebook</strong>
            </div>
            <span>12/2018</span>
          </DashboardBoxElement>
        </div>
        <DashboardBoxCta>
          <DashboardButton>
            <strong>Enrichir sa data {'>'}</strong>
          </DashboardButton>
        </DashboardBoxCta>
      </DashboardBoxData>
      <DashboardBoxInsight opacity={opaq}>
        <DashboardTitle small>
          <DashboardImage
            src="./assets/img/icon-insight.png"
            alt="Ermes data"
          />
          INSIGHT
        </DashboardTitle>
        <DashboardText>
          Découvrez réellement qui est votre cible et comparez-là avec votre
          audience actuelle
        </DashboardText>
        <div>
          <DashboardBoxSubtile>
            <DashboardTextSubtile>3 DERNIERS INSIGHT</DashboardTextSubtile>
            <DashboardTextSubtile>{'>'}</DashboardTextSubtile>
          </DashboardBoxSubtile>
          <DashboardBoxElement>
            <div>
              <strong>Profil visites stores</strong>
            </div>
            <span>07/2018-08/2018</span>
          </DashboardBoxElement>
          <DashboardBoxElement>
            <div>
              <strong>Profils dowload</strong>
            </div>
            <span>06/2018-12/2018</span>
          </DashboardBoxElement>
          <DashboardBoxElement>
            <div>
              <strong>Profils achats Amazon</strong>
            </div>
            <span>12/2018</span>
          </DashboardBoxElement>
        </div>
        <DashboardBoxCta>
          <DashboardButton>
            <strong>Nouvelle analyses {'>'}</strong>
          </DashboardButton>
        </DashboardBoxCta>
      </DashboardBoxInsight>
      <DashboardBoxActivation opacity={opaq}>
        <DashboardTitle small>
          <DashboardImage
            src="./assets/img/icon-activation.png"
            alt="Ermes data"
          />
          ACTIVATION
        </DashboardTitle>
        <DashboardText>
          Réalisez des camapgnes marketing très ciblées ou plus mass média et
          améliorer votre ROI
        </DashboardText>
        <div>
          <DashboardBoxSubtile>
            <DashboardTextSubtile>3 DERNIERS ACTIVATION</DashboardTextSubtile>
            <DashboardTextSubtile>{'>'}</DashboardTextSubtile>
          </DashboardBoxSubtile>
          <DashboardBoxElement>
            <div>
              <strong>campagne Facebook</strong>
            </div>
            <span>04/2018-06/2018</span>
          </DashboardBoxElement>
          <DashboardBoxElement>
            <div>
              <strong>campagne Display</strong>
            </div>
            <span>01/2018-06/2018</span>
          </DashboardBoxElement>
          <DashboardBoxElement>
            <div>
              <strong>campagne Facebook</strong>
            </div>
            <span>12/2018</span>
          </DashboardBoxElement>
        </div>
        <DashboardBoxCta>
          <DashboardButton>
            <strong>Nouvelle campagne {'>'}</strong>
          </DashboardButton>
        </DashboardBoxCta>
      </DashboardBoxActivation>

      <DashboardBoxFunnel>
        <DashboardTitle small>
          <DashboardImage src="./assets/img/icon-funnel.png" alt="Ermes data" />
          FUNNEL
        </DashboardTitle>
        <DashboardText>
          Comprenez, optimisez et anticipez vos performances quels que soient
          vos objectifs.
        </DashboardText>
        <div>
          <DashboardBoxSubtile>
            <DashboardTextSubtile>2 DERNIERS Funnel</DashboardTextSubtile>
            <DashboardTextSubtile>
              <LinkHistory to={`${routes.HISTORY}/funnel`}>{' > '}</LinkHistory>
            </DashboardTextSubtile>
          </DashboardBoxSubtile>
          {
            <Query
              query={FUNNEL.LIST_FUNNEL}
              skip={!user}
              variables={{
                userId: user ? user.username : null,
                saved: true,
                limit: 100
              }}
            >
              {({ loading, error, data }) => {
                if (loading || !data) return 'Loading...';
                if (error) return `Error! ${error.message}`;

                const funnels = data.listFunnels.items.slice(-2);
                return funnels.map(funnel => {
                  const measure = JSON.parse(funnel.measure);
                  const localisation = `${routes.FUNNEL}/${funnel.id}`;
                  return (
                    <DashboardBoxElement>
                      <LinkHistory to={localisation}>
                        <div>
                          <strong>
                            {funnel.name} ({measure.name})
                          </strong>
                        </div>
                        <span>
                          {moment(funnel.start).format('L')}-
                          {moment(funnel.end).format('L')}
                        </span>
                      </LinkHistory>
                    </DashboardBoxElement>
                  );
                });
              }}
            </Query>
          }
        </div>
        <DashboardBoxCta>
          <DashboardButton>
            <Links to={routes.FUNNEL}>
              <strong>Nouveau Funnel {'>'}</strong>
            </Links>
          </DashboardButton>
        </DashboardBoxCta>
      </DashboardBoxFunnel>
    </DashboardWrapper>
  );
}

export default Dashboard;
