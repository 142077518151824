import React, { useState } from 'react';
import PropTypes from 'prop-types';
import constants from 'utils/constants';

import Icon from 'utils/icons';
import ProfilNav from './ProfilNav';
import {
  FlexCenter,
  HeaderErmes,
  NavTab,
  NavTabItem,
  NavImg,
  BaseHeaderLink
} from './styles';

const { panels, colors } = constants;
const Header = props => {
  const [data, setFocusedData] = useState(false);
  const [insight, setFocusedInsight] = useState(false);
  const [activation, setFocusedActivation] = useState(false);
  const [funnel, setFocusedFunnel] = useState(false);
  const [imageName, setImageName] = useState(props.imageName);
  const [colorActuelFunnel, setcolorActuelFunnel] = useState('funnel');
  const url = window.location.pathname;

  console.log('URL : ', url);
  if (!funnel && url.indexOf('funnel') !== -1) {
    setFocusedFunnel(true);
    setcolorActuelFunnel('funnel');
    setImageName('/assets/img/logo_ermes_homepage.svg');
  }
  if (!data && url.indexOf('data') !== -1) {
    setFocusedData(true);
    setcolorActuelFunnel('data');
    setImageName('/assets/img/data.svg');
  }
  if (!insight && url.indexOf('insight') !== -1) {
    setFocusedInsight(true);
    setcolorActuelFunnel('insight');
    setImageName('/assets/img/insights.svg');
  }
  if (!activation && url.indexOf('activation') !== -1) {
    setFocusedActivation(true);
    setcolorActuelFunnel('activation');
    setImageName('/assets/img/activation.svg');
  }

  return (
    <HeaderErmes className="header" color={colorActuelFunnel}>
      <FlexCenter>
        <BaseHeaderLink
          to="/dashboard"
          onClick={() => {
            setFocusedData(false);
            setFocusedActivation(false);
            setFocusedInsight(false);
            setFocusedFunnel(false);
          }}
        >
          <NavImg src={imageName} alt="" />
        </BaseHeaderLink>

        <NavTab>
          <NavTabItem
            id="data"
            isFocused={data}
            onClick={() => {
              setFocusedData(true);
              setFocusedActivation(false);
              setFocusedInsight(false);
              setFocusedFunnel(false);
              setcolorActuelFunnel('data');
              setImageName('/assets/img/data.svg');
            }}
          >
            <BaseHeaderLink
              to="/features/data"
              className="nav-tab-link"
              color={colors.data}
              isActive={data}
            >
              <Icon icon={panels.data} />
              <div>Data</div>
            </BaseHeaderLink>
          </NavTabItem>
          <NavTabItem
            id="insight"
            isFocused={insight}
            onClick={() => {
              setFocusedData(false);
              setFocusedActivation(false);
              setFocusedInsight(true);
              setFocusedFunnel(false);
              setcolorActuelFunnel('insight');
              setImageName('/assets/img/insights.svg');
            }}
          >
            <BaseHeaderLink
              to="/insight"
              className="nav-tab-link"
              color={colors.insight}
              isActive={insight}
            >
              <Icon icon={panels.insight} />
              <div>Insight</div>
            </BaseHeaderLink>
          </NavTabItem>
          <NavTabItem
            id="activation"
            isFocused={activation}
            onClick={() => {
              setFocusedData(false);
              setFocusedActivation(true);
              setFocusedInsight(false);
              setFocusedFunnel(false);
              setcolorActuelFunnel('activation');
              setImageName('/assets/img/activation.svg');
            }}
          >
            <BaseHeaderLink
              to="/features/activation"
              className="nav-tab-link"
              color={colors.activation}
              isActive={activation}
            >
              <Icon icon={panels.activation} />
              <div>Activation</div>
            </BaseHeaderLink>
          </NavTabItem>
          <NavTabItem
            id="funnel"
            isFocused={funnel}
            onClick={() => {
              setFocusedData(false);
              setFocusedActivation(false);
              setFocusedInsight(false);
              setFocusedFunnel(true);
              setcolorActuelFunnel('funnel');
              setImageName('/assets/img/logo_ermes_homepage.svg');
            }}
          >
            <BaseHeaderLink
              to="/funnel"
              className="nav-tab-link"
              color={colors.funnel}
              isActive={funnel}
            >
              <Icon icon={panels.funnel} />
              <div>Funnel</div>
            </BaseHeaderLink>
          </NavTabItem>
        </NavTab>
      </FlexCenter>
      <ProfilNav />
    </HeaderErmes>
  );
};

Header.propTypes = {
  imageName: PropTypes.string.isRequired
};

export default Header;
