import React from 'react';
import { Query, Mutation } from 'react-apollo';

import LoadingInsight from 'Insight/components/LoadingInsight';
import ResultInsight from 'Insight/components/ResultInsight';
import ModalInsight from 'Insight/components/ModalInsight';
import CautionModalInsight from 'Insight/components/CautionModalInsight';
import SurveyModalInsight from 'Insight/components/SurveyModalInsight';
import ResultSavingInsight from 'Insight/components/ResultSavingInsight';
import ResultInsightLayout, {
  ResultColumn
} from 'Insight/components/ResultInsightLayout';
import ResultTitleInsight from 'Insight/components/ResultTitleInsight';
import { ActionButton } from 'Insight/components/ButtonInsight';
import { useInsight } from 'Insight/contexts/InsightContext';
import { useCategory } from 'Insight/contexts/CategoryContext';
import { useOperator } from 'Insight/contexts/OperatorContext';

import { INSIGHT } from 'utils/graphQL';

/**
 * ResultContainer
 * @component
 *
 */
function ResultContainer() {
  const { insight } = useInsight();
  const { currentCategory } = useCategory();
  const { formatedFilter } = useOperator();
  const { contacts, cookies, mails } = insight;
  const [name, setName] = React.useState('');
  const [currentModal, setCurrentModal] = React.useState(null);
  const closeModal = () => {
    setCurrentModal(null);
  };
  const getResult = (req, field) => {
    if (!req.data.viewer) return 0;
    return req.data.viewer.filteredData[field];
  };
  const handleSurvey = () => {};
  /*
      const handleActivation = () => {};
      const handleBuy = () => {};
    */
  const handleRecapOrder = () => {};
  const queryOptions = {
    variables: { categoryId: currentCategory, filters: formatedFilter },
    fetchPolicy: 'network-only'
  };
  React.useEffect(() => {}, [currentModal]);
  return (
    <React.Fragment>
      <ResultInsightLayout>
        <Query query={INSIGHT.GET_CATEGORY_DETAILS} {...queryOptions}>
          {response => (
            <LoadingInsight {...response}>
              <ResultColumn design="results">
                <ResultInsight
                  name={contacts.label}
                  value={getResult(response, 'nbProfiles')}
                />
                <ResultInsight
                  name={cookies.label}
                  value={getResult(response, 'nbProfilesCookies')}
                />
                <ResultInsight
                  name={mails.label}
                  value={getResult(response, 'nbProfilesEmails')}
                />
              </ResultColumn>
              <ResultColumn design="actions">
                <ActionButton
                  inversed
                  onClick={() => setCurrentModal('modal_save_funnel')}
                >
                  Sauvegarder la cible
                </ActionButton>
                <ActionButton
                  inversed
                  onClick={() => setCurrentModal('modal_survey_funnel')}
                >
                  Sondage AD-HOC
                </ActionButton>
                <ActionButton
                  onClick={() => {
                    /*
                  if (contacts.value < 50000)
                    setCurrentModal('modal_too_small_segment');
                  else handleActivation();
                  */
                  }}
                >
                  Activer la cible
                </ActionButton>
                <ActionButton
                  onClick={() => {
                    /*
                  if (contacts.value < 50000)
                    setCurrentModal('modal_too_small_segment');
                  else handleBuy();
                  */
                  }}
                >
                  Acheter la data
                </ActionButton>
              </ResultColumn>
            </LoadingInsight>
          )}
        </Query>
      </ResultInsightLayout>
      <ResultTitleInsight hasTitle={!!name}>{name}</ResultTitleInsight>
      {/* ------------------------ */}
      {/* Modal part (invisible) : */}
      <Mutation mutation={INSIGHT.PERSIST_FILTERS}>
        {persistFilters => (
          <ModalInsight
            isOpen={currentModal === 'modal_save_funnel'}
            handleClose={closeModal}
            handleValidation={() => {
              persistFilters({
                variables: {
                  input: {
                    clientMutationId: 0,
                    name,
                    filters: formatedFilter
                  }
                }
              }).then(r => {
                closeModal();
                console.log('response ::', r);
              });
            }}
            validationLabel="Sauvegarder"
            label={"Sauvegarder l'insight"}
          >
            <ResultSavingInsight
              onNameChange={nameInsight => setName(nameInsight)}
            />
          </ModalInsight>
        )}
      </Mutation>
      <ModalInsight
        isOpen={currentModal === 'modal_survey_funnel'}
        handleClose={closeModal}
        handleValidation={handleSurvey}
        validationLabel="Sonder"
        label={"Sonder l'insight"}
      >
        <SurveyModalInsight />
      </ModalInsight>
      <ModalInsight
        isOpen={currentModal === 'modal_too_small_segment'}
        handleClose={closeModal}
        handleValidation={handleRecapOrder}
        closeLabel="Modifier la sélection"
        validationLabel="Conserver la sélection"
        label="too Small Segment"
      >
        <CautionModalInsight />
      </ModalInsight>
    </React.Fragment>
  );
}
ResultContainer.defaultProps = {};
ResultContainer.propTypes = {};

export default ResultContainer;
