import styled from 'styled-components';
import Title from 'components/Title';
import constants from 'utils/constants';

const { colors } = constants;

/**
 * BaseResultTitle
 * @component
 *
 */
const BaseResultTitle = styled(Title)`
  color: ${colors.light};
  text-align: center;
  margin: ${({ hasTitle }) => (hasTitle ? '4px 0 8px' : '0')};
  height: ${({ hasTitle }) => (hasTitle ? '100%' : '0%')};
  transition: all 1s;
`;

export default BaseResultTitle;
