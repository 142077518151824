import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from '@material-ui/core';

const BaseSection = styled.div`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  border-radius: ${({ borderradius }) => borderradius || '2px'};
  display: ${({ display }) => display || 'flex'};
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'stretch'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  height: ${({ height }) => height || 'auto'};
  padding: ${({ padding }) => padding || '0'};
  width: ${({ width }) => width || 'auto'};
  min-height: ${({ minHeight }) => minHeight || 'auto'};
  color: ${({ color }) => color || 'currentcolor'};
  box-sizing: border-box;
  position: ${({ position }) => position || 'inherit'};

  .is-flex {
    display: flex;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .flex-1 {
    flex: 1;
  }
`;

export const BaseSectionLanding = styled(BaseSection)`
  @media (max-width: 1024px) {
    flex-direction: column;

    .box {
      flex-direction: column;
    }

    h1 {
      width: 100%;
    }
    &.section-one {
      padding-top: 120px;
    }
  }

  @media (max-width: 768px) {
    &.section-one {
      padding-top: 220px;
    }

    .img-home {
      width: 100%;
    }
  }
`;

export const BaseSectionTableWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    > div {
      min-height: auto;
      padding: 35px;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .plus {
    width: 30px;
    height: 30px;
    text-align: right;
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
`;

export const BaseSectionContainer = styled(Container)`
  && {
    padding: 0;
    margin: 0;
    width: 100%;
  }
`;

const Section = props => {
  const { type } = props;
  if (type === 'landing') return <BaseSectionLanding {...props} />;
  return <BaseSection {...props} />;
};
Section.defaultProps = {
  type: ''
};
Section.propTypes = {
  type: PropTypes.string
};

export default Section;
