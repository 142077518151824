import React from 'react';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { VictoryChart, VictoryAxis } from 'victory';
import moment from 'utils/moment';
import {
  TableDistrib,
  TableBodyDistrib,
  TableCellDistrib,
  TableHeadDistrib,
  TableRowDistrib,
  Container,
  ErrorMessage
} from './style';
import Distrib from './Distrib';

/**
 * @component
 *
 */

function DistributionMediaContainer() {
  const { funnel } = useFunnel();

  const filter = data => {
    return moment(data.date).isBetween(
      moment(funnel.start)
        .startOf('day')
        .format(),
      moment(funnel.end)
        .startOf('day')
        .format(),
      null,
      '[]'
    );
  };

  function getBudget() {
    const id = funnel.connected[4].data;
    const newConnectorData = id.filter(filter);

    console.log('NEW DATA : ', newConnectorData);

    const total = [];
    let sum = 0;
    if (newConnectorData[0].mediaB1 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaB1);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaB2 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaB2);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaB3 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaB3);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaB4 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaB4);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaB5 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaB5);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaB6 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaB6);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaB7 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaB7);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaB8 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaB8);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaB9 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaB9);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaB10 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaB10);
      }
      total.push(sum);
    }
    return total;
  }

  function getImpression() {
    const id = funnel.connected[4].data;
    const newConnectorData = id.filter(filter);
    const total = [];
    let sum = 0;
    if (newConnectorData[0].mediaI1 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaI1);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaI2 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaI2);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaI3 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaI3);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaI4 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaI4);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaI5 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaI5);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaI6 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaI6);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaI7 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaI7);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaI8 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaI8);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaI9 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaI9);
      }
      total.push(sum);
    }
    if (newConnectorData[0].mediaI10 !== undefined) {
      sum = 0;
      for (let i = 0; i < newConnectorData.length; i += 1) {
        sum += parseFloat(newConnectorData[i].mediaI10);
      }
      total.push(sum);
    }
    return total;
  }

  function getName() {
    const id = funnel.connected[4].data;
    const newConnectorData = id.filter(filter);
    const names = [
      newConnectorData[0].mediaName1,
      newConnectorData[0].mediaName2,
      newConnectorData[0].mediaName3,
      newConnectorData[0].mediaName4,
      newConnectorData[0].mediaName5,
      newConnectorData[0].mediaName6,
      newConnectorData[0].mediaName7,
      newConnectorData[0].mediaName8,
      newConnectorData[0].mediaName9,
      newConnectorData[0].mediaName10
    ];
    return names;
  }
  const budgetA = getBudget();
  const impressionA = getImpression();
  const namesA = getName();
  const tmpData = [];
  for (let i = 0; i < budgetA.length; i += 1) {
    tmpData.push({
      name: namesA[i],
      budget: parseFloat(budgetA[i] * 1000).toFixed(),
      impression: parseFloat(impressionA[i] / 1000).toFixed(2),
      CPM:
        impressionA[i] === 0
          ? 0
          : parseFloat((budgetA[i] * 1000) / impressionA[i]).toFixed(2)
    });
  }

  const maxBudget = Math.max(
    ...tmpData.map(o => {
      return o.budget;
    })
  );
  const maxImpression = Math.max(
    ...tmpData.map(o => {
      return o.impression;
    })
  );

  const max = {
    name: '',
    budget: parseFloat(maxBudget * 1.5).toFixed(0),
    impression: parseFloat(maxImpression * 1.5).toFixed(0),
    CPM: ''
  };

  React.useEffect(() => {
    tmpData.sort((a, b) => a.CPM - b.CPM);
  }, []);
  const [data, setData] = React.useState(tmpData);
  const [croissantCPM, setCroissantCPM] = React.useState(false);
  const [croissantBudget, setCroissantBudget] = React.useState(false);
  const [croissantImpression, setCroissantImpression] = React.useState(false);
  const [activeImpression, setActiveImpression] = React.useState(false);
  const [activeCPM, setActiveCPM] = React.useState(false);
  const [activeBudget, setActiveBudget] = React.useState(false);
  return (
    <React.Fragment>
      <div className="pb-data">
        <Container>
          <img
            src="/assets/img/info.svg"
            alt="? icon"
            className="question_mark"
          />
          <div className="kpi_table_description align_center">
            {`Comparez facilement la distribution de budget entre
             les différents médias utilisés et le nombre d’impression
              correspondant à chacun d’entre eux.`}
          </div>
        </Container>
      </div>

      {budgetA.lenght === 0 ? (
        <TableDistrib>
          <TableHeadDistrib>
            <TableRowDistrib>
              <TableCellDistrib />
              <TableCellDistrib
                fontWeight={activeCPM ? 'true' : undefined}
                onClick={() => {
                  setActiveImpression(false);
                  setActiveBudget(false);
                  setActiveCPM(true);
                  if (croissantCPM) {
                    setData(data.sort((a, b) => a.CPM - b.CPM));
                    setCroissantCPM(false);
                  } else {
                    setData(data.sort((a, b) => b.CPM - a.CPM));
                    setCroissantCPM(true);
                  }
                }}
              >
                CPM{' '}
                <img
                  src="/assets/img/picto_arrow_down.svg"
                  alt="drop-down-arrow"
                />
              </TableCellDistrib>
              <TableCellDistrib
                fontWeight={activeBudget ? 'true' : undefined}
                onClick={() => {
                  setActiveImpression(false);
                  setActiveBudget(true);
                  setActiveCPM(false);
                  if (croissantBudget) {
                    setData(data.sort((a, b) => a.budget - b.budget));
                    setCroissantBudget(false);
                    console.log(tmpData);
                  } else {
                    setData(data.sort((a, b) => b.budget - a.budget));
                    setCroissantBudget(true);
                    console.log(tmpData);
                  }
                }}
              >
                BUDGET (€)
                <img
                  src="/assets/img/picto_arrow_down.svg"
                  alt="drop-down-arrow"
                />
              </TableCellDistrib>
              <TableCellDistrib
                fontWeight={activeImpression ? 'true' : undefined}
                onClick={() => {
                  setActiveImpression(true);
                  setActiveBudget(false);
                  setActiveCPM(false);
                  if (croissantImpression) {
                    setData(data.sort((a, b) => a.impression - b.impression));
                    setCroissantImpression(false);
                    console.log(data);
                  } else {
                    setData(data.sort((a, b) => b.impression - a.impression));
                    setCroissantImpression(true);
                    console.log(data);
                  }
                }}
              >
                IMPRESSION (M)
                <img
                  src="/assets/img/picto_arrow_down.svg"
                  alt="drop-down-arrow"
                />
              </TableCellDistrib>
            </TableRowDistrib>
          </TableHeadDistrib>
          <TableBodyDistrib>
            {data.map((e, index) => (
              <Distrib
                key={index}
                name={e.name}
                tmpData={e}
                maxBudget={Number(max.budget)}
                maxImpression={Number(max.impression)}
              />
            ))}
            <TableRowDistrib>
              <TableCellDistrib className="b-right b-top" />
              <TableCellDistrib className="b-right b-top" />
              <TableCellDistrib className="b-right b-top">
                <VictoryChart
                  height={50}
                  style={{ parent: { position: 'relative', right: '10%' } }}
                >
                  <VictoryAxis
                    crossAxis
                    domain={[0, max.budget]}
                    standalone={false}
                    style={{
                      ticks: { stroke: 'white', size: 10 },

                      axis: {
                        stroke: 'none'
                      },
                      tickLabels: {
                        fontSize: '12px',
                        fill: 'white'
                      }
                    }}
                  />
                </VictoryChart>
              </TableCellDistrib>
              <TableCellDistrib className="b-right b-top">
                <VictoryChart
                  height={50}
                  style={{ parent: { position: 'relative', right: '10%' } }}
                >
                  <VictoryAxis
                    crossAxis
                    domain={[0, max.impression]}
                    standalone={false}
                    style={{
                      axis: {
                        stroke: 'none'
                      },
                      ticks: { stroke: 'white', size: 10 },
                      tickLabels: {
                        fontSize: '12px',
                        fill: 'white'
                      }
                    }}
                  />
                </VictoryChart>{' '}
              </TableCellDistrib>
            </TableRowDistrib>
          </TableBodyDistrib>
        </TableDistrib>
      ) : (
        <ErrorMessage>
          {
            "Il semblerait que les données que vous nous avez transmis pour ce funnel ne nous permettent pas d'analyser la distribution de vos budgets médias."
          }
        </ErrorMessage>
      )}
    </React.Fragment>
  );
}

DistributionMediaContainer.defaultProps = {};

DistributionMediaContainer.propTypes = {};

export default DistributionMediaContainer;
